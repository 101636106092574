import { Switch } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

import useOrganizationColors from '../../hooks/useOrganizationColors';

export default function Checkbox({ value, label, description, enabled, onChange }) {
  const colors = useOrganizationColors();

  return (
    <Switch.Group>
      <div
        className={clsx(
          'relative flex gap-1 my-4 p-2 rounded cursor-pointer hover:ring-gray-200 hover:bg-gray-50 ring-1',
          {
            [`bg-[${colors?.primary500_1}] ring-[${colors?.primary500}]`]: enabled
          },
          {
            'bg-gray-50/80 ring-gray-100': !enabled
          }
        )}>
        <div className="flex-none">
          <Switch
            checked={enabled}
            onChange={() => onChange(value)}
            className={clsx('h-5 w-5 rounded border border-theme-8 bg-theme-7 text-theme-2', {
              [`bg-[${colors?.secondary500}]`]: enabled
            })}>
            <CheckIcon className={enabled ? 'opacity-100' : 'opacity-0'} />
          </Switch>
        </div>
        <Switch.Label className="flex-grow flex-shrink ml-2.5 overflow-hidden">
          <div className="flex flex-col">
            {label}
            {description && <span className="text-sm text-gray-500">{description}</span>}
          </div>
          <span className="absolute w-full h-full left-0 top-0 cursor-pointer"></span>
        </Switch.Label>
      </div>
    </Switch.Group>
  );
}
