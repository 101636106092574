import clsx from 'clsx';

export default function RadioSelect({
  label,
  labelClassName = '',
  // error,
  // touched,
  options,
  selected,
  onChange = () => {},
  // onReset,
  ...props
}) {
  const handleChange = (e) => {
    const selectedValue = e.target.value;
    onChange(selectedValue);
  };

  return (
    <div className="w-full group bg-backgroundGrey rounded-lg my-4">
      {label && (
        <div className="relative">
          <label
            className={clsx(
              'px-6 peer-focus:font-medium absolute text-sm text-textAndIcons duration-300 transform -translate-y-6 scale-75 top-4 z-50 origin-[0] rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-textAndIcons peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6',
              labelClassName
            )}>
            {label}
          </label>
        </div>
      )}
      <select
        {...props}
        onChange={handleChange}
        className="bg-transparent pr-3 pl-4 py-4 text-sm w-full focus:outline-none">
        {options.map((option) => {
          let isSelected;
          if (typeof selected === 'object' && selected !== null) {
            isSelected = option.value === selected._id;
          } else if (typeof selected === 'string') {
            isSelected = option.value === selected;
          } else {
            isSelected = false;
          }
          return (
            <option
              key={option.value}
              value={option.value}
              disabled={option.isDisabled}
              selected={isSelected}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
}
