import { ArrowPathIcon } from '@heroicons/react/24/outline';

export default function Input({
  label,
  labelClassName,
  className,
  error,
  touched,
  isDark = false,
  inputClassName,
  onReset,
  ...props
}) {
  return (
    <div className={`${className}`}>
      {label && <label className={`block ${labelClassName} `}>{label}</label>}
      {onReset && props.value && (
        <ArrowPathIcon
          className="w-4 inline-block ml-2 cursor-pointer"
          title="reset"
          onClick={onReset}
        />
      )}
      <input
        className={`border px-2 py-2 rounded w-full ${isDark && 'bg-zinc-200'} ${inputClassName}`}
        {...props}
      />
      {error && touched && (
        <p className="text text-red-500" data-tesid="error-text">
          {error}
        </p>
      )}
    </div>
  );
}

Input.defaultProps = {
  className: ''
};
