import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getActiveColumns, moveGroup } from '../service';
import { moveGroupSchema } from '../utils/schema';
import { RadioSelect } from './common';
import Button from './common/button';

const MoveGroup = ({ group, onSubmit = () => {} }) => {
  const { t } = useTranslation();
  const [columns, setColumns] = useState([]);

  const fetchColumns = async () => {
    try {
      const { data: list } = await getActiveColumns();
      // remove the column to which the group currently belongs to
      const filteredList = list.filter((listItem) => listItem._id !== group?.groupColumn?._id);

      // format for the dropdown
      const formattedList = filteredList.map((listItem) => ({
        _id: listItem._id,
        label: listItem.name,
        value: listItem._id
      }));

      setColumns(formattedList);
    } catch (error) {
      toast.error('Failed to get columns');
      console.error('Failed to get columns: ', error);
    }
  };

  useEffect(() => {
    fetchColumns();
  }, []);

  const formik = useFormik({
    initialValues: { column: columns?.[0]?.value },
    validationSchema: moveGroupSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const { column } = values;
        await moveGroup({ groupId: group._id, moveToColumnId: column });
        toast.success('Group moved successfully.');
        onSubmit();
      } catch (error) {
        console.error('Failed to move group:', error);
        toast.error('Failed to move group.');
      }
    }
  });

  return (
    <div>
      <header className="bg-[#F8F8F8] px-6 py-4 rounded-t-lg flex flex-col items-start justify-start">
        <h4 className="font-semibold">{t('moveGroup.title')}</h4>
        <p className="text-sm">
          {t('moveGroup.from')}{' '}
          <span className="font-semibold underline">{group?.groupColumn?.name}</span>
        </p>
      </header>
      <main className="p-6">
        <form onSubmit={formik.handleSubmit}>
          <RadioSelect
            name="group"
            label={t('moveGroup.moveField')}
            className="my-1"
            value={formik.values.column}
            touched={formik.touched.column}
            onBlur={formik.handleBlur}
            error={formik.errors.column}
            options={columns}
            onChange={(option) => {
              formik.setFieldValue('column', option, true);
            }}
          />
          <footer className="flex item-center justify-end">
            <Button.ButtonFilled type="submit" isMini loading={formik.isSubmitting}>
              {t('moveGroup.submit')}
            </Button.ButtonFilled>
          </footer>
        </form>
      </main>
    </div>
  );
};

export default MoveGroup;
