import { ArrowPathIcon } from '@heroicons/react/24/outline';

import Input from './input';
import InputSelect from './inputSelect';

export default function InputGroupSelectRange({
  label,
  year,
  setFieldValue,
  labelClassName,
  range,
  error,
  value,
  touched,
  labelFirst = false,
  onReset,
  ...props
}) {
  return (
    <div>
      {label && <label className={`${labelClassName}`}>{label}</label>}
      {onReset && (range.value || year.value) && (
        <ArrowPathIcon
          className="w-4 inline-block ml-2 cursor-pointer"
          title="reset"
          onClick={onReset}
        />
      )}
      <div className="flex flex-row gap-2">
        <div className="w-1/2">
          <Input {...year} />
        </div>
        <div className="w-1/2">
          {/* {range.label && <label className="mr-2">{range.label}</label>} */}
          <InputSelect
            className="rounded w-full"
            name={range.name}
            placeholder={range.placeholder}
            value={range.value}
            touched={range.touched}
            onBlur={range.handleBlur}
            error={range.error}
            options={range.options}
            onChange={(val) => {
              if (range?.onChange?.setFieldValue) {
                range.onChange.setFieldValue(range.name, val, true);
              }
              if (range?.setFieldValue) {
                range.setFieldValue(range.name, val, true);
              }
            }}
          />
        </div>
        {/* {range.error && range.touched && <p className="text text-red-500">{range.error}</p>} */}
      </div>
    </div>
  );
}
