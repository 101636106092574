import { useTranslation } from 'react-i18next';

export default function Badge({ isCompleted }) {
  const { t } = useTranslation();
  return isCompleted ? (
    <span className="px-2 py-1 bg-green-200/60 rounded-full">{t('badge.complete')}</span>
  ) : (
    <span className="px-2 py-1 bg-gray-100 rounded-full">{t('badge.incomplete')}</span>
  );
}
