import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaCaretRight } from 'react-icons/fa';

import { ProfileContext } from '../../contexts/profileContext';
import { isLinkDisabled } from '../../service/internalService';
import DownloadPDF from '../popups/downloadPDF';
import userRoles from '../../data/roles';

export default function MenuItems({ isPopupMenu = false }) {
  const [profile] = useContext(ProfileContext);

  const [showPDFModal, setShowPDFModal] = useState(false);

  const handlePDFDownload = () => {
    setShowPDFModal(true);
  };
  const closePDFModal = () => {
    setShowPDFModal(false);
  };

  const videoRoutes = [
    {
      title: 'Vidéo :',
      sub: 'Ma force préférée',
      link: '/video-1'
    },
    {
      title: 'Vidéo :',
      sub: 'Mon rôle professionnel préféré',
      link: '/video-2'
    },
    {
      title: 'Vidéo :',
      sub: 'Ma scolarité',
      link: '/video-3'
    },
    {
      title: 'Vidéo :',
      sub: 'Ma formation professionnelle',
      link: '/video-4'
    },
    {
      title: 'Vidéo :',
      sub: 'Mon parcours de CHAMPION',
      link: '/video-5'
    },
    {
      title: 'Vidéo :',
      sub: 'Ma mission future',
      link: '/video-6'
    },
    {
      title: 'Vidéo :',
      sub: 'Ce que j’aimerais encore vous dire …',
      link: '/video-7'
    }
  ];

  const menuItems = [
    {
      title: 'Données personelles',
      link: '/profile-data'
    },
    {
      title: 'Mon portrait',
      link: '/profile-image'
    },
    {
      title: 'Sélection des forces et rôles',
      sub: '(Top 3)',
      link: '/strengths'
    }
  ];

  const finalRoutes =
    profile.userRoles && profile.userRoles !== userRoles.CHAMPION
      ? [...menuItems]
      : [...menuItems, ...videoRoutes];

  return (
    <div className="menu-items px-2">
      {isPopupMenu
        ? finalRoutes.map((item, index) => (
            <Link
              key={index}
              to={isLinkDisabled(item.link, profile) ? '#' : item.link}
              className={`link-item ${isLinkDisabled(item.link, profile) && 'opacity-40'}`}>
              <div className="title">
                <p>{item.title}</p>
                <span>{item.sub}</span>
              </div>
              <FaCaretRight size={14} />
            </Link>
          ))
        : finalRoutes.map((item, index) => (
            <Link
              key={index}
              to={isLinkDisabled(item.link, profile) ? '#' : item.link}
              className={`link-item ${isLinkDisabled(item.link, profile) && 'opacity-40'}`}>
              <div className="title">
                <p>{item.title}</p>
                <span>{item.sub}</span>
              </div>
              <FaCaretRight size={14} />
            </Link>
          ))}
      {profile.profileType === userRoles.CHAMPION && (
        <Link
          to={
            isLinkDisabled('/champion-profile', profile) ? '#' : '/champion-profile/' + profile._id
          }
          className={`link-item ${isLinkDisabled('/champion-profile', profile) && 'opacity-40'}`}>
          <div className="title">
            <p>Je regarde mon profil :</p>
            <span></span>
          </div>
          <FaCaretRight size={14} />
        </Link>
      )}
      <button
        className={`link-item w-full ${
          isLinkDisabled('/champion-profile', profile) && 'opacity-40'
        }`}
        disabled={isLinkDisabled('/champion-profile', profile)}
        onClick={handlePDFDownload}>
        <div className="title">
          <p>Télécharge ta Fiche Portrait</p>
          <span></span>
        </div>
      </button>
      {showPDFModal && (
        <DownloadPDF
          userId={profile._id}
          profileType={profile.profileType}
          role={profile.role}
          closeModal={closePDFModal}
        />
      )}
    </div>
  );
}
