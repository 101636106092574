import React, { useEffect } from 'react';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import { HashLink } from 'react-router-hash-link';

import useOrganizationColors from '../../../../hooks/useOrganizationColors';

export default function Menu({ menuData, showMenu, setShowMenu }) {
  const colors = useOrganizationColors();

  const hideMenu = () => setShowMenu(false);

  useEffect(() => {
    if (!document.getElementById('root').classList) return;
    if (showMenu) {
      document.getElementById('root').classList.add('overflow-y-hidden', 'h-screen');
    } else {
      document.getElementById('root').classList.remove('overflow-y-hidden', 'h-screen');
    }
  }, [showMenu]);

  return (
    <nav
      className={`slide-menu-container ${showMenu ? 'open' : 'close'} bg-[${
        colors?.secondary500
      }] fixed z-50 overflow-y-auto`}>
      <div className="p-4">
        <div className="text-left px-8">
          <button
            className={`bg-[${colors?.secondary300}] text-white text-xl rounded-full h-8 w-8 text-center my-6`}
            onClick={hideMenu}>
            <HiOutlineArrowNarrowLeft className="text-white mx-auto" />
          </button>
        </div>
        <div className="px-8">
          <ul>
            {menuData &&
              menuData.map((item, index) => {
                return (
                  <li key={index} className="mb-6 text-center md:text-left">
                    <HashLink
                      scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                      to={item.link}
                      onClick={hideMenu}
                      className={item?.className}>
                      {item.type !== 'button' ? (
                        <span className="text-white font-bold text-base md:text-xl uppercase">
                          {item.title}{' '}
                          {item.subtitle && (
                            <span
                              className={`text-[${colors?.primary500}] block md:inline-block ${item?.subtitleClass}`}>
                              {item.subtitle}
                            </span>
                          )}
                        </span>
                      ) : (
                        <span
                          className={`block w-fit border-2 border-white mx-auto md:ml-0  font-bold text-lg px-8 py-1 rounded-full mt-2 md:mt-6 ${
                            item.className !== undefined
                              ? item.className
                              : `bg-transparent md:bg-white text-[${colors?.primary500}] md:text-[${colors?.secondary500}]`
                          }`}>
                          {item.title}
                        </span>
                      )}
                    </HashLink>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </nav>
  );
}
