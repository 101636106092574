import clsx from 'clsx';
import React, { forwardRef, useContext } from 'react';
import { ProfileContext } from '../../../contexts/profileContext';
import { Spinner } from '../../common';

const ButtonPlatform = forwardRef(
  ({ onClick, className, children, loading, isMini = false, ...props }, ref) => {
    const [profile] = useContext(ProfileContext);
    const { masterOrganization: { customConfigurations: { buttonRadius = '9999px' } = {} } = {} } =
      profile;

    return (
      <button
        ref={ref}
        {...props}
        className={clsx(
          'self-start font-bold bg-backgroundGrey text-textAndIcons flex items-center gap-2 shadow',
          className,
          isMini ? 'px-4 py-1 text-md' : 'px-6 py-2 text-lg'
        )}
        style={{
          borderRadius: buttonRadius
        }}
        disabled={loading}
        onClick={onClick}>
        {children}
        <Spinner isSubmitting={loading} />
      </button>
    );
  }
);

export default ButtonPlatform;
