import React, { useState, useEffect } from 'react';
import markerSDK from '@marker.io/browser';

import bug from '../../assets/svgLogos/bug.svg';

const BugReport = () => {
  const [widget, setWidget] = useState(null);

  useEffect(() => {
    (async () => {
      const w = await markerSDK.loadWidget({ project: '6627b8e3c91ffe6e012af745' });
      w.hide();
      w.on('show', function listener() {
        w.hide();
      });
      setWidget(w);
    })();
  }, []);

  if (!widget) return null;

  return (
    <button className="fixed bottom-4 right-4 z-50 w-12 h-12 cursor-pointer outline-none">
      <img src={bug} alt="bug" onClick={() => widget?.capture('advanced')} />
    </button>
  );
};

export default BugReport;
