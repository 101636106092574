import { pathToRegexp } from 'path-to-regexp';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { scrollToFragment } from 'scroll-to-fragment';

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ProfileContext } from './contexts/profileContext';
import { fetchTranslations, fetchTranslationsProfileCreation, getAdminProfile } from './service';

import { Base, Dashboard, Login, Magic, Profiles } from './pages';

import './App.scss';
import { Loader, BugReport } from './components';
import { changeFavicon } from './utils';

function App() {
  const [translationsLoaded, setTranslationsLoaded] = useState(false);
  const [isAuth, setAuth] = useState(ProfileContext);

  const [profile, setProfileData] = useContext(ProfileContext);
  const location = useLocation();

  const fetchUser = async () => {
    try {
      if (window.location.pathname.includes('magic')) return;
      const { data } = await getAdminProfile();
      const loadedUserData = { ...data, loaded: true };
      // add permission flags to the admin user
      // loadedUserData.access = getPermisssionFlagsForProfile(data);
      // loadedUserData.language = loadedUserData.language || 'en';
      // localStorage.setItem('lang', loadedUserData.language);
      setProfileData(loadedUserData);
      setAuth(true);

      // Handle favicon
      if (loadedUserData?.masterOrganization?.favicon) {
        const faviconUrl = `${process.env.REACT_APP_AWS_URL}${loadedUserData.masterOrganization.favicon}`;
        changeFavicon(faviconUrl);
      }
    } catch (error) {
      setAuth(false);
      console.log('EEE', error);
    }
  };

  useEffect(() => {
    fetchUser();
    scrollToFragment(); // This is for first time loading only
  }, []);

  const loadTranslations = async () => {
    try {
      i18next.init({
        interpolation: { escapeValue: false },
        saveMissing: true,
        returnEmptyString: false
      });
      i18next.languages = ['en', 'fr', 'de', 'it'];
      i18next.use(initReactI18next).init({
        fallbackLng: 'en',
        lng: 'en',
        ns: ['translations'],
        defaultNS: 'translations'
      });

      const { data: { en, de, fr, it } = {} } = await fetchTranslations();

      const { data: { en: en_p, de: de_p, fr: fr_p, it: it_p } = {} } =
        await fetchTranslationsProfileCreation();

      const en_merged = { ...en, form: en_p?.form, myProfile: en_p?.myProfile };
      const de_merged = { ...de, form: de_p?.form, myProfile: de_p?.myProfile };
      const fr_merged = { ...fr, form: fr_p?.form, myProfile: fr_p?.myProfile };
      const it_merged = { ...it, form: it_p?.form, myProfile: it_p?.myProfile };

      i18next.addResourceBundle('en', 'translations', en_merged);
      i18next.addResourceBundle('fr', 'translations', fr_merged);
      i18next.addResourceBundle('de', 'translations', de_merged);
      i18next.addResourceBundle('it', 'translations', it_merged);

      i18next.changeLanguage('en'); // Initial language
    } catch (error) {
      console.log('Error', error);
    } finally {
      setTranslationsLoaded(true);
    }
  };

  useEffect(() => {
    if (translationsLoaded) return;

    loadTranslations();
  }, [translationsLoaded]);

  if (!translationsLoaded) return <Loader />;

  return (
    <>
      <ToastContainer />

      <Routes>
        {/*  Admin  */}
        {/*<Route path="/admin-login" element={<AdminLogin />} />*/}

        <Route path="*" element={<Navigate replace to="/" />} />
        <Route path="/" element={<Base />}>
          <Route
            path="/"
            element={
              <TokenExistence>
                <Login />
              </TokenExistence>
            }
          />
          <Route path="magic" element={<Magic />} />
          <Route path="dashboard" element={<Dashboard role={profile.role} />} />
          <Route path="dashboard/:groupId" element={<Dashboard role={profile.role} />} />
          <Route path="/:groupId/profiles" element={<Profiles role={profile.role} />} />
          <Route path="/:groupId/profiles/:profileId" element={<Profiles role={profile.role} />} />
          {/* <Route
            path="dashboard"
            element={
              <ProtectedAdmin role={profile.role}>
                <AdminLayout />
              </ProtectedAdmin>
            }>
            <Route path="/dashboard" element={<Navigate to="profile" />} />
            <Route path="profile" element={<AdminProfile role={profile.role} />} />
            <Route
              path="organisation"
              element={
                <ProtectedByPermissions
                  role={profile.role}
                  userPermissions={profile.permissions}
                  requiredPermissions={[permissions.CREATE_ORGANIZATION]}>
                  <Organization />
                </ProtectedByPermissions>
              }
            />
            <Route
              path="organisation/:orgId"
              element={
                <ProtectedByPermissions
                  role={profile.role}
                  userPermissions={profile.permissions}
                  requiredPermissions={[permissions.MANAGE_ORGANIZATIONS_AND_INFORMATION]}>
                  <Organization />
                </ProtectedByPermissions>
              }
            />
            <Route
              path="organisation/:orgId/accounts"
              element={
                <ProtectedByPermissions
                  role={profile.role}
                  userPermissions={profile.permissions}
                  requiredPermissions={[permissions.MANAGE_ORGANIZATION_SUBADMINS]}>
                  <SubAdminAccounts />
                </ProtectedByPermissions>
              }
            />
            <Route
              path="organisation/:orgId/accounts/manage"
              element={
                <ProtectedByPermissions
                  role={profile.role}
                  userPermissions={profile.permissions}
                  requiredPermissions={[permissions.MANAGE_ORGANIZATION_SUBADMINS]}>
                  <ManageAdminAccounts />
                </ProtectedByPermissions>
              }
            />
            <Route
              path="organisation/:orgId/profiles"
              element={
                <ProtectedByPermissions
                  role={profile.role}
                  userPermissions={profile.permissions}
                  requiredPermissions={[permissions.MANAGE_ORGANIZATION_PROFILES]}>
                  <ProfilesList />
                </ProtectedByPermissions>
              }
            />
            <Route
              path="organisation/:orgId/profiles/move"
              element={
                <ProtectedByPermissions
                  role={profile.role}
                  userPermissions={profile.permissions}
                  requiredPermissions={[permissions.MOVE_PROFILES]}>
                  <MoveProfiles />
                </ProtectedByPermissions>
              }
            />
            <Route
              path="organisation/:orgId/profiles/add"
              element={
                <ProtectedByPermissions
                  role={profile.role}
                  userPermissions={profile.permissions}
                  requiredPermissions={[permissions.MANAGE_ORGANIZATION_PROFILES]}>
                  <Profile />
                </ProtectedByPermissions>
              }
            />
            <Route
              path="organisation/:orgId/profiles/:id"
              element={
                <ProtectedByPermissions
                  role={profile.role}
                  userPermissions={profile.permissions}
                  requiredPermissions={[permissions.MANAGE_ORGANIZATION_PROFILES]}>
                  <Profile />
                </ProtectedByPermissions>
              }
            />
            <Route
              path="organisation/:orgId/profiles/validate"
              element={
                <ProtectedByPermissions
                  role={profile.role}
                  userPermissions={profile.permissions}
                  requiredPermissions={[permissions.MANAGE_ORGANIZATION_PROFILES]}>
                  <ValidateProfilesList />
                </ProtectedByPermissions>
              }
            />
            <Route
              path="organisation/:orgId/profiles/validate/:id"
              element={
                <ProtectedByPermissions
                  role={profile.role}
                  userPermissions={profile.permissions}
                  requiredPermissions={[permissions.MANAGE_ORGANIZATION_PROFILES]}>
                  <ValidateProfilesList />
                </ProtectedByPermissions>
              }
            />
            <Route path="subadmin">
              <Route
                path="accounts"
                element={
                  <ProtectedByPermissions
                    role={profile.role}
                    userPermissions={profile.permissions}
                    requiredPermissions={[permissions.MANAGE_SYSTEM_SUBADMINS]}>
                    <SubAdminAccounts />
                  </ProtectedByPermissions>
                }
              />
              <Route
                path="manage"
                element={
                  <ProtectedByPermissions
                    role={profile.role}
                    userPermissions={profile.permissions}
                    requiredPermissions={[permissions.MANAGE_SYSTEM_SUBADMINS]}>
                    <ManageAdminAccounts />
                  </ProtectedByPermissions>
                }
              />
            </Route>
          </Route> */}
        </Route>
      </Routes>

      <BugReport />
    </>
  );
}

export default App;

function ProtectedAdmin({ role = null, children }) {
  let token = localStorage.getItem('adminToken');

  //  @WASIF HOW TO UPDATE REDIRECTION HERE
  if (!token) {
    return <Navigate to="/" replace />;
  }

  const authorizedRoles = [
    'systemAdmin',
    'systemSubAdmin',
    'organizationAdmin',
    'organizationSubAdmin'
  ];

  //  @WASIF HOW TO UPDATE REDIRECTION HERE
  if (role && !authorizedRoles.includes(role)) {
    // remove admin token from localstorage
    localStorage.removeItem('adminToken');
    // redirect
    return <Navigate to="/" replace />;
  }

  return children;
}

function ProtectedByPermissions({
  role = null,
  userPermissions = [],
  requiredPermissions = [],
  children
}) {
  if (role && userPermissions && role !== 'systemAdmin') {
    const userPermissionIds = userPermissions.map((permission) => permission._id);

    const hasRequiredPermissions = requiredPermissions.every((permission) => {
      return userPermissionIds.includes(permission);
    });

    if (!hasRequiredPermissions) {
      return <Navigate to="/dashboard" replace />;
    }
  }
  return children;
}

function TokenExistence({ children }) {
  let token = localStorage.getItem('adminToken');
  if (token) {
    return <Navigate to="/dashboard" replace />;
  }
  return children;
}

export const hasColoredBackground = (location) => {
  const validRoutes = [
    // 'accounts',
    // 'manage',
    'organisation',
    'organisation/:orgId',
    'organisation/:orgId/profiles/move',
    'organisation/:orgId/profiles/add',
    'organisation/:orgId/profiles/:id',
    'organisation/:orgId/accounts/manage',
    'subadmin/manage'
  ];

  const currentPath = location.pathname.replace('/dashboard/', '');

  // Check if the current path matches any of the valid routes
  for (let route of validRoutes) {
    const regex = pathToRegexp(route);
    if (regex.test(currentPath)) {
      return true;
    }
  }

  return false;
};
