import React from 'react';

export default function PDFType2() {
  return (
    <footer className="footer-pdf footer-pdf2">
      <div className="left-container">
        <img src="/images/registeration-logo.png" />
      </div>
      <div className="right-container">
        <p className="description">
          Des personnes en situation de handicap, visible ou invisible, concourent sur cette
          épreuve, dans les mêmes conditions que les autres candidats.
        </p>
        <img className="img-right" src="/images/footer-logos-1.png" />
      </div>
    </footer>
  );
}
