import 'cropperjs/dist/cropper.css';
import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import useOrganizationColors from '../hooks/useOrganizationColors';
import { getUploadingFileData, postUserImageLink, uploadFileToAWS } from '../service';

import { Avatar } from './common';
import Button from './common/button';
import ImageCropper from './common/imageCropper';
import { EditIcon, RefreshIcon } from './icons';

const IMAGE_URL = process.env.REACT_APP_AWS_URL + 'profile-images/';

const ProfileImage = forwardRef(({ profileId, url, onUpdate }, ref) => {
  const imgRef = useRef();
  const [avatar, setAvatar] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [croppedImg, setCroppedImg] = useState(null);
  const [showImgCropper, setShowImgCropper] = useState(false);
  const [croppedImageBlob, setCroppedImageBlob] = useState(null);
  const { t } = useTranslation();

  const color = useOrganizationColors();

  useEffect(() => {
    setAvatar(url ? `${IMAGE_URL}${url}` : '/images/editAvatar.png');
  }, [url]);

  useImperativeHandle(ref, () => ({
    saveImage
  }));

  const saveImage = async () => {
    if (!croppedImageBlob?.type) {
      return;
    }
    setIsSubmitting(true);
    let imgBlob = croppedImageBlob;
    const body = {
      type: imgBlob.type,
      name: 'img.png',
      isProfileImage: true
    };
    try {
      const {
        data: { url, finalName }
      } = await getUploadingFileData(body);
      await uploadFileToAWS(url, imgBlob, body.type);
      await postUserImageLink({
        _id: profileId,
        image: finalName
      });
      // Request to save data in backend passo
      toast.success('Image Updated!');
      onUpdate(finalName);
    } catch (error) {
      console.error('Upload Error', error);
      toast.error('Error with uploading the profile picture!');
    } finally {
      setIsSubmitting(false);
    }
  };

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      var imgFile = e.target.files[0];
      var imgURL = URL.createObjectURL(imgFile);
      setAvatar(imgURL);
      setCroppedImg(imgURL);
      setShowImgCropper(true);
    }
  };

  const imgSetter = (blob) => {
    setCroppedImageBlob(blob);
    var img = URL.createObjectURL(blob);
    setCroppedImg(img);
    setAvatar(img);
    setShowImgCropper(false);
  };

  return (
    <section className="profile-image">
      {showImgCropper ? (
        <ImageCropper
          imageUrl={avatar}
          onImageSet={imgSetter}
          turnOffCropper={() => setShowImgCropper(false)}
        />
      ) : (
        <>
          <Avatar
            className="mx-auto px-auto mb-6 overflow-hidden rounded-full"
            img={avatar ? avatar : croppedImg}
          />

          {/* CROP IMAGE */}
          <div className="text-center mb-4 flex items-center justify-center">
            <Button.ButtonOutlined
              className="flex items-centers gap-2"
              onClick={() => setShowImgCropper(!showImgCropper)}>
              <div className="hidden">
                <input type="file" ref={imgRef} onChange={onImageChange} accept="image/*" />
              </div>
              <EditIcon color={color?.primary} /> {t('editProfileImage.cropImage')}
            </Button.ButtonOutlined>
          </div>

          {/* REPLACE IMAGE */}
          <div className="text-center mb-4 flex items-center justify-center">
            <Button.ButtonOutlined
              className="flex items-centers gap-2"
              onClick={() => {
                imgRef.current.click();
              }}>
              <div className="hidden">
                <input type="file" ref={imgRef} onChange={onImageChange} accept="image/*" />
              </div>
              <RefreshIcon color={color?.primary} />
              {t('editProfileImage.replaceImage')}
            </Button.ButtonOutlined>
          </div>
        </>
      )}
    </section>
  );
});

export default ProfileImage;
