import clsx from 'clsx';

export default function TextInput({ label, name, className, ...props }) {
  return (
    <div className={clsx('w-full group bg-backgroundGrey px-4 rounded-lg', className)}>
      <input
        type="text"
        id={name}
        name={name}
        className="block p-0 pt-4 w-full text-sm text-gray-900 bg-transparent border-none appearance-none focus:outline-none focus:ring-0 peer"
        placeholder=" "
        {...props}
      />
      <label
        htmlFor={name}
        className="peer-focus:font-medium block text-sm text-textAndIcons duration-300 transform scale-75 top-4 origin-[0] rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-textAndIcons peer-placeholder-shown:!-translate-y-[100%] peer-focus:!-translate-y-[220%] peer-focus:scale-75 pointer-events-none !-translate-y-[220%]">
        {label}
      </label>
    </div>
  );
}
