import { useFormik } from 'formik';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import GenericField from '../genericField';

import { genericFormSchema } from '../../utils/schema';

import { bestApprentice, fullRangeGroup, genderOptions } from '../../data/professionOptions';

import AbilympicsProfessions from '../../data/abilympicsProfession';

const GenericForm = forwardRef(
  (
    {
      profile,
      languages,
      fields,
      regions,
      professions,
      listSwissRegions,
      listProfessionalAssoc,
      listSwissFunction,
      listSwissJobTitle,
      listVocationalSchool,
      handleFormSubmit,
      orgPrefix
    },
    ref
  ) => {
    const { t } = useTranslation();

    const [initialValues, setInitialValues] = useState({ ...profile });
    const [submitting, setSubmitting] = useState(false);

    useImperativeHandle(ref, () => ({
      save: formik.submitForm
    }));

    const formSubmitHandler = async (values) => {
      try {
        setSubmitting(true);
        await handleFormSubmit(values);
      } catch (error) {
        console.log(error);
        toast.error(t(`form.error`));
      } finally {
        setSubmitting(false);
      }
      console.log(values);
    };

    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: genericFormSchema,
      enableReinitialize: true,
      validateOnBlur: true,
      onSubmit: formSubmitHandler
    });

    useEffect(() => {
      const objBestApprentice = bestApprentice.filter(
        (item) => item.value === profile.bestApprentice
      );
      const objbestWorker = bestApprentice.filter((item) => item.value === profile.bestWorker);
      const objgender = genderOptions.filter((item) => item.value === profile.gender);
      const ObjnatFinalsRank = fullRangeGroup.filter(
        (item) => item.value === parseInt(profile.natFinalsRank)
      );
      const ObjworldSkillsRank = fullRangeGroup.filter(
        (item) => item.value === parseInt(profile.worldSkillsRank)
      );
      const objnatFinalsRegion = regions.filter((item) => item.value === profile.natFinalsRegion);

      const ObjeuroSkillsRank = fullRangeGroup.filter(
        (item) => item.value === parseInt(profile.euroSkillsRank)
      );

      let objworldSkillsProfession = professions.filter(
        (item) => item.value === profile.worldSkillsProfession
      );

      // ch champion form
      const objParticipationType = t('form.participationType.values', {
        returnObjects: true
      })?.filter((item) => item.value === profile.participationType);
      const objSwissRegion = listSwissRegions.filter((item) => item.value === profile.region);
      const objProfessionAssoc = listProfessionalAssoc.filter(
        (item) => item.value === profile.associationProfession
      );
      const objSwissFunction = listSwissFunction.filter((item) => {
        if (profile.functions.includes(item.value)) {
          return item;
        }
      });
      const objSwissJobTitle = listSwissJobTitle.filter(
        (item) => item.value === profile.specialJobTitle
      );
      const objSwissJobChamp = professions.filter((item) => item.value === profile.profession);
      const objVocationalSchool = listVocationalSchool.filter(
        (item) => item.value === profile.vocationSchool
      );
      const ObjeuroSwissYear = fullRangeGroup.filter(
        (item) => item.value === parseInt(profile.swissChampionYear)
      );
      const ObjeuroSwissRank = fullRangeGroup.filter(
        (item) => item.value === parseInt(profile.swissChampionRank)
      );

      // Abylimpics
      const objAbilympicsProfession = AbilympicsProfessions.filter(
        (item) => item.label === profile.abilympicsProfession
      );

      setInitialValues({
        ...initialValues,
        ...profile,
        bestApprentice: objBestApprentice?.[0],
        bestWorker: objbestWorker?.[0],
        worldSkillsProfession: objworldSkillsProfession?.[0],
        gender: objgender?.[0],
        natFinalsRank: ObjnatFinalsRank?.[0],
        worldSkillsRank: ObjworldSkillsRank?.[0],
        euroSkillsRank: ObjeuroSkillsRank?.[0],
        natFinalsRegion: objnatFinalsRegion?.[0],
        // ch champion form
        swissChampionYear: ObjeuroSwissYear?.[0],
        swissChampionRank: ObjeuroSwissRank?.[0],
        region: objSwissRegion?.[0],
        specialJobTitle: objSwissJobTitle?.[0],
        profession: objSwissJobChamp?.[0] || '',
        associationProfession: objProfessionAssoc?.[0],
        vocationSchool: objVocationalSchool?.[0],
        functions: objSwissFunction,
        participationType: objParticipationType?.[0],
        abilympicsProfession: objAbilympicsProfession?.[0],
        montherLanguage: profile?.montherLanguage
          ? { value: profile?.montherLanguage, label: profile?.montherLanguage?.toUpperCase() }
          : ''
      });
    }, [profile, regions, professions]);

    return (
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2 w-full">
        {/* <div className="flex w-full items-center justify-between">
        <h1 className="heading">{t(`form.title`)}</h1>
        <Button.ButtonRounded type="submit" loading={submitting}>
          {t(`form.submit`)}
        </Button.ButtonRounded>
      </div> */}

        {fields.map(({ key: fieldKey, required: isRequired }, index) => (
          <GenericField
            languages={languages}
            key={index}
            index={index}
            fieldKey={fieldKey}
            isRequired={isRequired}
            formik={formik}
            regions={regions}
            professions={professions}
            listSwissRegions={listSwissRegions}
            listProfessionalAssoc={listProfessionalAssoc}
            listSwissFunction={listSwissFunction}
            listSwissJobTitle={listSwissJobTitle}
            listVocationalSchool={listVocationalSchool}
            orgPrefix={orgPrefix}
          />
        ))}
      </form>
    );
  }
);

export default GenericForm;
