import React from 'react';
import HeaderPdf1 from './headerPdf1';
import HeaderPdf2 from './headerPdf2';

export default function HeaderPdf({ subtype = '', ...props }) {
  switch (subtype) {
    case 'header1':
      return <HeaderPdf1 {...props} />;
    case 'header2':
      return <HeaderPdf2 {...props} />;
    default:
      return <HeaderPdf1 {...props} />;
  }
}
