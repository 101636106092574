import clsx from 'clsx';
import { useFormik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ProfileContext } from '../contexts/profileContext';
import { permissions } from '../data/permissions';
import { createSubAdmin, deleteSubAdmin, getSubAdmins } from '../service';
import { addNewTeamMemberSchema } from '../utils/schema';
import { Popover, RadioSelect, Spinner, TextInput } from './common';
import Button from './common/button';
import { CloseButtonIcon, DefaultUserIcon, PlusButtonIcon } from './icons';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import Popup from 'reactjs-popup';
import { getTranslation } from '../utils';

const TeamMemberManager = () => {
  const { t } = useTranslation();
  const [profile] = useContext(ProfileContext);
  const profilePermissionIds = profile.permissions?.map((permission) => permission._id);
  const { masterOrganization: { customConfigurations: { colors } = {} } = {} } = profile;

  const scrollableRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [showAddNewTeamMember, setShowAddNewTeamMember] = useState(false);

  const canManageAdmins =
    profile.role === 'systemAdmin' ||
    (profile.role === 'systemSubAdmin' &&
      profilePermissionIds.includes(permissions.MANAGE_SYSTEM_SUBADMINS));

  useEffect(() => {
    if (showAddNewTeamMember && scrollableRef.current) {
      const scroll = scrollableRef.current;
      scroll.scrollTop = scroll.scrollHeight;
    }
  }, [showAddNewTeamMember]);

  const getTeamMembers = async () => {
    if (canManageAdmins) {
      try {
        setIsLoading(true);
        const { data } = await getSubAdmins();
        setTeamMembers(data);
      } catch (e) {
        toast.error('Failed to get team members.');
        console.error('error:', e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const teamMemberFormik = useFormik({
    initialValues: { email: '', role: '' },
    validationSchema: addNewTeamMemberSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const { email, role } = values;
        await createSubAdmin({ email, role: role || 'editor' });
        toast.success('Team member added successfully.');
        setShowAddNewTeamMember(false);
        teamMemberFormik.resetForm();
        getTeamMembers();
      } catch (error) {
        console.error('Failed to add team member.');
        toast.error('Failed to add team member.');
      }
    }
  });

  const handleSubAdminDelete = async (adminId) => {
    try {
      await deleteSubAdmin({ _id: adminId });
      toast.success('Team member deleted successfully.');
      setShowAddNewTeamMember(false);
      teamMemberFormik.resetForm();
      getTeamMembers();
    } catch (error) {
      console.error('Failed to delete team member.');
      toast.error('Failed to delete team member.');
    }
  };

  const getRolesList = (role) => {
    const allRoles = t('roles', { returnObjects: true });

    return allRoles[role]?.map((roleItem) => <li key={roleItem}>{roleItem}</li>);
  };

  return (
    <>
      <Popover
        position="bottom left"
        size="md"
        onOpen={getTeamMembers}
        onClose={() => setShowAddNewTeamMember(false)}
        button={
          <button type="button" className={clsx('rounded-full flex items-center justify-center')}>
            <DefaultUserIcon />
          </button>
        }>
        <div>
          <header className="bg-[#F8F8F8] px-6 py-4 rounded-t-lg flex items-center justify-between">
            <h4 className="font-semibold">{t('editAdminTeamMembers.title')}</h4>
          </header>
          <main className="max-h-[80vh] overflow-y-auto p-6" ref={scrollableRef}>
            <div className="mb-4">
              <h2 className="text-lg font-bold mb-2">{t('editAdminTeamMembers.yourAccount')}</h2>
              <TextInput label="Email" value={profile?.email} disabled />
            </div>
            <Spinner isSubmitting={isLoading} color="#000000" />
            {['admins', 'editors'].map(
              (role) =>
                teamMembers?.[role]?.length > 0 && (
                  <section key={role}>
                    <div className="mb-4">
                      <h3 className="font-bold mb-2 flex items-center gap-2">
                        <Popup
                          trigger={<InformationCircleIcon className="w-4" />}
                          position="bottom left"
                          on="hover">
                          <div className="bg-[#F4F2F2] border rounded-xl shadow w-96 p-4">
                            <ul className="list-disc list-inline ml-4">{getRolesList(role)}</ul>
                          </div>
                        </Popup>
                        {role.charAt(0).toUpperCase() + role.slice(1)}:
                      </h3>
                    </div>
                    <ul className="my-6">
                      {teamMembers[role].map((teamMember) => (
                        <li key={teamMember._id} className="my-2 flex items-center gap-4">
                          <TextInput
                            name="email"
                            label={t('editAdminTeamMembers.memberEmail')}
                            value={teamMember.email}
                            disabled
                          />
                          <Popover
                            position="bottom right"
                            arrow={false}
                            button={
                              <button>
                                <CloseButtonIcon className="cursor-pointer" />
                              </button>
                            }>
                            <div className="px-4 py-2">
                              <h4 className="font-semibold">
                                {t('editAdminTeamMembers.confirmDeleteTitle')}
                              </h4>
                              <p className="text-sm my-2 text-secondary">
                                {t('editAdminTeamMembers.confirmDeleteDescription')}
                              </p>
                              <Button.ButtonFilled
                                type="button"
                                isMini
                                onClick={() => handleSubAdminDelete(teamMember._id)}>
                                {t('editAdminTeamMembers.confirmDeleteSubmit')}
                              </Button.ButtonFilled>
                            </div>
                          </Popover>
                        </li>
                      ))}
                    </ul>
                  </section>
                )
            )}
            {canManageAdmins && (
              <>
                <hr className="border-2" style={{ borderColor: colors?.primary }} />
                <PlusButtonIcon
                  onClick={() => setShowAddNewTeamMember(true)}
                  className="cursor-pointer mt-4"
                />
              </>
            )}
            {showAddNewTeamMember && (
              <div className="my-6">
                <h4 className="text-lg font-bold mb-2">
                  {t('editAdminTeamMembers.addNewTeamMemberTitle')}
                </h4>
                <form onSubmit={teamMemberFormik.handleSubmit}>
                  <TextInput
                    name="email"
                    label={t('editAdminTeamMembers.memberEmail')}
                    value={teamMemberFormik.values.email}
                    onChange={teamMemberFormik.handleChange}
                    onBlur={teamMemberFormik.handleBlur}
                    error={teamMemberFormik.errors.email}
                  />
                  <RadioSelect
                    name="role"
                    label={t('editAdminTeamMembers.selectRole')}
                    className="my-1"
                    value={teamMemberFormik.values.role}
                    touched={teamMemberFormik.touched.role}
                    onBlur={teamMemberFormik.handleBlur}
                    error={teamMemberFormik.errors.role}
                    options={[
                      {
                        id: 'select-role',
                        label: t('editAdminTeamMembers.selectRole'),
                        value: 'select-role',
                        isDisabled: true
                      },
                      { id: 'admin', label: t('editAdminTeamMembers.admin'), value: 'admin' },
                      { id: 'editor', label: t('editAdminTeamMembers.editor'), value: 'editor' }
                    ]}
                    onChange={(option) => teamMemberFormik.setFieldValue('role', option, true)}
                  />
                  <footer className="flex items-center justify-end">
                    <Button.ButtonFilled type="submit">
                      <Spinner
                        isSubmitting={teamMemberFormik.isSubmitting}
                        color="#fff"
                        className="mr-2"
                      />
                      {t('editAdminTeamMembers.addNewButton')}
                    </Button.ButtonFilled>
                  </footer>
                </form>
              </div>
            )}
          </main>
        </div>
      </Popover>
    </>
  );
};

export default TeamMemberManager;
