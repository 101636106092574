import ClipLoader from 'react-spinners/ClipLoader';

const Spinner = ({ isSubmitting = false, color = '#FFF', setSize = 16, className = 'ml-2' }) =>
  isSubmitting ? (
    <ClipLoader color={color} loading={true} size={setSize} className={className} />
  ) : (
    <></>
  );

export default Spinner;
