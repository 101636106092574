import Select from 'react-select';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

export default function InputSelect({
  label,
  labelClassName = '',
  error,
  touched,
  options,
  onReset,
  ...props
}) {
  return (
    <div className="">
      {label && <label className={labelClassName}>{label}</label>}

      {onReset && props.value && (
        <ArrowPathIcon
          className="w-4 inline-block ml-2 cursor-pointer"
          title="reset"
          onClick={onReset}
        />
      )}

      <Select
        styles={{
          control: (base) => ({
            ...base,
            background: '#e4e4e7'
          })
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: 'gray',
            primary: 'black'
          }
        })}
        className="mb-4"
        options={options}
        {...props}
      />
      {error && touched && <p className="text text-red-500">{error}</p>}
    </div>
  );
}
