import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment } from 'react';
import { RadioIcon } from '../icons';

export default function DropDown({
  colors = {},
  hasBackdrop = true,
  options = [],
  onItemSelect = () => {},
  children,
  iconPosition = 'before', // 'before', 'after'
  position = 'left', // 'left', 'right',
  type = 'radio' // 'radio', 'button',
}) {
  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            {hasBackdrop && open && (
              <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            )}
            <div>
              <Menu.Button className="cursor-pointer">
                {/* className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3
                py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                hover:bg-gray-50" */}
                {/* <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                {children}
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items
                className={clsx(
                  'absolute z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                  {
                    'right-0': position === 'left'
                  }
                )}>
                <div className="py-1">
                  {options.map((option) => (
                    <Menu.Item key={option.id}>
                      {({ active }) => {
                        const isSelected = active || option.isSelected;
                        const isRadioType = type === 'radio';
                        const isIconBefore = iconPosition === 'before';
                        const isIconAfter = iconPosition === 'after';

                        const buttonClasses = clsx(
                          'px-4 py-2 text-sm w-full flex items-center', // Common classes
                          {
                            'bg-gray-100 text-gray-900': isSelected, // Active or selected options
                            'text-gray-700': !isSelected, // Unselected or inactive options
                            'justify-between': isRadioType && isIconAfter, // Additional class for radio type with icon after
                            'gap-2': !isRadioType || isIconBefore // Additional class for non-radio types or radio with icon before
                          }
                        );

                        return (
                          <button
                            type="button"
                            onClick={() => onItemSelect(option)}
                            className={buttonClasses}>
                            {option.icon && isIconBefore && <option.icon />}
                            {isRadioType && isIconBefore && (
                              <RadioIcon
                                className="w-4"
                                isChecked={option.isSelected}
                                color={colors.primary}
                              />
                            )}
                            <span className="text-textAndIcons text-left">{option.name}</span>
                            {isRadioType && isIconAfter && (
                              <RadioIcon
                                className="w-4"
                                isChecked={option.isSelected}
                                color={colors.primary}
                              />
                            )}
                            {option.icon && isIconAfter && <option.icon />}
                          </button>
                        );
                      }}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
}

{
}
