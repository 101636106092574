import { calculatePercentage } from '../../utils';
import { EditIcon } from '../icons';

export default function ItemCompletionIndicator({
  showProgress = true,
  value,
  total,
  children,
  onClick
}) {
  let progressBarWidth;

  if (!total) {
    progressBarWidth = calculatePercentage(value ? 1 : 0, 1);
  } else {
    progressBarWidth = calculatePercentage(value, total);
  }

  // Ensure progressBarWidth doesn't exceed 100%
  progressBarWidth = Math.min(progressBarWidth, 100);

  return (
    <div className="flex items-center justify-between gap-3 my-6 text-sm">
      <EditIcon className="cursor-pointer" onClick={onClick} />
      <div className="w-full">
        <div className="flex items-center justify-between">{children}</div>
        {showProgress && (
          <div className="w-full bg-[#D9D9D9] rounded-full h-2.5 mt-2">
            <div
              className="bg-[#515151] h-2.5 rounded-full"
              style={{ width: `${progressBarWidth}%` }}></div>
          </div>
        )}
      </div>
    </div>
  );
}
