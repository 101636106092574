import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getActiveGroups, moveGroupProfiles } from '../service';
import { moveGroupProfileSchema } from '../utils/schema';
import { Checkbox, RadioSelect } from './common';
import Button from './common/button';

export default function MoveProfile({
  profiles = [],
  groupId,
  groupName = '',
  onSubmit = () => {}
}) {
  const { t } = useTranslation();
  const [groups, setGroups] = useState([]);

  const fetchGroups = async () => {
    try {
      const { data: groupsList } = await getActiveGroups();
      const selectedFormattedGroups = groupsList.map((group) => ({
        _id: group._id,
        label: group.name,
        value: group._id
      }));
      setGroups(selectedFormattedGroups);
    } catch (error) {
      toast.error('Failed to get groups');
      console.error('Failed to get groups: ', error);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const formik = useFormik({
    initialValues: {
      group: '',
      profiles,
      resetProgress: false
    },
    validationSchema: moveGroupProfileSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const { group, profiles, resetProgress } = values;
        const moveToGroupId = group;
        await moveGroupProfiles(groupId, profiles, moveToGroupId, resetProgress);
        toast.success('Profile/s moved successfully.');
        const profileIds = profiles.map((profile) => profile._id);
        onSubmit(profileIds);
      } catch (error) {
        console.error('Failed to move profile/s:', error);
        toast.error('Failed to move profile/s.');
      }
    }
  });

  return (
    <div>
      <header className="bg-[#F8F8F8] px-6 py-4 rounded-t-lg flex flex-col items-start justify-start">
        <h4 className="font-semibold">{t('moveProfiles.title')}</h4>
        <p className="text-sm">
          {t('moveProfiles.from')} <span className="font-semibold underline">{groupName}</span>
        </p>
      </header>
      <main className="p-6">
        <form onSubmit={formik.handleSubmit}>
          <RadioSelect
            name="group"
            label={t('moveProfiles.moveField')}
            className="my-1"
            value={formik.values.group}
            touched={formik.touched.group}
            onBlur={formik.handleBlur}
            error={formik.errors.group}
            options={groups}
            onChange={(option) => {
              formik.setFieldValue('group', option, true);
            }}
          />
          <div className="mt-4 mb-6">
            <Checkbox
              value="resetProgress"
              label={t('moveProfiles.resetProgress')}
              description={t('moveProfiles.resetProgressDescription')}
              enabled={formik.values.resetProgress}
              onChange={() => formik.setFieldValue('resetProgress', !formik.values.resetProgress)}
            />
          </div>
          <footer className="flex item-center justify-end">
            <Button.ButtonFilled type="submit" isMini loading={formik.isSubmitting}>
              {t('moveProfiles.submit')}
            </Button.ButtonFilled>
          </footer>
        </form>
      </main>
    </div>
  );
}
