import { Dialog as ReactDialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment } from 'react';
import { BackButtonIcon, CrossIcon } from '../icons';

const sizeClassMap = {
  sm: 'sm:max-w-sm',
  lg: 'sm:max-w-lg',
  xl: 'sm:max-w-xl',
  '2xl': 'sm:max-w-2xl',
  '3xl': 'sm:max-w-3xl',
  default: 'sm:max-w-3xl'
};

export default function Dialog({
  show = false,
  size = 'sm',
  onClose,
  hasCloseButton = true,
  onBackButtonClick,
  header = '',
  children
}) {
  const sizeClasses = sizeClassMap[size] || sizeClassMap.default;

  return (
    <Transition.Root show={show} as={Fragment}>
      <ReactDialog as="div" className="relative z-10" onClose={onClose || (() => {})}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <ReactDialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full ${sizeClasses} overflow-visible`}>
                <ReactDialog.Title
                  className={clsx(
                    'rounded-t-lg flex items-center bg-[#F8F8F8] px-6 py-4 justify-between',
                    {
                      '!justify-end': !onBackButtonClick && !header
                    }
                  )}>
                  {onBackButtonClick ? (
                    <div className="flex items-center gap-4 w-full">
                      <BackButtonIcon onClick={onBackButtonClick} />
                      {header && (
                        <span className="font-semibold text-textAndIcons w-full">{header}</span>
                      )}
                    </div>
                  ) : (
                    header && <div className="font-semibold text-textAndIcons w-full">{header}</div>
                  )}
                  {hasCloseButton && onClose && (
                    <CrossIcon className="cursor-pointer" onClick={onClose} />
                  )}
                </ReactDialog.Title>
                <div className="py-4 px-6">{children}</div>
              </ReactDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </ReactDialog>
    </Transition.Root>
  );
}
