const bestApprentice = [
  {
    label: 'Oui',
    value: 'oui'
  },
  {
    label: 'Non',
    value: 'no'
  }
];
const range = [
  {
    label: '1',
    value: 1
  },
  {
    label: '2',
    value: 2
  },
  {
    label: '3',
    value: 3
  },
  {
    label: '4',
    value: 4
  },
  {
    label: '5',
    value: 5
  }
];

const rangeGrouped = [
  {
    label: '1',
    value: 1
  },
  {
    label: '2',
    value: 2
  },
  {
    label: '3',
    value: 3
  },
  {
    label: '4-8',
    value: 4
  },
  {
    label: '9+',
    value: 5
  }
];

const genderOptions = [
  {
    label: 'Masculin',
    value: 'masculin'
  },
  {
    label: 'Féminin',
    value: 'féminin'
  }
];

const chRangeGrouped = Array.from({ length: 100 }, (_, index) => index + 1).map((item) => ({
  label: item.toString(),
  value: item
}));

const fullRangeGroup = Array.from({ length: 100 }, (_, index) => index + 1).map((item) => ({
  label: item.toString(),
  value: item
}));

export { fullRangeGroup, bestApprentice, range, rangeGrouped, genderOptions, chRangeGrouped };
