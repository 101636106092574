import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { addGroup } from '../service';
import { addNewGroupSchema } from '../utils/schema';
import { Dialog, TextInput } from './common';
import Button from './common/button';

const NewGroup = ({
  groupColumn,
  showAddNewGroup = false,
  setShowAddNewGroup = () => {},
  onUpdate = () => {}
}) => {
  const { t } = useTranslation();

  const groupNameFormik = useFormik({
    initialValues: { groupName: '' },
    validationSchema: addNewGroupSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await addGroup({ name: values.groupName, groupColumn: groupColumn?._id });
        toast.success('Group created successfully.');
        setShowAddNewGroup(false);
        onUpdate();
      } catch (error) {
        console.error('Failed to create group.');
        toast.error('Failed to create group.');
      }
    }
  });

  return (
    <Dialog
      show={showAddNewGroup}
      onClose={() => setShowAddNewGroup(false)}
      header={t('group.title')}>
      <form onSubmit={groupNameFormik.handleSubmit}>
        <TextInput
          name="groupName"
          label={t('group.groupNameField')}
          value={groupNameFormik.values.groupName}
          onChange={groupNameFormik.handleChange}
          onBlur={groupNameFormik.handleBlur}
          error={groupNameFormik.errors.groupName}
        />
        <footer className="w-full flex items-center justify-end mt-2">
          <Button.ButtonFilled type="submit" loading={groupNameFormik.isSubmitting}>
            {t('group.submit')}
          </Button.ButtonFilled>
        </footer>
      </form>
    </Dialog>
  );
};

export default NewGroup;
