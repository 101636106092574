import FullRoundedButton from './fullRoundedButton';
import ButtonOutlined from './buttonOutlined';
import ButtonRounded from './buttonRounded';
import ButtonPlatform from './buttonPlatform';
import ButtonFilled from './buttonFilled';
import ButtonNext from './buttonNext';

const Button = {
  FullRoundedButton,
  ButtonPlatform,
  ButtonOutlined,
  ButtonRounded,
  ButtonFilled,
  ButtonNext
};

export default Button;
