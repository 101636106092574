import React, { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { FaChevronCircleLeft } from 'react-icons/fa';
import Partials from '..';

export default function HeaderMain({ menuShow }) {
  const [popupShow, setPopupShow] = useState(false);
  const handler = () => setPopupShow(!popupShow);

  return (
    <>
      <section className="header header-fixed">
        <div className={`flex gap-4 items-center ${menuShow ? '' : 'hidden'}`}>
          <button className="btn btn-rounded btn-menu" onClick={handler}>
            <GiHamburgerMenu className="icon" />
          </button>
        </div>
      </section>
      <div className={`menu-popup ${popupShow ? '' : 'hidden'}`}>
        <div className="popup-bar">
          <FaChevronCircleLeft size={30} color={'#fff'} onClick={handler} />
        </div>
        <div className="p-4">
          <Partials.MenuItems isPopupMenu={true} />
        </div>
      </div>
    </>
  );
}

HeaderMain.dfaultProps = {
  menuShow: true
};
