import { ArrowPathIcon } from '@heroicons/react/24/outline';
import Select, { components } from 'react-select';
import { RadioIcon } from '../icons';
import clsx from 'clsx';
import useOrganizationColors from '../../hooks/useOrganizationColors';
import { hexToRgba } from '../../utils/utils';

const RadioOption = (props) => {
  const color = useOrganizationColors();
  return (
    <components.Option {...props}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
        {props.label}
        {!props.isDisabled && (
          <RadioIcon
            className="w-6 h-6"
            isChecked={props.isSelected}
            color={color?.secondary}
            checkColor={color?.primary}
          />
        )}
        {/* <span
          style={{
            height: '16px',
            width: '16px',
            borderRadius: '50%',
            border: '2px solid',
            borderColor: props.isSelected ? '#4F46E5' : '#d1d5db',
            marginRight: '10px',
            backgroundColor: props.isSelected ? '#4F46E5' : '#fff'
          }}
        /> */}
      </div>
    </components.Option>
  );
};

export default function MultiSelect({
  isReactSelect,
  label,
  labelClassName = '',
  error,
  touched,
  options,
  selected,
  onReset,
  ...props
}) {
  const color = useOrganizationColors();

  return (
    <div className="w-full group bg-backgroundGrey rounded-lg my-4 isolate  ">
      {label && (
        <div className="relative">
          <label
            className={clsx(
              'px-6 peer-focus:font-medium absolute text-sm text-textAndIcons duration-300 transform -translate-y-6 scale-75 top-4 z-50 origin-[0] rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-textAndIcons peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6',
              labelClassName
            )}>
            {label}
          </label>
        </div>
      )}
      {onReset && props.value && (
        <ArrowPathIcon
          className="w-4 inline-block ml-2 cursor-pointer"
          title="reset"
          onClick={onReset}
        />
      )}
      <Select
        components={{ Option: RadioOption }}
        styles={{
          menu: (base) => ({
            ...base,
            width: '100%'
            // maxHeight: '200px',
            // overflowY: 'auto' // Enable scroll
          }),
          control: (base) => ({
            ...base,
            border: '0',
            borderRadius: '0.5rem',
            background: '#F6F6F6',
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem',
            color: '#111827',
            boxShadow: 'none'
          }),
          placeholder: (base) => ({
            ...base,
            color: '#111827',
            fontSize: '0.875rem',
            lineHeight: '1.25rem'
          })
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: hexToRgba(color?.secondary, color?.intensitySecondary),
            primary: color?.primary
          }
        })}
        className="mb-4"
        options={options}
        {...props}
      />
      {error && touched && <p className="text text-red-500">{error}</p>}
    </div>
  );
}
