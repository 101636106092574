import React from 'react';

export default function PDFType3() {
  return (
    <footer>
      <div className="bg-cblue-500 flex gap-4">
        <div className="bg-cblue-500 w-2/6 p-6">
          <img src="/images/registeration-logo.png" />
        </div>
        <div className="w-full flex justify-between items-center p-6">
          <img className="w-44" src="/images/footer-logos-2.png" />
          <p className="text-2xl">
            Des personnes en situation de handicap, visible ou invisible, concourent sur cette
            épreuve, dans les mêmes conditions que les autres candidats.
          </p>
        </div>
      </div>
    </footer>
  );
}
