import React from 'react';
import { RxCross1 } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';

export default function HeaderProfile() {
  const navigate = useNavigate();
  const handler = () => {
    navigate('/');
  };
  return (
    <section className="header relative">
      <div className="flex gap-4 items-center">
        <button className="btn btn-rounded btn-times" onClick={handler}>
          <RxCross1 className="icon" />
        </button>
        <h1 className="heading">MON PROFIL</h1>
      </div>
    </section>
  );
}
