import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';

import { ArchiveIcon } from '../icons';

const CSVInput = ({
  fileType = '.csv',
  onFileProcessed,
  headers,
  sheetName,
  withoutHeader = false,
  maxSize = 100
}) => {
  const fileInput = useRef(null);
  const [fileName, setFileName] = useState('');

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    setFileName(file.name);

    const fileSize = file.size / (1024 * 1024); // in MB

    if (fileSize > maxSize) {
      toast(`File size should not exceed ${maxSize} MB`);
      setFileName('');
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const targetSheetName = sheetName || workbook.SheetNames[0];
        const worksheet = workbook.Sheets[targetSheetName];
        const range = XLSX.utils.decode_range(worksheet['!ref']);

        const output = {};

        // Check if headers are not provided
        if (!headers || headers.length === 0) {
          headers = [];
          for (let colIndex = range.s.c; colIndex <= range.e.c; colIndex++) {
            const cell = worksheet[XLSX.utils.encode_cell({ r: range.s.r, c: colIndex })];
            if (cell && cell.v) {
              headers.push(cell.v);
            }
          }
        }

        if (withoutHeader) {
          output['EMAIL ADDRESS'] = [];

          for (let rowIndex = range.s.r; rowIndex <= range.e.r; rowIndex++) {
            const cell = worksheet[XLSX.utils.encode_cell({ r: rowIndex, c: 0 })];
            if (cell && cell.v) {
              output['EMAIL ADDRESS'].push(cell.v);
            }
          }
        } else {
          headers.forEach((header) => (output[header] = []));

          for (let rowIndex = range.s.r + 1; rowIndex <= range.e.r; rowIndex++) {
            headers.forEach((header, columnIndex) => {
              const cell = worksheet[XLSX.utils.encode_cell({ r: rowIndex, c: columnIndex })];
              output[header].push(cell ? cell.v : null);
            });
          }
        }

        onFileProcessed(output, file);
      } catch (error) {
        console.error('Error processing file:', error);
        toast.error('Error processing file. Please try again.');
      } finally {
        // Reset the file input value after processing
        fileInput.current.value = '';
        // Don't reset fileName here
      }
    };

    reader.onerror = (error) => {
      console.error('FileReader error:', error);
      toast.error('Error reading file. Please try again.');
      setFileName('');
    };

    reader.readAsBinaryString(file);

    // Reset the file input value to allow selecting the same file again
    event.target.value = '';
  };

  return (
    <div className="flex items-center justify-center">
      <label className="flex items-center justify-center w-32 px-4 py-2 cursor-pointer text-gray-500rounded text-center rounded-lg bg-gray-100 hover:bg-gray-200">
        <input
          type="file"
          accept={fileType}
          name="file"
          id="fileInput"
          className="hidden"
          ref={fileInput}
          onChange={handleFileUpload}
        />
        <div className="flex flex-col items-center">
          <ArchiveIcon className="w-12 h-12" />
          {fileName ? (
            <span className="w-32 truncate text-xs">{fileName}</span>
          ) : (
            <span className="w-32 truncate text-xs text-gray-500">Upload {fileType} Sheet</span>
          )}
        </div>
      </label>
    </div>
  );
};

export default CSVInput;
