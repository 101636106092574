import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { sendEmailNotification } from '../service';
import { emailNotificationSchema } from '../utils/schema';
import { RadioSelect, Spinner, TextInput } from './common';
import Button from './common/button';
import { MessageIcon } from './icons';

export default function EmailProfiles({ groupId }) {
  const { t } = useTranslation();

  const emailNotificationFormik = useFormik({
    initialValues: {
      emailSubject: '',
      emailMessage: '',
      emailLanguage: 'en',
      notificationFor: 'not-started'
    },
    validationSchema: emailNotificationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const { emailLanguage, notificationFor } = values;
        await sendEmailNotification(groupId, {
          ...values,
          emailLanguage: emailLanguage,
          notificationFor: notificationFor
        });
        toast.success('Email send successfully.');
        emailNotificationFormik.resetForm();
      } catch (error) {
        console.error('Failed to send email.');
        toast.error('Failed to send email.');
      }
    }
  });

  return (
    <div className="p-6">
      <h6 className="text-2xl font-bold mb-2">{t('emailProfiles.title')}</h6>
      <form onSubmit={emailNotificationFormik.handleSubmit}>
        <RadioSelect
          label={t('emailProfiles.selectLanguage')}
          className="my-1"
          name="emailLanguage"
          value={emailNotificationFormik.values.emailLanguage}
          touched={emailNotificationFormik.touched.emailLanguage}
          onBlur={emailNotificationFormik.handleBlur}
          error={emailNotificationFormik.errors.emailLanguage}
          options={[
            {
              id: 'select-language',
              label: 'Select language',
              value: 'select-language',
              isDisabled: true
            },
            {
              id: 'en',
              label: 'EN',
              value: 'en'
            },
            {
              id: 'de',
              label: 'DE',
              value: 'de'
            },
            {
              id: 'fr',
              label: 'FR',
              value: 'fr'
            },
            {
              id: 'it',
              label: 'IT',
              value: 'it'
            }
          ]}
          onChange={(option) => {
            emailNotificationFormik.setFieldValue('emailLanguage', option, true);
          }}
        />
        <RadioSelect
          label={t('emailProfiles.selectProfileType')}
          className="my-1"
          name="notificationFor"
          value={emailNotificationFormik.values.notificationFor}
          touched={emailNotificationFormik.touched.notificationFor}
          onBlur={emailNotificationFormik.handleBlur}
          error={emailNotificationFormik.errors.notificationFor}
          options={[
            {
              id: 'select-profile',
              label: t('emailProfiles.profile.select'),
              value: 'select-profile',
              isDisabled: true
            },
            {
              id: 'not-started',
              label: t('emailProfiles.profile.notStarted'),
              value: 'not-started'
            },
            {
              id: 'in-progress',
              label: t('emailProfiles.profile.inProgress'),
              value: 'in-progress'
            },
            {
              id: 'validation-requested',
              label: t('emailProfiles.profile.vadliationRequested'),
              value: 'validation-requested'
            },
            {
              id: 'validated',
              label: t('emailProfiles.profile.validated'),
              value: 'validated'
            }
          ]}
          onChange={(option) => {
            emailNotificationFormik.setFieldValue('notificationFor', option, true);
          }}
        />
        <TextInput
          name="emailSubject"
          label={t('emailProfiles.emailSubject')}
          value={emailNotificationFormik.values.emailSubject}
          onChange={emailNotificationFormik.handleChange}
          onBlur={emailNotificationFormik.handleBlur}
          error={emailNotificationFormik.errors.emailSubject}
          className="mb-4"
        />
        <TextInput
          name="emailMessage"
          label={t('emailProfiles.emailMessage')}
          value={emailNotificationFormik.values.emailMessage}
          onChange={emailNotificationFormik.handleChange}
          onBlur={emailNotificationFormik.handleBlur}
          error={emailNotificationFormik.errors.emailMessage}
          className="mb-4"
        />
        <footer className="flex items-center justify-end">
          <Button.ButtonFilled>
            <Spinner isSubmitting={emailNotificationFormik.isSubmitting} color="#fff" />
            {!emailNotificationFormik.isSubmitting && <MessageIcon className="mr-1" />}
            {t('emailProfiles.submit')}
          </Button.ButtonFilled>
        </footer>
      </form>
    </div>
  );
}
