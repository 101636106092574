import clsx from 'clsx';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  GENERIC_NOTIFICATION_TYPE,
  GENERIC_NOTIFICATION_TYPE_COLOR,
  IMAGE_URL
} from '../../data/constants';
import {
  getNotifications,
  removeNotificationForAll,
  getUnreadNotificationsCount
} from '../../service';
import { formatDateWithDays } from '../../utils';
import { NotificationIcon } from '../icons';
import Popover from './popover';
import Loading from './loading';
import Button from '../common/button';
import { Tab } from '@headlessui/react';

const Notifications = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const abortControllerRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState({});
  const [activeTab, setActiveTab] = useState(GENERIC_NOTIFICATION_TYPE.VALIDATION_REQUESTED);
  const currentTabRef = useRef(activeTab);
  const LIMIT = 10;
  const loaderRef = useRef(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    itemsPerPage: LIMIT,
    hasNextPage: false,
    hasPreviousPage: false
  });
  const [error, setError] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  const fetchNotifications = async (type, pageNumber = 1) => {
    setIsLoading(true);
    setError(false);
    currentTabRef.current = type;

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();

    try {
      const response = await getNotifications(type, abortControllerRef.current.signal, {
        page: pageNumber,
        limit: LIMIT
      });

      if (currentTabRef.current === type) {
        if (pageNumber === 1) {
          setNotifications(response.data.notifications);
        } else {
          setNotifications((prev) => {
            const merged = { ...prev };
            Object.keys(response.data.notifications).forEach((date) => {
              if (merged[date]) {
                merged[date] = [...merged[date], ...response.data.notifications[date]];
              } else {
                merged[date] = response.data.notifications[date];
              }
            });
            return merged;
          });
        }
        setPagination(response.data.pagination);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        toast.error('Failed to get notifications');
        console.error('Failed to get notifications: ', error);
        if (currentTabRef.current === type) {
          setIsLoading(false);
          setError(true);
        }
      }
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && pagination.hasNextPage && !isLoading) {
          const nextPage = pagination.currentPage + 1;
          setPagination((prev) => ({ ...prev, currentPage: nextPage }));
          fetchNotifications(activeTab, nextPage);
        }
      },
      { threshold: 0.1 }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => observer.disconnect();
  }, [pagination.hasNextPage, isLoading, pagination.currentPage, activeTab]);

  const fetchUnreadCount = async () => {
    try {
      const {
        data: { count }
      } = await getUnreadNotificationsCount();
      setUnreadCount(count);
    } catch (error) {
      console.error('Failed to fetch unread notifications count:', error);
    }
  };

  useEffect(() => {
    // Initial fetch
    fetchUnreadCount();

    // Set up polling interval (every 15 seconds)
    const pollInterval = setInterval(fetchUnreadCount, 15000);

    // Cleanup
    return () => clearInterval(pollInterval);
  }, []);

  const tabItems = [
    {
      key: GENERIC_NOTIFICATION_TYPE.VALIDATION_REQUESTED,
      label: t('notification.tabs.validationRequested'),
      bgColor: 'bg-[#9747FF33]',
      textColor: 'text-[#7A30D9]'
    },
    {
      key: GENERIC_NOTIFICATION_TYPE.PROFILE_UPDATED,
      label: t('notification.tabs.profileUpdated'),
      bgColor: 'bg-[#51C94F33]',
      textColor: 'text-[#3A9E38]'
    },
    {
      key: GENERIC_NOTIFICATION_TYPE.NOTE_ADDED,
      label: t('notification.tabs.noteAdded'),
      bgColor: 'bg-[#FFAD3333]',
      textColor: 'text-[#D98C1A]'
    }
  ];

  const handleNotificationAction = async (notification) => {
    try {
      await removeNotificationForAll({ id: notification._id });

      setNotifications((prevNotifications) => {
        const updatedNotifications = { ...prevNotifications };

        Object.keys(updatedNotifications).forEach((date) => {
          updatedNotifications[date] = updatedNotifications[date].filter(
            (item) => item._id !== notification._id
          );

          if (updatedNotifications[date].length === 0) {
            delete updatedNotifications[date];
          }
        });

        return updatedNotifications;
      });

      navigate(`/${notification.groupId}/profiles/${notification.userId}`);
    } catch (error) {
      toast.error('Failed to remove notification');
      console.error('Failed to remove notification: ', error);
    }
  };

  const handleTabChange = (index) => {
    setActiveTab(tabItems[index].key);
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
    fetchNotifications(tabItems[index].key, 1);
  };

  const handlePopoverClose = () => {
    setActiveTab(GENERIC_NOTIFICATION_TYPE.VALIDATION_REQUESTED);
  };

  return (
    <Popover
      position="bottom center"
      size="normal"
      onOpen={() => {
        setPagination((prev) => ({ ...prev, currentPage: 1 }));
        fetchNotifications(activeTab, 1);
      }}
      onClose={handlePopoverClose}
      button={
        <button
          type="button"
          className={clsx(
            'rounded-full font-semibold text-lg flex items-center justify-center relative'
          )}>
          <NotificationIcon className="cursor-pointer" hasNotification={unreadCount > 0} />
        </button>
      }>
      <div>
        <header className="bg-[#F8F8F8] px-6 py-4 rounded-t-lg">
          <h4
            className="font-semibold mb-4"
            dangerouslySetInnerHTML={{ __html: t('notification.title') }}
          />
          <Tab.Group onChange={handleTabChange}>
            <Tab.List className="flex space-x-1 rounded-xl bg-white p-1 shadow">
              {tabItems.map((item) => (
                <Tab
                  key={item.key}
                  className={({ selected }) =>
                    clsx(
                      'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                      'ring-white ring-opacity-60 ring-offset-2 focus:outline-none focus:ring-2',
                      selected
                        ? `${item.bgColor} ${item.textColor}`
                        : 'text-gray-500 hover:text-gray-700'
                    )
                  }>
                  {item.label}
                </Tab>
              ))}
            </Tab.List>
          </Tab.Group>
        </header>
        <main className="max-h-[450px] overflow-y-auto p-6">
          {isLoading && pagination.currentPage === 1 ? (
            <div className="flex justify-center items-center h-full w-full">
              <Loading loading={true} />
            </div>
          ) : error ? (
            <div className="text-center text-red-500 p-4">
              {t('notification.error.failedToLoad')}
            </div>
          ) : Object.keys(notifications).length === 0 ? (
            <EmptyState />
          ) : (
            <ul>
              <div className="max-w-lg mx-auto">
                {Object.keys(notifications)?.map((notificationDate, index) => {
                  const allNotificationsByDate = notifications[notificationDate];
                  return (
                    <div key={notificationDate}>
                      <p className="font-semibold text-textAndIcons mb-4">
                        {formatDateWithDays(notificationDate)}
                      </p>
                      {allNotificationsByDate.map((notification) => (
                        <NotificationItem
                          key={notification._id}
                          onAction={() => handleNotificationAction(notification)}
                          {...notification}
                        />
                      ))}
                    </div>
                  );
                })}
                {pagination.hasNextPage && (
                  <div ref={loaderRef} className="flex justify-center py-4">
                    <Loading loading={isLoading} />
                  </div>
                )}
              </div>
            </ul>
          )}
        </main>
      </div>
    </Popover>
  );
};

const NotificationItem = ({
  _id: notificationId,
  userId,
  groupId,
  date,
  image,
  name,
  groupName,
  profileType,
  type,
  updates,
  note,
  onAction
}) => {
  const { t } = useTranslation();
  const notificationColor = GENERIC_NOTIFICATION_TYPE_COLOR[type];

  const profileImage = image
    ? `${IMAGE_URL}profile-images/${image}`
    : '/images/default-user-icon.png';

  return (
    <div className="bg-backgroundGrey rounded-xl shadow p-4 mb-4">
      <div className="flex items-start justify-between">
        <div className="flex items-start">
          <div className="flex-shrink-0 relative">
            <img className="h-12 w-12 rounded-full" src={profileImage} alt="" />
            <div
              className="w-3 h-3 rounded-full border border-backgroundGrey absolute top-0 right-0"
              style={{ backgroundColor: notificationColor }}
            />
          </div>
          <div className="ml-4">
            <h3 className="text-base font-semibold leading-6 text-gray-900">{name}</h3>
            <p className="text-sm text-gray-500">{groupName}</p>
            <p className="text-sm text-gray-500">{profileType}</p>
            <div className="mt-4">
              {updates?.length > 0 && (
                <div>
                  <p
                    className="mb-2 text-sm"
                    dangerouslySetInnerHTML={{ __html: t('notification.updatesHeading') }}
                  />
                  {updates.map((update, index) => (
                    <span
                      key={index}
                      className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm text-gray-700 mr-2 mb-2">
                      {t(`notification.details.${update}`)}
                    </span>
                  ))}
                </div>
              )}
              {note && (
                <div className="text-sm">
                  <p
                    className="mb-2"
                    dangerouslySetInnerHTML={{ __html: t('notification.noteHeading') }}
                  />
                  <span className="bg-white rounded px-2 py-1  w-full break-words">{note}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <Button.ButtonFilled onClick={onAction} isMini>
          {t('notification.buttonText')}
        </Button.ButtonFilled>
      </div>
    </div>
  );
};

const EmptyState = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center p-8">
      <div className="relative w-20 h-20 mb-3">
        <svg
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-full h-full">
          <path
            d="M28 8c-8.8 0-14 6.2-14 15v12l-4 4v2h36v-2l-4-4V23c0-8.8-5.2-15-14-15zM28 47c2.2 0 4-1.8 4-4h-8c0 2.2 1.8 4 4 4z"
            fill="#E5E7EB"
          />
          {/* Black circle with white text */}
          <circle cx="40" cy="16" r="7" fill="#111827" />
          <text
            x="40"
            y="16"
            fontSize="9"
            fill="white"
            textAnchor="middle"
            dominantBaseline="middle"
            fontWeight="600">
            0
          </text>
        </svg>
      </div>
      <p className="text-gray-400 text-sm font-medium">{t('notification.empty')}</p>
    </div>
  );
};

export default Notifications;
