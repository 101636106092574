import React from 'react';

import useOrganizationColors from '../../../hooks/useOrganizationColors';

export default function Global() {
  const colors = useOrganizationColors();

  return (
    <footer className={`bg-[${colors?.secondary500}] py-10 md:py-0`}>
      <div className="w-full md:py-10">
        <div className="container mx-auto">
          <div className="mx-10 flex flex-wrap md:flex-row mx-auto justify-between">
            <div className="w-full md:w-2/5"></div>
            <div className="w-full md:w-3/5">
              <p className="text-white">
                <span className="font-bold">Note:</span> Le programme mission future de WorldSkills
                est en phase de test. Ce n&apos;est qu&apos;une fois tout le programme validé que ce
                site sera inclus dans le site officiel de WorldSkills France.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
