const LoadingHorizontalBar = ({ variant = 'full' }) => {
  const isSmall = variant === 'small';

  return (
    <div className={`flex ${isSmall ? 'justify-center' : ''}`}>
      <div
        className={`
            h-2
            rounded-full 
            bg-gradient-to-r 
            from-gray-200 
            via-gray-300 
            to-gray-200 
            animate-pulse
            ${isSmall ? 'w-[12rem]' : 'w-full'}
          `}
      />
    </div>
  );
};

export default LoadingHorizontalBar;
