import clsx from 'clsx';
import React, { forwardRef, useContext } from 'react';
import { ProfileContext } from '../../../contexts/profileContext';
import { Spinner } from '../../common';

const ButtonOutlined = forwardRef(
  (
    {
      onClick,
      className,
      children,
      loading,
      spinnerColor = '#ffffff',
      isMini = false,
      variant = 'primary',
      ...props
    },
    ref
  ) => {
    const [profile] = useContext(ProfileContext);
    const {
      masterOrganization: { customConfigurations: { colors, buttonRadius = '9999px' } = {} } = {}
    } = profile;

    const getColors = () => {
      if (loading) return {};
      switch (variant) {
        case 'danger':
          return {
            borderColor: '#EF4444',
            color: '#EF4444',
            transition: 'all 0.2s ease-in-out'
          };
        default:
          return { borderColor: colors?.primary, color: colors?.primary };
      }
    };

    return (
      <button
        {...props}
        ref={ref}
        className={clsx(
          `font-bold flex items-center justify-center border-2 disabled:opacity-60`,
          variant === 'danger' && [
            'hover:bg-red-500 hover:border-red-500',
            '[&_svg]:hover:text-white',
            'hover:[&]:text-white'
          ],
          className,
          isMini ? 'px-4 py-1 text-md' : 'px-6 py-2 text-lg'
        )}
        style={{
          borderRadius: buttonRadius,
          ...getColors()
        }}
        disabled={loading}
        onClick={onClick}>
        <Spinner isSubmitting={loading} className="mr-2" color={spinnerColor} />
        {children}
      </button>
    );
  }
);

export default ButtonOutlined;
