//Packages
import React, { useContext, useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
//Icon
import { FiMenu } from 'react-icons/fi';
//Custom Components
import Menu from './menu';
import { Flag } from '../../common';
import { Link } from 'react-router-dom';
import { ProfileContext } from '../../../contexts/profileContext';
import useOrganizationColors from '../../../hooks/useOrganizationColors';

export default function HeaderDesktop({
  menuItems,
  menuData,
  className = '',
  hideButtons = false,
  showLoginButton = false,
  smallScreenLogin = true,
  menuOnMobile = false,
  connectionButtonTitle = 'Connexion',
  connectionButtonLink = '/login',
  connectionLinkType = '',
  smallHeader = false
}) {
  const [scrollDirection, setScrollDirection] = useState(null);

  const colors = useOrganizationColors();

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? 'down' : 'up';
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -2)
      ) {
        const header = document.querySelector('.header-section');
        if (direction === 'up' && header.classList.contains('hidden')) {
          header.classList.remove('hidden');
        } else {
          header.classList.add('hidden');
        }
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener('scroll', updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener('scroll', updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector('.header-section');
    const scrollTop = window.scrollY;
    if (!smallHeader)
      scrollTop > 0 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
  };
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      {/* {showMenu && <Menu menuData={menuData} disableMenu={disableMenu} />} */}
      <Menu menuData={menuData} showMenu={showMenu} setShowMenu={setShowMenu} />
      <header
        className={`header-section ${className} mx-1 lg:mx-1 relative bg-[${
          colors?.secondary500
        }] pt-5 ${smallHeader && 'is-sticky'}`}>
        {/* <img src="./images/Header.png" className="object-cover w-full" /> */}
        <div className="outer-container">
          <div className={`container flex ${!hideButtons && 'md:flex-col'} mx-auto`}>
            <div
              className={`menu-icon-container flex flex-row  ${
                hideButtons ? 'items-start' : 'md:w-full justify-between'
              } items-center text-white w-20  lg:w-full px-4 lg:px-0 ${
                !hideButtons && 'lg:pb-10'
              }`}>
              {menuData && (
                <button
                  className={`bg-[${colors.secondary300}] text-2xl rounded-full p-3 text-center`}
                  onClick={() => setShowMenu(true)}>
                  <FiMenu className="mx-auto text-white" />
                </button>
              )}
              <div className="hidden md:inline-block">
                {showLoginButton &&
                  (connectionLinkType === 'outbound' ? (
                    <a href={connectionButtonLink} className="hide-on-sticky">
                      <div
                        className={`bg-white text-sm border-2 border-white rounded-full px-4 py-2 font-bold text-[${colors?.secondary500}]`}>
                        {connectionButtonTitle}
                      </div>
                    </a>
                  ) : (
                    <Link to={connectionButtonLink} className="hide-on-sticky">
                      <div
                        className={`bg-white text-sm border-2 border-white rounded-full px-4 py-2 font-bold text-[${colors?.secondary500}]`}>
                        {connectionButtonTitle}
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
            <div
              className={`menu-container ${
                hideButtons ? 'items-start w-fit' : 'grow mx-auto w-full'
              }   flex flex-row justify-between items-baseline mr-6 md:mr-auto md:px-0 pb-4 border-b-2 border-b-white`}>
              <Link to={'/'}>
                <img
                  src="/images/registeration-logo.png"
                  className="logo"
                  alt="WorldSkills France"
                />
              </Link>
              <div className={`${menuOnMobile ? 'flex' : 'hidden md:flex'} items-center gap-2`}>
                {!hideButtons &&
                  menuItems &&
                  menuItems.map((item, index) => {
                    let content = (
                      <div
                        className={`text-white text-sm border-[1px] border-white rounded-full px-4 py-2 font-semibold ${
                          item.className !== undefined ? item.className : ''
                        }`}>
                        {item.title}
                      </div>
                    );
                    return item.type && item.type === 'samepage' ? (
                      <HashLink key={index} to={item.link} className={item.className}>
                        {content}
                      </HashLink>
                    ) : item.type === 'outbound' ? (
                      <a key={index} href={item.link}>
                        {content}
                      </a>
                    ) : (
                      <Link key={index} to={item.link}>
                        {content}
                      </Link>
                    );
                  })}
              </div>
            </div>
            <div className="block md:hidden mr-2">
              {showLoginButton && smallScreenLogin && (
                <Link to={connectionButtonLink} className="hide-on-sticky">
                  <div
                    className={`bg-white text-sm border-2 border-white rounded-full px-4 py-2 font-bold text-[${colors?.secondary500}]`}>
                    {connectionButtonTitle}
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>

        <Flag />
      </header>
    </>
  );
}
