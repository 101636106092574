import Input from './input';

export default function InputGroup({ label, labelClassName = '', fields, className, ...props }) {
  return (
    <div className={`my-2 ${className}`}>
      {label && <label className={labelClassName}>{label}</label>}
      {fields.map((field, index) => {
        return <Input key={index} className="my-2" {...field} />;
      })}
      <div className="">
        <p className="text-xs">Max. 90 characters</p>
      </div>
    </div>
  );
}
