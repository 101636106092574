import React, { useEffect, useRef, useState } from 'react';

const Tooltip = ({ open, setOpen, title, children }) => {
  const tooltipRef = useRef(null);
  const parentRef = useRef(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [isClickedInside, setIsClickedInside] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (parentRef.current && tooltipRef.current) {
        const parentRect = parentRef.current.getBoundingClientRect();
        const tooltipRect = tooltipRef.current.getBoundingClientRect();

        const top = parentRect.top + window.scrollY;
        const left = window.innerWidth - parentRect.left + window.scrollX;

        setPosition({ top, left });
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [open]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!isClickedInside && tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isClickedInside, setOpen]);

  const handleTooltipClick = () => {
    setIsClickedInside(true);
  };

  return (
    <div
      className={`absolute z-50 bg-white border border-gray-300 p-4 rounded-lg shadow-md ${
        open ? 'block' : 'hidden'
      }`}
      style={{ top: `${position.top}px`, left: `${position.left}px` }}
      ref={tooltipRef}
      onClick={handleTooltipClick}>
      <div className="font-bold mb-2">{title}</div>
      <div className="text-sm">{children}</div>
    </div>
  );
};

export default Tooltip;
