import React from 'react';

export default function Flag({ blueWidth = '30%', whiteWidth = '50%', redWidth = '20%' }) {
  return (
    <div className="flex justify-between">
      <span
        className="bg-cblue-500 h-4 md:h-8"
        style={{
          width: blueWidth
        }}></span>
      <span
        className="bg-white h-4 md:h-8"
        style={{
          width: whiteWidth
        }}></span>
      <span
        className="bg-cred-500 h-4 md:h-8"
        style={{
          width: redWidth
        }}></span>
    </div>
  );
}
