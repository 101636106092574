import React from 'react';
import InputRadio from './inputRadio';

export default function InputGroupRadio({
  label,
  options,
  error,
  touched,
  name,
  className,
  setFieldValue,
  labelClassName,
  value,
  description,
  optionDirection = 'row',
  ...props
}) {
  return (
    <div role="group" className={`my-2 ${className}`}>
      {label && <label className={`${labelClassName}`}>{label}</label>}
      {description && <p>{description}</p>}
      <div
        className={`mt-2 flex gap-6 flex-wrap ${
          optionDirection === 'row' ? 'flex-row items-center' : 'flex-col items-start'
        }`}>
        {options?.length &&
          options?.map((option, i) => (
            <InputRadio
              checked={value?.value === option.value}
              value={value}
              key={i}
              name={name}
              col={optionDirection === 'col'}
              {...option}
              {...props}
              onChange={() => {
                setFieldValue(name, option, true);
              }}
            />
          ))}
      </div>
      {error && touched && <p className="text text-red-500">{error}</p>}
    </div>
  );
}
