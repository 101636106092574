import React from 'react';
import QRCode from 'react-qr-code';

//Simple
export default function PDFTYpe1({ student = false, QRCodeData = null }) {
  return (
    <footer
      className={`footer-pdf text-center p-5 ${student ? 'student flex justify-between' : ''}`}>
      <img className={`footer-logo`} src="/images/registeration-logo.png" />
      {QRCodeData && (
        <div className={`h-full w-auto`}>
          <QRCode
            value={`https://www.mission-future.com/${QRCodeData}`}
            bgColor="#ffffff"
            fgColor={'#163F9B'}
            title="Profile QR Code"
            size={256}
            viewBox={`0 0 256 256`}
            className={'h-full w-auto max-h-[100%]'}
          />
        </div>
      )}
    </footer>
  );
}
