export const IMAGE_URL = process.env.REACT_APP_AWS_URL;

export const optionList = [
  {
    name: 'Download',
    action: 'download',
    icon: 'download'
  },
  {
    name: 'Upload',
    action: 'upload',
    icon: 'upload'
  },
  {
    name: 'Remove',
    action: 'remove',
    icon: 'remove'
  }
];

export const videosOption = [
  {
    id: '1',
    item: {}
  },
  {
    id: '2',
    item: {}
  },
  {
    id: '3',
    item: {}
  },
  {
    id: '4',
    item: {}
  },
  {
    id: '5',
    item: {}
  },
  {
    id: '6',
    item: {}
  },
  {
    id: '7',
    item: {}
  }
];

export const NOTIFICATION_TYPE = {
  PROFILE_UPDATED: 'profile-updated',
  VALIDATION_REQUESTED: 'validation-requested'
};

export const GENERIC_NOTIFICATION_TYPE = {
  PROFILE_UPDATED: 'profile-updated',
  VALIDATION_REQUESTED: 'validation-requested', // first-time
  NOTE_ADDED: 'note-added'
};

export const GENERIC_NOTIFICATION_TYPE_COLOR = {
  'profile-updated': '#51C94F',
  'validation-requested': '#9747FF',
  'note-added': '#FC9E5A',
  'has-notification': '#E54A4A'
};
