export const translations = {
  required: {
    en: 'This field is mandatory.',
    fr: 'Ce champ est obligatoire.',
    de: 'Dieses feld is erforderlich.',
    it: 'Questo campo è obbligatorio.'
  },
  en: {
    genericFields: {
      email: 'Email is required',
      validEmail: 'Enter a valid email address',
      profileType: 'Please choose a Profile Type.',
      competitorProfileType: 'Please choose a Competitor Profile Type.'
    },

    createOrganizationSchema: {
      name: 'Please enter an Organisation name.',
      image: 'Logo is required.'
    },

    subAdminAccountSchema: {
      systemAssignedOrganization: {
        min: 'Please select an Organisation.',
        required: 'Organisation is required.'
      },
      permissions: {
        min: 'Please select a Role.',
        required: 'Role is required.'
      }
    },

    subOrganizationAdminAccountSchema: {
      permissions: {
        min: 'Please select a Role.',
        required: 'Role is required.'
      }
    },

    profileTypeCreateSchema: {
      profiles: 'Please use a valid CSV file.'
    },

    moveProfilesSchema: {
      organization: 'Organisation is required.'
    },

    updateAdminProfileSchema: {
      language: 'Language is required.'
    },

    updateOrganizationAdminProfileSchema: {
      image: 'Organisation logo is required.',
      language: 'Language is required.'
    }
  },
  fr: {
    genericFields: {
      email: 'L’e-mail est requis.',
      validEmail: "L'email doit être un email valide",
      profileType: 'Veuillez choisir un type de profil.',
      competitorProfileType: 'Veuillez choisir un type de profil de competiteur.'
    },

    createOrganizationSchema: {
      name: 'Le nom de l’organisation est requis.',
      image: 'Le logo est requis.'
    },

    subAdminAccountSchema: {
      systemAssignedOrganization: {
        min: 'Veuillez sélectionner une organisation.',
        required: 'L’organisation est requise.'
      },

      permissions: {
        min: 'Veuillez sélectionner un rôle.',
        required: 'Le rôle est requis.'
      }
    },

    subOrganizationAdminAccountSchema: {
      permissions: {
        min: 'Veuillez sélectionner un rôle.',
        required: 'Le rôle est requis.'
      }
    },

    profileTypeCreateSchema: {
      profiles: 'Veuillez utiliser un fichier CSV valide.'
    },

    moveProfilesSchema: {
      organization: 'L’organisation est requise.'
    },

    updateAdminProfileSchema: {
      language: 'La langue est requise.'
    },

    updateOrganizationAdminProfileSchema: {
      image: 'Le logo de l’organisation est requis.',
      language: 'La langue est requise.'
    }
  },
  de: {
    genericFields: {
      email: 'Eine E-Mail ist erforderlich.',
      validEmail: 'Geben sie eine gültige E-Mail-Adresse an',
      profileType: 'Bitte wählen Sie einen Profiltyp aus.',
      competitorProfileType: 'Bitte wählen Sie einen Teilnehmer-Profiltyp aus.'
    },

    createOrganizationSchema: {
      name: 'Bitte geben Sie einen Organisationsnamen ein.',
      image: 'Logo ist erforderlich.'
    },

    subAdminAccountSchema: {
      systemAssignedOrganization: {
        min: 'Bitte wählen Sie eine Organisation aus.',
        required: 'Organisation ist erforderlich.'
      },
      permissions: {
        min: 'Bitte wählen Sie eine Rolle aus. ',
        required: 'Rolle ist erforderlich.'
      }
    },

    subOrganizationAdminAccountSchema: {
      permissions: {
        min: 'Bitte wählen Sie eine Rolle aus. ',
        required: 'Rolle ist erforderlich. '
      }
    },

    profileTypeCreateSchema: {
      profiles: 'Bitte verwenden Sie eine gültige CSV-Datei.'
    },

    moveProfilesSchema: {
      organization: 'Organisation ist erforderlich.'
    },

    updateAdminProfileSchema: {
      language: 'Sprache ist erforderlich.'
    },

    updateOrganizationAdminProfileSchema: {
      image: 'Das Logo der Organisation ist erforderlich.',
      language: 'Sprache ist erforderlich.'
    }
  },
  it: {
    genericFields: {
      email: "Si requiesta un'e-mail.",
      validEmail: 'Inserire un indirizzo email valido',
      profileType: 'Si prega di selezionare un tipo di profilo.',
      competitorProfileType: 'Si prega di selezionare un tipo di profilo di partecipante.'
    },

    createOrganizationSchema: {
      name: "Si prega di inserire il nome dell'organizzazione.",
      image: 'Si requista il logo.'
    },

    subAdminAccountSchema: {
      systemAssignedOrganization: {
        min: "Si prega di selezionare un'organizzazione.",
        required: "Si requista un'organizzazione."
      },
      permissions: {
        min: 'Si prega di selezionare un ruolo.',
        required: 'Si requiesta un ruolo.'
      }
    },

    subOrganizationAdminAccountSchema: {
      permissions: {
        min: 'Si prega di selezionare un ruolo.',
        required: 'Si requiesta un ruolo.'
      }
    },

    profileTypeCreateSchema: {
      profiles: 'Si prega di utilizzare un file CSV valido.'
    },

    moveProfilesSchema: {
      organization: "Si requista un'organizzazione."
    },

    updateAdminProfileSchema: {
      language: 'La lingua è richiesta.'
    },

    updateOrganizationAdminProfileSchema: {
      image: "È richiesto il logo dell'organizzazione.",
      language: 'La lingua è richiesta.'
    }
  }
};
