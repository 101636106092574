import PropTypes from 'prop-types';

const LoadingInButton = ({ loading = false, size = 'medium', position = 'left' }) => {
  if (!loading) return null;

  const sizeClasses = {
    small: 'w-4 h-4 border-2',
    medium: 'w-8 h-8 border-3',
    large: 'w-12 h-12 border-4'
  };

  const marginClass = position === 'none' ? '' : position === 'left' ? 'mr-2' : 'ml-2';

  return (
    <div role="status" className={`flex items-center justify-center ${marginClass}`}>
      <div
        className={`animate-spin ${sizeClasses[size]} border-gray-300 border-t-gray-600 rounded-full`}
      />
      <span className="sr-only">Loading...</span>
    </div>
  );
};

LoadingInButton.propTypes = {
  loading: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  position: PropTypes.oneOf(['left', 'right', 'none'])
};

export default LoadingInButton;
