import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import { addBulkProfileData, createGroupProfiles, getBulkProfileUploadData } from '../service';
import { CSVInput, Popover, RadioSelect, TextInput } from './common';
import Button from './common/button';
import { CrossIcon, PlusIcon } from './icons';
import { addProfilesSchema } from '../utils/schema';

const ProfileEmailsCsvUrl = process.env.REACT_APP_AWS_URL + 'ProfileEmails.csv';

const AddProfiles = ({ colors = {}, groupId, onSubmit = () => {} }) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      type: 'single-profile',
      emailAddress: '',
      profiles: [],
      bulkProfiles: {},
      file: null
    },
    validationSchema: addProfilesSchema,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      if (!groupId) {
        toast.error('Group info is not valid to perform this action.');
        return;
      }

      let profiles = [];

      const creationType = formValues.type;

      if (creationType === 'single-profile') {
        profiles = [formValues.emailAddress];
      } else if (creationType === 'multiple-profiles') {
        profiles = formValues.profiles;
      } else if (creationType === 'bulk-profiles') {
        profiles = formValues.bulkProfiles;
      }

      if (Array.isArray(profiles) ? profiles.length === 0 : Object.keys(profiles).length === 0) {
        toast.error('Profile info is not valid to perform this action.');
        return;
      }

      try {
        if (creationType === 'bulk-profiles') {
          const formData = new FormData();
          formData.append('groupId', groupId);
          formData.append('creationType', creationType);
          formData.append('profiles', JSON.stringify(profiles));
          formData.append('file', formValues.file);

          await addBulkProfileData(formData);
        } else {
          await createGroupProfiles(groupId, profiles);
        }
        onSubmit();
        toast.success('Profile/s added successfully.');
        formik.resetForm();
      } catch (error) {
        toast.error('Failed to add group profile/s.');
        console.error('Failed to add group profile/s: ', error);
      }
    }
  });

  const handleCsvUpload = (csvData) => {
    if (csvData['EMAIL ADDRESS']) {
      const profiles = csvData['EMAIL ADDRESS'];

      // TODO: need to implement a better regex for this
      // const areAllEmailsValid = profiles.every(isValidEmail);
      // if (!areAllEmailsValid) {
      //   toast.error('One or more email addresses are not correct');
      //   return;
      // }

      formik.setFieldValue('profiles', profiles);
    }
  };

  const downloadBulkExcelSheet = async () => {
    const { data: excelSheetData } = await getBulkProfileUploadData(groupId);

    const wb = XLSX.utils.book_new();

    // Add the "Profiles" sheet
    const profilesWorksheet = XLSX.utils.json_to_sheet([], { header: excelSheetData.headers });
    XLSX.utils.book_append_sheet(wb, profilesWorksheet, 'Profiles');

    // Add other sheets based on other keys in excelSheetData
    for (let key in excelSheetData) {
      if (key !== 'headers') {
        const dataForSheet = excelSheetData[key].map((value) => ({ [key]: value }));
        const worksheet = XLSX.utils.json_to_sheet(dataForSheet);
        XLSX.utils.book_append_sheet(wb, worksheet, key);
      }
    }

    // Write the workbook to a file
    XLSX.writeFile(wb, 'Bulk-Profiles-Upload.xlsx');
  };

  const handleBulkXLSXUpload = async (csvData, file) => {
    // validate input data here
    const requiredHeaderValues = ['email'];
    let isValid = true;
    const errorMessages = [];
    requiredHeaderValues.forEach((requiredHeader) => {
      if (
        !csvData[requiredHeader] ||
        csvData[requiredHeader].length === 0 ||
        csvData[requiredHeader].some((value) => !value)
      ) {
        isValid = false;
        errorMessages.push(`The "${requiredHeader}" value is missing for some profiles.`);
      }
    });

    if (!isValid) {
      toast.error(errorMessages.join(' ---- '));
      return;
    }
    formik.setFieldValue('bulkProfiles', csvData, true);
    formik.setFieldValue('file', file, true);
  };

  const PopoverHeader = ({ closePopup }) => {
    return (
      <header className="bg-[#F8F8F8] px-6 py-4 rounded-t-lg flex items-center justify-between">
        <h4 className="font-semibold">{t('addProfile.title')}</h4>
        <CrossIcon onClick={closePopup} />
      </header>
    );
  };

  return (
    <>
      <Popover
        position="bottom right"
        size="md"
        button={
          <Button.ButtonOutlined type="button" data-testid="add-new-profiles-button">
            <PlusIcon color={colors?.primary} /> {t('addProfile.button')}
          </Button.ButtonOutlined>
        }>
        <PopoverHeader />
        <main className="max-h-[450px] overflow-y-auto p-6">
          <form onSubmit={formik.handleSubmit}>
            <RadioSelect
              label={t('addProfile.type.title')}
              className="my-1"
              name="type"
              value={formik.values.type}
              touched={formik.touched.type}
              onBlur={formik.handleBlur}
              error={formik.errors.type}
              options={[
                {
                  id: 'single-profile',
                  label: t('addProfile.type.single'),
                  value: 'single-profile'
                },
                {
                  id: 'multiple-profiles',
                  label: t('addProfile.type.multiple'),
                  value: 'multiple-profiles'
                },
                { id: 'bulk-profiles', label: t('addProfile.type.bulk'), value: 'bulk-profiles' }
              ]}
              onChange={(option) => {
                formik.setFieldValue('type', option, true);
              }}
            />
            {formik.values.type === 'single-profile' && (
              <TextInput
                name="emailAddress"
                label={t('addProfile.emailAddress')}
                value={formik.values.emailAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.emailAddress}
              />
            )}
            {formik.values.type === 'multiple-profiles' && (
              <div className="mb-8">
                <a
                  className="text-md text-cblack-300 my-1 mb-5 flex items-center gap-2 hover:underline group"
                  href={ProfileEmailsCsvUrl}
                  download="ProfileEmails.csv">
                  {t('addProfile.downloadSample')}
                  <div className="border rounded py-0.5 px-2 group-hover:border-gray-400">
                    <ArrowDownTrayIcon className="w-4 h-4 text-gray-800" />
                  </div>
                </a>

                <CSVInput
                  onFileProcessed={handleCsvUpload}
                  headers={['EMAIL ADDRESS']}
                  withoutHeader={true}
                />
                {formik.errors.profiles && (
                  <p className="text-sm text-red-600">{formik.errors.profiles}</p>
                )}
              </div>
            )}
            {formik.values.type === 'bulk-profiles' && (
              <div className="mb-8">
                <button
                  className="text-md text-cblack-300 my-1 mb-5 flex items-center gap-2 hover:underline group"
                  onClick={downloadBulkExcelSheet}
                  type="button">
                  {t('addProfile.downloadBulkSample')}
                  <div className="border rounded py-0.5 px-2 group-hover:border-gray-400">
                    <ArrowDownTrayIcon className="w-4 h-4 text-gray-800" />
                  </div>
                </button>

                <CSVInput
                  fileType=".xlsx"
                  sheetName="Profiles"
                  onFileProcessed={handleBulkXLSXUpload}
                />
                {formik.errors.bulkProfiles && (
                  <p className="text-sm text-red-600">{formik.errors.bulkProfiles}</p>
                )}
              </div>
            )}

            <footer className="flex items-center justify-end mt-4">
              <Button.ButtonFilled
                type="submit"
                loading={formik.isSubmitting}
                disabled={!formik.isValid}
                data-testid="save-new-profiles-button">
                {t('addProfile.submit')}
              </Button.ButtonFilled>
            </footer>
          </form>
        </main>
      </Popover>
    </>
  );
};

export default AddProfiles;
