import { useTranslation } from 'react-i18next';
import { Spinner, TextInput } from './common';
import { useFormik } from 'formik';
import { refuseGroupProfilesSchema } from '../utils/schema';
import { refuseGroupProfiles } from '../service';
import { toast } from 'react-toastify';
import Button from './common/button';

export default function RefuseProfile({ groupId, profileId, onSubmit }) {
  const { t, i18n } = useTranslation();

  const formik = useFormik({
    initialValues: { notes: '' },
    validationSchema: refuseGroupProfilesSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        if (!profileId) throw new Error('Profile not selected');

        const selectedProfileId = profileId;
        await refuseGroupProfiles(groupId, {
          profileIds: [selectedProfileId],
          notes: values.notes,
          emailLanguage: i18n.language
        });
        toast.success('Profile refused succesfully.');
        onSubmit();
        formik.resetForm();
      } catch (error) {
        console.error('Failed to refuse profile.');
        toast.error('Failed to refuse profile.');
      }
    }
  });
  return (
    <div>
      <p className="font-semibold mb-1">{t('refuseProfile.title')}</p>
      <p className="text-sm">{t('refuseProfile.description')}</p>
      <form className="relative z-0 w-full group rounded-lg my-4" onSubmit={formik.handleSubmit}>
        <TextInput
          name="notes"
          label={t('refuseProfile.refuseReason')}
          value={formik.values.notes}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.notes}
        />
        <footer className="flex items-center justify-end">
          <Button.ButtonFilled
            loading={formik.isSubmitting}
            type="submit"
            className="inline-flex justify-center rounded-full bg-black px-6 py-2 text-sm font-semibold text-white shadow-sm mt-6">
            {t('refuseProfile.submit')}
          </Button.ButtonFilled>
        </footer>
      </form>
    </div>
  );
}
