import React from 'react';

export default function HeaderPdf2({
  title,
  titleEnd = '',
  subtitle = '',
  showLogo = true,
  headerReverse = false
}) {
  return (
    <header className="header header-2 flex">
      <div className="bg-cblue-500 flex flex-col items-left justify-center header-container">
        {!headerReverse && (
          <h1 className="header-text">
            <span className="font-bold">{title}</span>{' '}
            <span className="font-light">{titleEnd}</span>
          </h1>
        )}
        {headerReverse && (
          <h1 className="header-text">
            <span className="font-light">{title}</span>{' '}
            <span className="font-bold">{titleEnd}</span>
          </h1>
        )}
        <p className="subheading">{subtitle}</p>
      </div>
      <div className="header-white-container"></div>
      <div className="header-red-container">
        {showLogo && <img src="/images/registeration-logo.png" className="w-full mx-auto" />}
      </div>
    </header>
  );
}
