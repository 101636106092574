import React from 'react';

export default function HeaderWithTitle({ title = '' }) {
  return (
    <section className="header">
      <div className="flex gap-4 items-center">
        <h1 className="heading">{title}</h1>
      </div>
    </section>
  );
}
