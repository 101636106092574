import clsx from 'clsx';
import { useCombobox } from 'downshift';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { globalSearch } from '../../service';

export default function Search() {
  const [items, setItems] = useState([]);
  const navigate = useNavigate();

  const handleSelectedItemChange = ({ selectedItem }) => {
    if (selectedItem) {
      const navigateURL =
        selectedItem.type === 'profile'
          ? `/${selectedItem.groupId}/profiles/${selectedItem._id}`
          : `/dashboard/${selectedItem._id}`;

      navigate(navigateURL);
    }
    reset();
  };

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
    reset
  } = useCombobox({
    onInputValueChange: async ({ inputValue }) => {
      if (inputValue.length >= 3) {
        const { data: items } = await globalSearch(inputValue);
        setItems(items);
      }
    },
    items,
    itemToString(item) {
      return item ? item.title : '';
    },
    onSelectedItemChange: handleSelectedItemChange
  });

  return (
    <div>
      <div className={'flex flex-1 ml-4'}>
        <div className={' w-80'}>
          {/* <span className={'absolute pl-3 inset-y-0 left-0 flex items-center'}>
                <svg className={'h-6 w-6 text-gray-600'} viewBox="0 0 24 24" fill="none">
                  <path
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </span> */}
          <input
            className={
              'block w-full rounded-xl border-none pl-10 pr-4 py-2 text-gray-900 text-md placeholder-gray-600 bg-[#f6f6f6] h-14'
            }
            placeholder="Search"
            {...getInputProps()}
          />
        </div>
      </div>

      <ul
        className={`absolute w-72 bg-white mt-1 shadow-md max-h-80 overflow-scroll p-0 z-10 ${
          !(isOpen && items.length) && 'hidden'
        }`}
        {...getMenuProps()}>
        {isOpen &&
          items.map((item, index) => (
            <li
              className={clsx(
                highlightedIndex === index && 'bg-blue-300',
                selectedItem === item && 'font-bold',
                'py-2 px-3 shadow-sm flex flex-col '
              )}
              key={item.id}
              {...getItemProps({ item, index })}>
              <span>{item.name}</span>
              {item.email && <span className="text-sm text-gray-700">{item.email}</span>}
              <span
                className={clsx('text-xs text-gray-700 uppercase rounded inline-block p-1', {
                  'bg-teal-200': item.type === 'profile',
                  'bg-pink-200': item.type === 'group'
                })}>
                {item.type}
              </span>
            </li>
          ))}
      </ul>
    </div>
  );
}
