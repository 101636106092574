import React from 'react';
import Global from './global';
import PDFType1 from './pdfType1';
import PDFType2 from './pdfType2';
import PDFType3 from './pdfType3';

export default function Footer({ type = '', student = false, QRCodeData = null }) {
  switch (type) {
    case 'pdf1':
      return <PDFType1 student={student} QRCodeData={QRCodeData} />;
    case 'pdf2':
      return <PDFType2 />;
    case 'pdf3':
      return <PDFType3 />;
    default:
      return <Global />;
  }
}
