import React from 'react';

export default function HeaderPdf1({ title, logo, logoClass = '', ...props }) {
  return (
    <header className="header flex header-1">
      <h1 className="header-text text-white font-bold">{title}</h1>

      <img src={logo} className={`${logoClass}`} />
    </header>
  );
}
