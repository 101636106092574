import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTranslation } from '../utils';
import { Popover } from './common';
import Button from './common/button';
import { RadioIcon, VisibilityIcon } from './icons';

const ColumnVisibility = ({
  translationPrefix = 'en',
  colors = {},
  list = [],
  onApply = () => {}
}) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState([...list]);

  const handleOptionClick = (optionIndex) => {
    const updatedOptions = JSON.parse(JSON.stringify(options));
    updatedOptions[optionIndex].isSelected = !updatedOptions[optionIndex].isSelected;
    setOptions(updatedOptions);
  };

  const PopoverHeader = ({ closePopup }) => {
    return (
      <header className="bg-[#F8F8F8] px-6 py-4 rounded-t-lg flex items-center justify-between">
        <h4 className="font-semibold">{t('columnFilter.title')}</h4>
        <Button.ButtonFilled
          onClick={() => {
            onApply(options);
            closePopup();
          }}>
          {t('columnFilter.submit')}
        </Button.ButtonFilled>
      </header>
    );
  };

  return (
    <>
      <Popover
        position="bottom left"
        size="md"
        onOpen={() => {
          setOptions(list);
        }}
        button={
          <Button.ButtonOutlined type="button">
            <VisibilityIcon color={colors?.primary} />
          </Button.ButtonOutlined>
        }>
        <PopoverHeader />
        <main className="max-h-[450px] overflow-y-auto p-6">
          <ul>
            {options.map((option, optionIndex) => (
              <li key={option.key}>
                <button
                  type="button"
                  onClick={() => {
                    handleOptionClick(optionIndex);
                  }}
                  className="w-full py-1.5 flex gap-4 rounded text-left hover:bg-gray-100 ">
                  <div className="w-4">
                    <RadioIcon
                      className="w-4 ml-2"
                      isChecked={option.isSelected}
                      color={colors?.primary}
                      checkColor="#fff"
                    />
                  </div>
                  <span className="flex-grow">
                    {getTranslation(t, `form.fields.${option.key}`, translationPrefix)?.label}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </main>
      </Popover>
    </>
  );
};

export default ColumnVisibility;
