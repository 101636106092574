import { useContext } from 'react';
import Select from 'react-select';
import { ProfileContext } from '../../contexts/profileContext';
import { hexToRgba } from '../../utils/utils';

export default function LanguageDropdown({
  themeId,
  className,
  label,
  error,
  touched,
  options,
  ...props
}) {
  const [profile] = useContext(ProfileContext);
  const {
    masterOrganization: { customConfigurations: { colors, buttonRadius = '9999px' } = {} } = {}
  } = profile;

  return (
    <div className="signupinwrap">
      {label && (
        <label className="block mb-1 text-base font-bold text-cblack-primary">{label}</label>
      )}
      <Select
        styles={{
          control: (base) => ({
            ...base,
            borderRadius: buttonRadius
          }),
          indicatorSeparator: (provided, state) => ({
            ...provided,
            display: 'none' // Remove the line between icon and label
          })
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: hexToRgba(colors?.secondaryVariant, colors?.intensitySecondary),
            primary: colors?.primary
          }
        })}
        className={`mb-4 rounded-full ${className}`}
        options={options}
        {...props}
      />
      {error && touched && <p className="text text-red-500">{error}</p>}
    </div>
  );
}
