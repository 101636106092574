import clsx from 'clsx';
import cloneDeep from 'lodash/cloneDeep';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Dialog,
  DropDown,
  EditableField,
  EmailProfiles,
  GroupCard,
  GroupDetailsView,
  GroupTeamMembers,
  Header,
  NewGroup,
  NewGroupColumn
} from '../components';
import Button from '../components/common/button';
import {
  ArchiveIcon,
  CancelIcon,
  ExportIcon,
  MeatballsMenu,
  PlusButtonIcon,
  PlusIcon,
  SortIcon
} from '../components/icons';
import { ProfileContext } from '../contexts/profileContext';
import { permissions } from '../data/permissions';
import useOrganizationColors from '../hooks/useOrganizationColors';
import {
  deleteGroupColumn,
  disableGroupColumn,
  getGroups,
  getProfileDataForExport,
  updateGroupColumn
} from '../service';
import { getSortedColumns } from '../utils';
import { exportData } from '../utils/utils';

export default function Dashboard() {
  const { t, i18n } = useTranslation();
  const { groupId } = useParams();
  const [profile] = useContext(ProfileContext);
  const colors = useOrganizationColors();

  const profilePermissionIds = profile.permissions?.map((permission) => permission._id);

  const canManageGroups =
    profile.role === 'systemAdmin' ||
    (profile.role === 'systemSubAdmin' &&
      profilePermissionIds.includes(permissions.CREATE_ORGANIZATION));

  const canManageProfiles =
    profile.role === 'systemAdmin' ||
    (profile.role === 'systemSubAdmin' &&
      profilePermissionIds
        .map((permission) => permission)
        .includes(permissions.CREATE_ORGANIZATION)) ||
    profile.role === 'organizationAdmin' ||
    (profile.role === 'organizationSubAdmin' &&
      profilePermissionIds.includes(permissions.MANAGE_ORGANIZATION_ADMINS));

  // console.log('permissions.MANAGE_ORGANIZATION_ADMINS', permissions.MANAGE_ORGANIZATION_ADMINS);
  // console.log('profilePermissionIds', profilePermissionIds);

  // console.log({ canManageProfiles });

  const [groups, setGroups] = useState([]);
  const [showAddNewGroup, setShowAddNewGroup] = useState(false);
  const [showAddNewGroupColumn, setShowAddNewGroupColumn] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({ isOpen: false });
  const [selectedGroupColumn, setSelectedGroupColumn] = useState(null);

  const [sortOptions, setSortOptions] = useState([
    {
      id: 'alphabetically',
      name: t('groupColumn.sort.alphabetically'),
      isSelected: true
    },
    {
      id: 'most-recent',
      name: t('groupColumn.sort.recent')
    },
    {
      id: 'oldest',
      name: t('groupColumn.sort.oldest')
    }
  ]);

  const getAllGroups = async () => {
    try {
      const { data: groupsList } = await getGroups();

      setGroups(groupsList);
    } catch (e) {
      toast.error('Failed to get groups.');
      console.error('error:', e);
    }
  };

  useEffect(() => {
    getAllGroups();
  }, [groupId]);

  useEffect(() => {
    if (groups?.length && groupId) {
      const searchedGroup = groups
        .flatMap((group) => group.groups)
        .find((subGroup) => subGroup._id === groupId);

      if (searchedGroup) {
        setSelectedGroup({ ...searchedGroup, screen: 'main', isOpen: true });
      }
    }
  }, [groups]);

  const handleGroupClick = (group) => {
    const totalProfiles = group.notStarted + group.inProgress + group.validated;
    setSelectedGroup({ ...group, totalProfiles, screen: 'main', isOpen: true });
  };

  const handleGroupViewClose = () => {
    setSelectedGroup({ ...selectedGroup, isOpen: false });
  };

  const handleAddNewGroupClick = (groupColumn) => {
    setShowAddNewGroup(true);
    setSelectedGroupColumn(groupColumn);
  };

  const sortGroups = (groupColumnId, selectedOption) => {
    const updatedGroups = cloneDeep(groups);
    const groupObj = updatedGroups.find((group) => group._id === groupColumnId);

    if (!groupObj) {
      console.error('Group not found');
      return;
    }

    switch (selectedOption.id) {
      case 'alphabetically':
        groupObj.groups.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'most-recent':
        groupObj.groups.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        break;
      case 'oldest':
        groupObj.groups.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        break;
      default:
        console.error('Invalid sort option');
    }
    setGroups(updatedGroups);

    const updatedSortOptions = cloneDeep(sortOptions);
    updatedSortOptions.forEach((sortOption) => (sortOption.isSelected = false));
    updatedSortOptions.find((sortOption) => sortOption.id === selectedOption.id).isSelected = true;

    setSortOptions(updatedSortOptions);
  };

  const handleGroupColumnNameUpdate = ({ id, fieldText }) => {
    setGroups((groups) =>
      groups.map((groupColumnItem) =>
        groupColumnItem._id === id ? { ...groupColumnItem, name: fieldText } : groupColumnItem
      )
    );
  };

  const handleGroupNameUpdate = ({ id, fieldText }) => {
    setSelectedGroup({ ...selectedGroup, name: fieldText });

    setGroups((groups) =>
      groups.map((groupColumnItem) => ({
        ...groupColumnItem,
        groups: groupColumnItem.groups.map((group) =>
          group._id === id ? { ...group, name: fieldText } : group
        )
      }))
    );
  };

  return (
    <>
      <div className="App">
        <div className={'h-screen flex'}>
          <div className={'flex-1 min-w-0 bg-white flex flex-col'}>
            <Header />

            <div className={'flex-1 h-[90vh] overflow-y-hidden'}>
              <main className={'px-4 py-12 inline-flex gap-4 h-full'}>
                {getSortedColumns(groups)?.map((groupColumnItem) => {
                  let groupColumnOptions = [];

                  if (canManageGroups && groupColumnItem.groups?.length === 0) {
                    groupColumnOptions.push({
                      id: 'delete',
                      name: 'Delete',
                      icon: CancelIcon
                    });
                  }

                  const hasGroups = groupColumnItem.groups?.length > 0;
                  if (hasGroups && canManageGroups) {
                    groupColumnOptions.unshift({
                      id: 'disable',
                      name: 'Disable & Store',
                      icon: ArchiveIcon
                    });
                    if (groupColumnItem._id !== 'not-bundled') {
                      groupColumnOptions.unshift({
                        id: 'export-data',
                        name: 'Export Data',
                        icon: ExportIcon
                      });
                    }
                  }
                  return (
                    <div
                      key={groupColumnItem._id}
                      className={
                        'p-3 w-[300px] flex-shrink-0 bg-white rounded transition-all h-full'
                      }>
                      <div className="flex justify-between relative">
                        <EditableField
                          isEditable={groupColumnItem._id !== 'not-bundled' && canManageGroups}
                          label={t('groupColumn.columnNameField')}
                          text={groupColumnItem.name}
                          id={groupColumnItem._id}
                          submitFunction={updateGroupColumn}
                          onUpdate={handleGroupColumnNameUpdate}
                          DisplayComponent="h3"
                        />
                        {canManageGroups && (
                          <DropDown
                            type="button"
                            options={groupColumnOptions}
                            onItemSelect={async (selectedOption) => {
                              if (selectedOption.id === 'delete') {
                                try {
                                  await deleteGroupColumn(groupColumnItem._id);
                                  toast.success('Group column deleted successfully.');
                                  getAllGroups();
                                } catch (error) {
                                  console.error('Failed to delete the group column.');
                                  toast.error('Failed to delete the group column:', error);
                                }
                              } else if (selectedOption.id === 'disable') {
                                try {
                                  await disableGroupColumn(groupColumnItem._id);
                                  toast.success('Group column disabled successfully.');
                                  getAllGroups();
                                } catch (error) {
                                  console.error('Failed to disable the group column.');
                                  toast.error('Failed to disable the group column:', error);
                                }
                              } else if (selectedOption.id === 'export-data') {
                                try {
                                  const { data: exportList } = await getProfileDataForExport(
                                    'groupColumnId',
                                    groupColumnItem._id,
                                    i18n.language
                                  );
                                  exportData(
                                    [{ key: 'email', name: 'Email' }, ...exportList.headers],
                                    exportList.profiles,
                                    groupColumnItem.name,
                                    t,
                                    i18n.language
                                  );
                                } catch (error) {
                                  console.error('Failed to export data.');
                                  toast.error('Failed to export data:', error);
                                }
                              }
                            }}>
                            <MeatballsMenu className="cursor-pointer rounded hover:bg-orange-100" />
                          </DropDown>
                        )}
                      </div>
                      <div className={'mt-4 ring-1 ring-inset ring-black rounded-md p-4 h-[98%]'}>
                        <div
                          className={clsx('flex items-center ', {
                            'justify-between':
                              groupColumnItem._id !== 'not-bundled' && canManageGroups,
                            'justify-end': groupColumnItem._id === 'not-bundled' || !canManageGroups
                          })}>
                          {groupColumnItem._id !== 'not-bundled' && canManageGroups && (
                            <PlusButtonIcon
                              onClick={() => handleAddNewGroupClick(groupColumnItem)}
                              className="cursor-pointer"
                            />
                          )}
                          <DropDown
                            colors={colors}
                            options={sortOptions}
                            onItemSelect={(selectedOption) =>
                              sortGroups(groupColumnItem._id, selectedOption)
                            }>
                            {groupColumnItem.groups?.length > 0 && (
                              <SortIcon className="cursor-pointer" />
                            )}
                          </DropDown>
                        </div>
                        <ul className="overflow-y-auto h-[98%]">
                          {groupColumnItem.groups.map((groupItem) => (
                            <GroupCard
                              key={groupItem._id}
                              groupItem={groupItem}
                              onClick={handleGroupClick}
                            />
                          ))}
                        </ul>
                      </div>
                    </div>
                  );
                })}
                {canManageGroups && (
                  <Button.ButtonPlatform
                    type="button"
                    className="mt-16"
                    onClick={() => setShowAddNewGroupColumn(true)}>
                    <PlusIcon className="mb-1" /> {t('groupColumn.addNewButton')}
                  </Button.ButtonPlatform>
                )}
              </main>
            </div>
          </div>
        </div>
      </div>
      {/* ADD NEW GROUP */}
      <NewGroup
        groupColumn={selectedGroupColumn}
        showAddNewGroup={showAddNewGroup}
        setShowAddNewGroup={setShowAddNewGroup}
        onUpdate={() => getAllGroups()}
      />
      {/* ADD NEW GROUP COLUMN */}
      <NewGroupColumn
        showAddNewGroupColumn={showAddNewGroupColumn}
        setShowAddNewGroupColumn={setShowAddNewGroupColumn}
        onUpdate={() => getAllGroups()}
      />
      {/* SHOW GROUP DETAILS */}
      <Dialog
        size="3xl"
        show={selectedGroup?.isOpen}
        header={selectedGroup?.screen !== 'main' ? selectedGroup?.name : null}
        onBackButtonClick={
          selectedGroup?.screen === 'main'
            ? null
            : () => setSelectedGroup({ ...selectedGroup, screen: 'main' })
        }
        onClose={handleGroupViewClose}>
        {selectedGroup?.screen === 'main' && (
          <GroupDetailsView
            group={selectedGroup}
            canManageGroups={canManageGroups}
            canManageProfiles={canManageProfiles}
            onUpdate={(updates) => {
              setSelectedGroup({ ...selectedGroup, ...updates });
              getAllGroups();
            }}
            onNameUpdate={handleGroupNameUpdate}
          />
        )}
        {selectedGroup?.screen === 'email-profiles' && (
          <EmailProfiles groupId={selectedGroup?._id} />
        )}
        {selectedGroup?.screen === 'team-members' && (
          <GroupTeamMembers groupId={selectedGroup?._id} />
        )}
      </Dialog>
    </>
  );
}
