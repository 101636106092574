import { useContext, useEffect, useRef, useState } from 'react';
import {
  AddProfiles,
  Badge,
  ColumnVisibility,
  Dialog,
  EditableField,
  EmailProfiles,
  Filters,
  GroupDetailsView,
  GroupTeamMembers,
  Header,
  ItemCompletionIndicator,
  Loading,
  MoveProfile,
  Notes,
  Popover,
  ProgressBar,
  RefuseProfile,
  TableBulkActions
} from '../components';
import StrengthsAndRoles from '../components/strengthsAndRoles';

import { CheckBadgeIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../components/common/button';
import {
  ArchiveIcon,
  BackButtonIcon,
  ExportIcon,
  RadioIcon,
  RightArrowIcon,
  SimpleCrossIcon,
  ViewIcon
} from '../components/icons';
import ProfileImage from '../components/profileImage';
import ProfileInformation from '../components/profileInformation';
import Videos from '../components/videos';
import { ProfileContext } from '../contexts/profileContext';
import {
  approveGroupProfiles,
  deleteGroupProfiles,
  disableGroupProfiles,
  getAllGroupProfiles,
  getCompleteGroupDetails,
  getFunction,
  getProfessionalAssociation,
  getProfessions,
  getProfileDetails,
  getRegions,
  getSpecialJobTitles,
  getVocationalSchool,
  removeNotification,
  updateProfileEmail
} from '../service';
import { calculatePercentage, formatDate, getTranslation } from '../utils';
import { exportData, getProfileUrl } from '../utils/utils';
import LoadingInButton from '../components/common/loadingInButton';

const columnHelper = createColumnHelper();
const clickableColumnKey = 'name';

export default function Profiles() {
  const [adminProfile] = useContext(ProfileContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const showGroupDetailsView = searchParams.get('view') === 'groupDetails';
  const showGroupDetailsEmailProfilesView =
    searchParams.get('groupDetailsView') === 'emailProfiles';
  const showGroupDetailsTeamMembersView = searchParams.get('groupDetailsView') === 'teamMembers';

  const profilePermissionIds = adminProfile.permissions?.map((permission) => permission._id);
  const { masterOrganization: { translationPrefix, customConfigurations: { colors } = {} } = {} } =
    adminProfile;

  const { groupId, profileId } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [dbData, setDBData] = useState(() => []);
  const [data, setData] = useState(() => []);
  const [areProfilesLoading, setAreProfilesLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(() => []);
  const [headers, setHeaders] = useState(() => []);
  const [columns, setColumns] = useState(() => []);
  const [originalColumns, setOriginalColumns] = useState(() => []);
  const [sorting, setSorting] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState({ screen: 'main' });
  const [rowSelection, setRowSelection] = useState({});
  const [showSelection, setShowSelection] = useState(false);
  const [groupDetails, setGroupDetails] = useState({});
  const [filters, setFilters] = useState([
    // { selectedField: null, selectedValue: null }
  ]);

  const [isExporting, setIsExporting] = useState(false);

  const containerRef = useRef(null);

  const [isApproving, setIsApproving] = useState(false);

  const fetchGroupDetails = async () => {
    try {
      const { data } = await getCompleteGroupDetails(groupId);
      setGroupDetails(data);
    } catch (error) {
      toast.error('Failed to get group details');
      console.error('Failed to get group details:', error);
    }
  };

  useEffect(() => {
    if (adminProfile?.loaded) {
      fetchGroupDetails();
      getGroupProfiles();
    }
  }, [groupId, adminProfile]);

  useEffect(() => {
    if (data?.length && profileId) {
      const searchedProfile = data.find((profile) => profile._id === profileId);
      if (searchedProfile) {
        setSelectedProfile({ ...searchedProfile, screen: 'main', isOpen: true });
      }
    }
  }, [data, profileId]);

  const saveProfileImageRef = useRef();
  const saveProfileInfoRef = useRef();
  const saveStrengthsAndRolesRef = useRef();

  const triggerProfileImageSave = () => {
    if (saveProfileImageRef.current) {
      saveProfileImageRef.current.saveImage();
    }
  };

  const triggerProfileInfoSave = () => {
    if (saveProfileInfoRef.current) {
      saveProfileInfoRef.current.save();
    }
  };

  const triggerStrengthsAndRolesSave = () => {
    if (saveStrengthsAndRolesRef.current) {
      saveStrengthsAndRolesRef.current.save();
    }
  };

  const handleSingleProfileDelete = async () => {
    try {
      await deleteGroupProfiles(groupId, [selectedProfile?._id], [selectedProfile?.email]);
      toast.success('Profile deleted successfully');

      // Remove the profile from all data states
      const updatedData = data.filter((profile) => profile._id !== selectedProfile._id);
      const updatedFilteredData = filteredData.filter(
        (profile) => profile._id !== selectedProfile._id
      );
      const updatedDBData = dbData.filter((profile) => profile._id !== selectedProfile._id);

      setData(updatedData);
      setFilteredData(updatedFilteredData);
      setDBData(updatedDBData);

      // Close the profile dialog
      setSelectedProfile({ screen: 'main' });
    } catch (error) {
      console.error('Failed to delete profile:', error);
      toast.error('Failed to delete profile.');
    }
  };

  const handleSingleProfileDisable = async () => {
    try {
      await disableGroupProfiles(groupId, [selectedProfile?._id], [selectedProfile?.email]);
      toast.success('Profile disbled succesfully');

      // Remove the profile from all data states
      const updatedData = data.filter((profile) => profile._id !== selectedProfile._id);
      const updatedFilteredData = filteredData.filter(
        (profile) => profile._id !== selectedProfile._id
      );
      const updatedDBData = dbData.filter((profile) => profile._id !== selectedProfile._id);

      setData(updatedData);
      setFilteredData(updatedFilteredData);
      setDBData(updatedDBData);

      // Close the profile dialog
      setSelectedProfile({ screen: 'main' });
    } catch (error) {
      console.error('Failed to disable profile:', error);
      toast.error('Failed to disable profile.');
    }
  };

  const handleMoveProfilesAfterEffect = () => {
    // Remove the moved profile from all data states
    const updatedData = data.filter((profile) => profile._id !== selectedProfile._id);
    const updatedFilteredData = filteredData.filter(
      (profile) => profile._id !== selectedProfile._id
    );
    const updatedDBData = dbData.filter((profile) => profile._id !== selectedProfile._id);

    // Update all data states
    setData(updatedData);
    setFilteredData(updatedFilteredData);
    setDBData(updatedDBData);

    // Reset and close the profile dialog
    setSelectedProfile({ screen: 'main' });
  };

  const profileViewActionItems = [
    {
      id: 'move-profile',
      name: t('profileDetails.action.move'),
      icon: RightArrowIcon,
      popover: (
        <MoveProfile
          groupId={groupId}
          groupName={groupDetails?.name}
          profiles={[{ _id: selectedProfile?._id, email: selectedProfile?.email }]}
          onSubmit={handleMoveProfilesAfterEffect}
        />
      )
    },
    // { id: 'download-poster', name: t('profileDetails.action.downloadPoster'), icon: ArchiveIcon },
    {
      id: 'export-data',
      name: t('profileDetails.action.export'),
      icon: ExportIcon,
      isLoading: isExporting
    },
    {
      id: 'disable-store',
      name: t('profileDetails.action.disable'),
      icon: ArchiveIcon,
      popover: (
        <div className="px-4 py-2">
          <h4 className="font-semibold">{t('profileDetails.confirm.disable.title')}</h4>
          <p className="text-sm my-2 text-secondary">
            {t('profileDetails.confirm.disable.description')}
          </p>
          <Button.ButtonFilled onClick={handleSingleProfileDisable} isMini>
            {t('profileDetails.confirm.disable.submit')}
          </Button.ButtonFilled>
        </div>
      )
    },
    {
      id: 'delete',
      name: t('profileDetails.action.delete'),
      icon: SimpleCrossIcon,
      className: 'hover:bg-red-400',
      popover: (
        <div className="px-4 py-2">
          <h4 className="font-semibold"> {t('profileDetails.confirm.delete.title')}</h4>
          <p className="text-sm my-2 text-secondary">
            {t('profileDetails.confirm.delete.description')}
          </p>
          <Button.ButtonFilled onClick={handleSingleProfileDelete} isMini>
            {t('profileDetails.confirm.delete.submit')}
          </Button.ButtonFilled>
        </div>
      )
    }
  ];

  // Selection column configuration
  const selectionColumn = {
    id: 'select-col',
    header: ({ table }) => (
      <>
        <label>
          <RadioIcon className="w-5" isChecked={table.getIsAllRowsSelected()} />
          <input
            className="hidden"
            type="checkbox"
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        </label>
      </>
    ),
    cell: ({ row }) => (
      <label>
        <RadioIcon className="w-5" isChecked={row.getIsSelected()} />
        <input
          className="hidden"
          type="checkbox"
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          onChange={row.getToggleSelectedHandler()}
        />
      </label>
    )
  };

  useEffect(() => {
    const currentColumns = [...columns];
    if (showSelection) {
      setColumns([selectionColumn, ...currentColumns]);
      return;
    }

    setColumns(originalColumns);
  }, [showSelection]);

  const showProfileDetails = (row) => {
    const profile = row.original;
    setSelectedProfile({ isOpen: true, screen: 'main', ...profile });
    fetchProfileDetails(profile._id);
    if (profile?.settings?.notifications) {
      const { profileUpdated, validationRequested, noteAdded } = profile.settings?.notifications;
      if (profileUpdated || validationRequested || noteAdded) {
        removeNotification({ id: profile._id });
      }
    }
  };

  const fetchProfileDetails = async (profileId) => {
    try {
      const { data: profileDetails } = await getProfileDetails(profileId);
      setSelectedProfile((prevProfile) => ({
        ...prevProfile,
        ...profileDetails
      }));
    } catch (error) {
      console.error('Failed to get profile details:', error);
      toast.error('Failed to get profile details');
    }
  };

  useEffect(() => {
    // Filter headers based on isSelected property
    const selectedHeaders = headers.filter((header) => header.isSelected);

    // Generate columns only for selected headers
    const updatedColumns = selectedHeaders.map((header) => {
      let columnConfig;
      if (header.key === clickableColumnKey) {
        return columnHelper.accessor(header.key, {
          cell: (info) => {
            const isNonStarted = info.row.original.settings?.isNonStarted;
            const isApproved = info.row.original.settings?.isApproved;
            const totalSteps = info.row.original.settings?.totalSteps;
            const progress = info.row.original.settings?.progress;
            const notifications = info.row.original.settings?.notifications;
            const videosCount = info.row.original.settings?.videosCount;
            const videosRequired = info.row.original.settings?.videosRequired;

            return (
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <div
                    className={clsx('mb-1 relative', {
                      'cursor-pointer hover:underline hover:scale-105 transition-all': !isNonStarted
                    })}
                    onClick={isNonStarted ? null : () => showProfileDetails(info.row)}>
                    <p className="flex items-center gap-1">
                      {videosCount > videosRequired && (
                        <ExclamationTriangleIcon className="w-4 h-4 text-yellow-500 absolute -left-4 top-1" />
                      )}
                      {info.getValue()}{' '}
                      {isApproved && <CheckBadgeIcon className="w-5" color="#34d399" />}
                    </p>
                    <p className="text-xs text-slate-600">{info.row.original?.email}</p>
                  </div>

                  <span className="flex items-start gap-0.5 -mt-6">
                    {notifications?.profileUpdated && (
                      <div
                        className="w-3 h-3 rounded-full border border-backgroundGrey"
                        style={{ backgroundColor: '#51C94F' }}
                      />
                    )}
                    {notifications?.validationRequested && (
                      <div
                        className="w-3 h-3 rounded-full border border-backgroundGrey"
                        style={{ backgroundColor: '#9747FF' }}
                      />
                    )}
                    {notifications?.noteAdded && (
                      <div
                        className="w-3 h-3 rounded-full border border-backgroundGrey "
                        style={{ backgroundColor: '#FC9E5A' }}
                      />
                    )}
                  </span>
                </div>
                <ProgressBar progressPercentage={calculatePercentage(progress, totalSteps)} />
              </div>
            );
          },
          header: () => (
            <span>{getTranslation(t, `form.fields.${header.key}`, translationPrefix)?.label}</span>
          ),
          id: header.key
        });
      } else {
        columnConfig = columnHelper.accessor(header.key, {
          header: () => getTranslation(t, `form.fields.${header.key}`, translationPrefix)?.label,
          cell: (info) => <span>{info.getValue()}</span>
        });
      }

      // Ensure every columnConfig has an id
      if (!columnConfig.id) {
        columnConfig.id = header.key;
      }

      return columnConfig;
    });

    // Update columns state
    setColumns(updatedColumns);
  }, [headers, t]);

  const getGroupProfiles = async () => {
    try {
      setAreProfilesLoading(true);
      const { data } = await getAllGroupProfiles(groupId, i18n.language);

      const { profiles, headers } = data;

      const { data: regions } = await getRegions(adminProfile?.masterOrganization?._id);
      const { data: professions } = await getProfessions(adminProfile?.masterOrganization?._id);
      const { data: associationProfessions } = await getProfessionalAssociation(
        adminProfile?.masterOrganization?._id
      );
      const { data: vocationSchools } = await getVocationalSchool(
        adminProfile?.masterOrganization?._id
      );
      const { data: specialJobTitles } = await getSpecialJobTitles(
        adminProfile?.masterOrganization?._id
      );
      const { data: functionList } = await getFunction(adminProfile?.masterOrganization?._id);

      const selectedLanguage = i18n.language;

      const updatedProfileData = [...profiles].map((profile) => {
        const birthday = formatDate(profile.birthday);

        const region = regions.find((region) => region._id === profile?.region)?.[
          `name_${selectedLanguage}`
        ];

        const profession = professions.find((item) => item._id === profile?.profession)?.[
          `jobRole_${selectedLanguage === 'en' ? 'de' : selectedLanguage}`
        ];
        const functions = profile.functions?.reduce((acc, profileFunctionId) => {
          // Find the matching function object in functionList by _id
          const functionObj = functionList.find(({ _id }) => _id === profileFunctionId);
          if (functionObj) {
            // If a matching object is found, push its name in the selected language to the accumulator
            const functionName = functionObj[`name_${selectedLanguage}`];
            if (functionName) acc.push(functionName);
          }
          return acc;
        }, []);

        const associationProfession = associationProfessions.find(
          (item) => item._id === profile?.associationProfession
        )?.[`name`];

        const vocationSchool = vocationSchools.find(
          (item) => item._id === profile?.vocationSchool
        )?.[`name`];

        const specialJobTitle = specialJobTitles.find(
          (item) => item._id === profile?.specialJobTitle
        )?.[`name_${selectedLanguage === 'en' ? 'de' : selectedLanguage}`];

        const natFinalsRegion = regions.find((item) => item._id === profile?.natFinalsRegion)?.[
          `name_${selectedLanguage}`
        ];

        const worldSkillsProfession = professions.find(
          (item) => item._id === profile?.worldSkillsProfession
        )?.[`jobRole_${selectedLanguage}`];

        return {
          ...profile,
          birthday,
          region,
          profession,
          associationProfession,
          vocationSchool,
          specialJobTitle,
          natFinalsRegion,
          functions,
          worldSkillsProfession
        };
      });

      setDBData(profiles);
      setData(updatedProfileData);
      setFilteredData(updatedProfileData);
      setHeaders(
        headers.map((header) => {
          header.isSelected = true;
          return header;
        })
      );
      const c = headers.map((header) => {
        if (header.key === clickableColumnKey) {
          return columnHelper.accessor(header.key, {
            cell: (info) => {
              const isNonStarted = info.row.original.settings?.isNonStarted;
              const isApproved = info.row.original.settings?.isApproved;
              const totalSteps = info.row.original.settings?.totalSteps;
              const progress = info.row.original.settings?.progress;
              const notifications = info.row.original.settings?.notifications;
              const videosCount = info.row.original.settings?.videosCount;
              const videosRequired = info.row.original.settings?.videosRequired;

              return (
                <div className="flex flex-col gap-1">
                  <div className="flex items-center gap-1">
                    <div
                      className={clsx('mb-1 relative', {
                        'cursor-pointer hover:underline hover:scale-105 transition-all':
                          !isNonStarted
                      })}
                      onClick={isNonStarted ? null : () => showProfileDetails(info.row)}>
                      <p className="flex items-center gap-1">
                        {videosCount > videosRequired && (
                          <ExclamationTriangleIcon className="w-4 h-4 text-yellow-500 absolute -left-4 top-1" />
                        )}
                        {info.getValue()}{' '}
                        {isApproved && <CheckBadgeIcon className="w-5" color="#34d399" />}
                      </p>
                      <p className="text-xs text-slate-600">{info.row.original?.email}</p>
                    </div>

                    <span className="flex items-start gap-0.5 -mt-6">
                      {notifications?.profileUpdated && (
                        <div
                          className="w-3 h-3 rounded-full border border-backgroundGrey"
                          style={{ backgroundColor: '#51C94F' }}
                        />
                      )}
                      {notifications?.validationRequested && (
                        <div
                          className="w-3 h-3 rounded-full border border-backgroundGrey"
                          style={{ backgroundColor: '#9747FF' }}
                        />
                      )}
                      {notifications?.noteAdded && (
                        <div
                          className="w-3 h-3 rounded-full border border-backgroundGrey "
                          style={{ backgroundColor: '#FC9E5A' }}
                        />
                      )}
                    </span>
                  </div>
                  <ProgressBar progressPercentage={calculatePercentage(progress, totalSteps)} />
                </div>
              );
            },
            header: () => (
              <span>
                {getTranslation(t, `form.fields.${header.key}`, translationPrefix)?.label}
              </span>
            ),
            id: header.key
          });
        } else {
          return columnHelper.accessor(header.key, {
            header: () => getTranslation(t, `form.fields.${header.key}`, translationPrefix)?.label,
            cell: (info) => <span>{info.getValue()}</span>
          });
        }
      });
      setColumns(c);
      setOriginalColumns(c);
    } catch (e) {
      toast.error('Failed to get profiles.');
      console.error('error:', e);
    } finally {
      setAreProfilesLoading(false);
    }
  };

  const table = useReactTable({
    data: filteredData,
    columns,
    state: { sorting, rowSelection },
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  });

  const handleProfileViewClose = () => {
    setSelectedProfile(null);
    if (profileId) {
      navigate(`/${groupId}/profiles/`);
    }
  };

  const handleProfileImageEditClick = () => {
    const { firstName, familyName } = selectedProfile;
    const name = `${firstName} ${familyName}`;
    setSelectedProfile({
      ...selectedProfile,
      size: 'lg',
      screen: 'profileImage',
      hasCloseButton: false,
      onBackButtonClick: () => {
        setSelectedProfile({ ...selectedProfile, screen: 'main' });
      },
      screenHeader: (
        <div className="flex items-center justify-between">
          <div>
            <h3>{t('editProfileImage.title')}</h3>
            <p className="font-normal">
              {t('editProfileImage.profileOf')} {name}
            </p>
          </div>
          <Button.ButtonFilled onClick={triggerProfileImageSave}>
            {t('editProfileImage.submit')}
          </Button.ButtonFilled>
        </div>
      )
    });
  };

  const handleProfileInfoEditClick = () => {
    const { firstName, familyName } = selectedProfile;
    const name = `${firstName} ${familyName}`;
    setSelectedProfile({
      ...selectedProfile,
      size: 'lg',
      screen: 'profileInfo',
      hasCloseButton: false,
      onBackButtonClick: () => {
        setSelectedProfile({ ...selectedProfile, screen: 'main' });
      },
      screenHeader: (
        <div className="flex items-center justify-between">
          <div>
            <h3>{t('editProfileInformation.title')}</h3>
            <p className="font-normal">
              {t('editProfileInformation.profileOf')} {name}
            </p>
          </div>
          <Button.ButtonFilled onClick={triggerProfileInfoSave}>
            {t('editProfileInformation.submit')}
          </Button.ButtonFilled>
        </div>
      )
    });
  };

  const handleStrengthsAndRolesEditClick = () => {
    const { firstName, familyName } = selectedProfile;
    const name = `${firstName} ${familyName}`;
    setSelectedProfile({
      ...selectedProfile,
      size: 'lg',
      screen: 'strengthsAndRoles',
      hasCloseButton: false,
      onBackButtonClick: () => {
        setSelectedProfile({ ...selectedProfile, screen: 'main' });
      },
      screenHeader: (
        <div className="flex items-center justify-between">
          <div>
            <h3> {t('editStrengthsAndRoles.title')}</h3>
            <p className="font-normal">
              {t('editStrengthsAndRoles.profileOf')} {name}
            </p>
          </div>
          <Button.ButtonFilled onClick={triggerStrengthsAndRolesSave}>
            {t('editStrengthsAndRoles.submit')}
          </Button.ButtonFilled>
        </div>
      )
    });
  };

  const handleVideosEditClick = () => {
    const { firstName, familyName } = selectedProfile;
    const name = `${firstName} ${familyName}`;
    setSelectedProfile({
      ...selectedProfile,
      size: 'xl',
      screen: 'videos',
      hasCloseButton: false,
      onBackButtonClick: () => {
        setSelectedProfile({ ...selectedProfile, screen: 'main' });
      },
      screenHeader: (
        <div className="flex items-center justify-between">
          <div>
            <h3>{t('editProfileVideos.title')}</h3>
            <p className="font-normal">
              {t('editProfileVideos.profileOf')} {name}
            </p>
          </div>
        </div>
      )
    });
  };

  const handleProfileApprove = async () => {
    setIsApproving(true);
    try {
      const selectedProfileId = selectedProfile?._id;
      await approveGroupProfiles(
        groupId,
        [selectedProfileId],
        [selectedProfile?.email],
        i18n.language
      );
      toast.success('Profile approved successfully.');
      setSelectedProfile({
        ...selectedProfile,
        settings: { ...selectedProfile.settings, isApproved: true, validationRequested: false }
      });
    } catch (error) {
      toast.error('Failed to approve profile.');
      console.error('Failed to approve profile.');
    } finally {
      setIsApproving(false);
    }
  };

  const applyFilters = (data = [], filters = []) => {
    const appliedFilters = filters.filter((filter) => filter.selectedValue);

    // Return all data if no filters are applied
    if (appliedFilters.length === 0) {
      return data;
    }

    return data.filter((item) => {
      return appliedFilters.some((filter) => {
        const field = filter.selectedField;
        const value = filter.selectedValue;

        // Handle profileStatus separately
        if (field === 'profileStatus') {
          switch (value) {
            case 'in-progress':
              return (
                (item.settings?.isApproved !== true && item.settings?.isNonStarted !== true) ||
                false
              );
            case 'not-started':
              return item.settings?.isNonStarted || false;
            case 'validated':
              return item.settings?.isApproved || false;
            default:
              return false;
          }
        }

        // Default filter logic
        return field ? item[field] === value : false;
      });
    });
  };

  const handleProfileViewActionClick = (actionItemId) => {
    if (actionItemId === 'export-data') {
      setIsExporting(true);
      const selectedProfileData = data.filter((dataItem) => dataItem._id === selectedProfile?._id);

      // Check if any items have videoStrength or videoRole
      const hasVideoStrength = selectedProfileData.some((item) => 'videoStrength' in item);
      const hasVideoRole = selectedProfileData.some((item) => 'videoRole' in item);

      selectedProfileData.forEach((profile) => {
        profile.status = profile?.settings?.isApproved
          ? 'Validated'
          : profile?.settings?.isNonStarted
          ? 'Not Started'
          : 'In Progress';
      });

      const exportHeaders = [
        { key: 'email', name: 'Email' },
        { key: 'status', name: 'Status' },
        ...headers,
        ...(hasVideoStrength ? [{ key: 'videoStrength', name: 'Video Strength' }] : []),
        ...(hasVideoRole ? [{ key: 'videoRole', name: 'Video Role' }] : []),
        { key: 'notes', name: 'Notes' }
      ];

      exportData(exportHeaders, selectedProfileData, 'Profile-Data', t, translationPrefix);
      setIsExporting(false);
    }
  };

  const handleFiltersApply = (updatedFilters) => {
    console.log(updatedFilters);
    setFilters(updatedFilters);
    setFilteredData(applyFilters(data, updatedFilters));
  };

  const getFiltersValues = () => {
    let filterList = headers.map((header) => ({
      _id: header.key,
      label: header.name,
      value: header.key
    }));
    filterList.push({ _id: 'profileStatus', label: 'Profile Status', value: 'profileStatus' });

    return filterList;
  };

  const handleProfileEmailUpdate = (emailUpdateDetails) => {
    console.log('emailUpdateDetails', emailUpdateDetails);
    setSelectedProfile({
      ...selectedProfile,
      email: emailUpdateDetails.fieldText
    });

    // Update data and filtered data tables
    setData((prevData) =>
      prevData.map((profile) =>
        profile._id === selectedProfile._id
          ? { ...profile, email: emailUpdateDetails.fieldText }
          : profile
      )
    );

    setFilteredData((prevData) =>
      prevData.map((profile) =>
        profile._id === selectedProfile._id
          ? { ...profile, email: emailUpdateDetails.fieldText }
          : profile
      )
    );

    // Update original DB data as well
    setDBData((prevData) =>
      prevData.map((profile) =>
        profile._id === selectedProfile._id
          ? { ...profile, email: emailUpdateDetails.fieldText }
          : profile
      )
    );
  };

  const removeProfilesFromData = (profileIds) => {
    // Remove profiles from all data states
    const updatedData = data.filter((profile) => !profileIds.includes(profile._id));
    const updatedFilteredData = filteredData.filter((profile) => !profileIds.includes(profile._id));
    const updatedDBData = dbData.filter((profile) => !profileIds.includes(profile._id));

    // Clear row selection
    setRowSelection({});
    // Close bulk actions by turning off selection mode
    setShowSelection(false);

    setData(updatedData);
    setFilteredData(updatedFilteredData);
    setDBData(updatedDBData);
  };

  const handleDeleteProfiles = removeProfilesFromData;
  const handleDisableProfiles = removeProfilesFromData;
  const handleMoveProfiles = removeProfilesFromData;

  const handleNoteAdded = ({ content: newNote }) => {
    // Update the selected profile and data states with the new note
    const updatedProfile = {
      ...selectedProfile,
      notes: selectedProfile?.notes ? `${selectedProfile.notes}\n${newNote}` : newNote
    };

    setSelectedProfile(updatedProfile);

    // Update the profile in all data states
    const updateProfileInList = (profiles) =>
      profiles.map((profile) =>
        profile._id === selectedProfile._id
          ? {
              ...profile,
              notes: profile.notes ? `${profile.notes}\n${newNote}` : newNote
            }
          : profile
      );

    setData(updateProfileInList(data));
    setFilteredData(updateProfileInList(filteredData));
    setDBData(updateProfileInList(dbData));
  };

  const handleVideoInformationUpdate = (updatedVideoInformation) => {
    setGroupDetails((prevDetails) => ({
      ...prevDetails,
      videosInformation: updatedVideoInformation
    }));
  };

  return (
    <>
      <div className="App">
        <div className={'h-screen flex'}>
          <div className={'flex-1 min-w-0 bg-white flex flex-col'}>
            <Header />
            <div className="m-6">
              {/* Back Button & Group Name */}
              <div className="mt-8 mb-16 flex items-center justify-between">
                <BackButtonIcon onClick={() => navigate('/dashboard')} />
                {groupDetails?.name && groupDetails?.columnName && (
                  <p>
                    <span
                      className="text-3xl font-semibold cursor-pointer"
                      onClick={() => {
                        searchParams.set('view', 'groupDetails');
                        setSearchParams(searchParams);
                      }}>
                      {groupDetails?.name}
                    </span>{' '}
                    in <span className="underline">{groupDetails?.columnName}</span>
                  </p>
                )}
              </div>

              {/* Bulk Actions */}
              <div className="mb-6 flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <ColumnVisibility
                    translationPrefix={translationPrefix}
                    colors={colors}
                    list={headers}
                    onApply={(list) => setHeaders(list)}
                  />
                  <Filters
                    colors={colors}
                    headers={getFiltersValues()}
                    data={data}
                    filters={filters}
                    onApply={handleFiltersApply}
                  />
                  <TableBulkActions
                    colors={colors}
                    groupId={groupId}
                    groupName={groupDetails?.name}
                    allData={data}
                    headers={headers}
                    selectedRows={rowSelection}
                    onDeleteProfiles={handleDeleteProfiles}
                    onDisableProfiles={handleDisableProfiles}
                    onMoveProfiles={handleMoveProfiles}
                    onSelectClick={(select) => {
                      setShowSelection(select);
                      table.toggleAllRowsSelected(false);
                    }}
                  />
                </div>
                <AddProfiles colors={colors} groupId={groupId} onSubmit={getGroupProfiles} />
              </div>

              {/* Table */}
              <div className="flex-1 overflow-auto mb-12">
                <div
                  ref={containerRef}
                  className="rounded-lg border border-slate-500 overflow-auto max-h-[78vh]">
                  {areProfilesLoading ? (
                    <div className="w-full flex items-center justify-center py-6">
                      <Loading loading={areProfilesLoading} />
                    </div>
                  ) : (
                    <table
                      className="min-w-full border-separate whitespace-nowrap"
                      style={{ borderSpacing: '0' }}>
                      <thead
                        className=" top-0"
                        style={{
                          backgroundColor: `${colors?.secondary}25`
                        }}>
                        {table
                          .getHeaderGroups()
                          .map((headerGroup, headerGroupIndex, headerGroupArray) => (
                            <tr key={headerGroup.id}>
                              {headerGroup.headers.map((header, index) => {
                                // Determine border classes dynamically
                                const isLastColumn = index === headerGroup.headers.length - 1;
                                const borderClasses = `border-t ${
                                  isLastColumn ? 'border-r' : ''
                                } border-l border-slate-600`;
                                let stickyClasses = '';
                                if (index === 0) {
                                  // First column
                                  stickyClasses = ' left-0 z-10 border-r';
                                } else if (index === 1 && false) {
                                  // Second column, only if there's a checkbox in the first column
                                  stickyClasses = 'sticky left-20 z-10 bg-gray-50';
                                }
                                return (
                                  <th
                                    key={header.id}
                                    colSpan={header.colSpan}
                                    className={`px-6 py-3 text-left font-semibold text-black tracking-wider ${borderClasses} ${stickyClasses}`}>
                                    {header.isPlaceholder ? null : (
                                      <div
                                        {...{
                                          className: header.column.getCanSort()
                                            ? 'cursor-pointer select-none'
                                            : '',
                                          onClick: header.column.getToggleSortingHandler()
                                        }}>
                                        {flexRender(
                                          header.column.columnDef.header,
                                          header.getContext()
                                        )}
                                        {{
                                          asc: ' 🔼',
                                          desc: ' 🔽'
                                        }[header.column.getIsSorted()] ?? null}
                                      </div>
                                    )}
                                  </th>
                                );
                              })}
                            </tr>
                          ))}
                      </thead>
                      <tbody className="bg-white">
                        {table.getRowModel().rows.map((row, rowIndex, rowArray) => (
                          <tr
                            key={row.id}
                            style={{
                              backgroundColor: row.getIsSelected() ? `${colors?.secondary}45` : null
                            }}>
                            {row.getVisibleCells().map((cell, cellIndex) => {
                              const isNonStarted = cell.row.original?.settings?.isNonStarted;
                              // Determine border classes dynamically
                              const isLastRow = rowIndex === rowArray.length - 1;
                              const isLastColumn = cellIndex === row.getVisibleCells().length - 1;
                              const borderClasses = `border-l ${
                                isLastRow ? 'border-b border-t' : 'border-t'
                              } ${isLastColumn ? 'border-r' : ''} border-slate-700`;

                              let stickyClasses = '';
                              if (cellIndex === 0) {
                                stickyClasses = ' left-0 z-10 border-r';
                              } else if (cellIndex === 1 && false) {
                                stickyClasses = 'sticky left-20 z-10 bg-white';
                              }

                              return (
                                <td
                                  key={cell.id}
                                  className={clsx(
                                    `px-6 py-4 whitespace-nowrap`,
                                    borderClasses,
                                    stickyClasses,
                                    { 'bg-gray-300': isNonStarted }
                                  )}>
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* SHOW PROFILE DETAILS */}
        <Dialog
          size={selectedProfile?.size || '3xl'}
          header={selectedProfile?.screenHeader}
          hasCloseButton={selectedProfile?.hasCloseButton}
          show={selectedProfile?.isOpen}
          onClose={handleProfileViewClose}
          onBackButtonClick={selectedProfile?.onBackButtonClick}>
          {selectedProfile?.screen === 'main' && (
            <>
              <div className="w-full flex justify-between gap-6 p-6">
                <div className="w-[60%]">
                  <h6 className="text-2xl font-bold mb-2 flex items-center gap-2">
                    <span>{selectedProfile?.name}</span>{' '}
                    {selectedProfile?.settings?.isApproved && (
                      <CheckBadgeIcon className="w-6" color="#34d399" />
                    )}
                  </h6>
                  <EditableField
                    // isEditable={canManageGroups}
                    isEditable={true}
                    text={selectedProfile?.email}
                    id={selectedProfile?._id}
                    label={t('profileDetails.emailUpdate.fieldTitle')}
                    successMessage={t('profileDetails.emailUpdate.success')}
                    errorMessage={t('profileDetails.emailUpdate.error')}
                    submitFunction={async (id, { fieldText }) =>
                      await updateProfileEmail(id, { fieldText, languageId: i18n.language })
                    }
                    onUpdate={handleProfileEmailUpdate}
                    className="block -mt-2"
                    DisplayComponent="span"
                  />
                  <div className="w-full text-lg font-medium my-10">
                    <ItemCompletionIndicator
                      showProgress={false}
                      onClick={handleProfileImageEditClick}>
                      <span>{t('profileDetails.profileImage')}</span>
                      <Badge isCompleted={selectedProfile?.settings?.hasProfileImage} />
                    </ItemCompletionIndicator>
                    <ItemCompletionIndicator
                      showProgress={false}
                      value={selectedProfile?.hasProfileInfo}
                      onClick={handleProfileInfoEditClick}>
                      <span>{t('profileDetails.profileInformation')}</span>
                      <Badge isCompleted={selectedProfile?.settings?.hasProfileInfo} />
                    </ItemCompletionIndicator>
                    <ItemCompletionIndicator
                      showProgress={false}
                      value={selectedProfile?.hasStrenghtsAndRoles}
                      onClick={handleStrengthsAndRolesEditClick}>
                      <span>{t('profileDetails.strengthsAndRoles')}</span>
                      <Badge isCompleted={selectedProfile?.settings?.hasStrenghtsAndRoles} />
                    </ItemCompletionIndicator>
                    {groupDetails?.hasVideoFunction && (
                      <ItemCompletionIndicator
                        value={selectedProfile?.settings?.videosCount}
                        total={selectedProfile?.settings?.videosRequired}
                        onClick={handleVideosEditClick}>
                        <span>{t('profileDetails.videos.title')}</span>
                        <span className="text-secondary flex items-center gap-1">
                          {selectedProfile?.settings?.videosCount >
                            selectedProfile?.settings?.videosRequired && (
                            <Popover
                              position="right"
                              size="sm"
                              button={
                                <ExclamationTriangleIcon className="w-4 h-4 text-yellow-500 cursor-help" />
                              }>
                              <div className="p-2">
                                <p className="text-sm">{t('profileDetails.videos.tooMany')}</p>
                              </div>
                            </Popover>
                          )}
                          ({selectedProfile?.settings?.videosCount ?? '0'}/
                          {selectedProfile?.settings?.videosRequired ?? '0'})
                        </span>
                      </ItemCompletionIndicator>
                    )}
                    <div className="w-full flex items-center justify-between gap-6 mt-12 ">
                      <Button.ButtonPlatform
                        isMini
                        onClick={() => {
                          const profileURL = getProfileUrl(
                            selectedProfile?.profileType,
                            selectedProfile?._id
                          );
                          window.open(profileURL, '_blank');
                        }}>
                        <ViewIcon />
                        {t('profileDetails.action.preview')}
                      </Button.ButtonPlatform>
                      <div className="flex items-center gap-2">
                        {!selectedProfile?.settings?.isApproved && (
                          <>
                            <Popover
                              position="bottom left"
                              button={
                                <Button.ButtonFilled isMini loading={isApproving}>
                                  {t('profileDetails.action.approve')}
                                </Button.ButtonFilled>
                              }>
                              <div className="px-4 py-2">
                                <h4 className="font-semibold">
                                  {t('profileDetails.approve.title')}
                                </h4>
                                <p className="text-sm my-2 text-secondary">
                                  {t('profileDetails.approve.description')}
                                </p>
                                <Button.ButtonFilled
                                  type="button"
                                  isMini
                                  loading={isApproving}
                                  onClick={handleProfileApprove}>
                                  {t('profileDetails.approve.submit')}
                                </Button.ButtonFilled>
                              </div>
                            </Popover>
                            {selectedProfile?.settings?.validationRequested && (
                              <Button.ButtonOutlined
                                isMini
                                onClick={() => {
                                  setSelectedProfile({
                                    ...selectedProfile,
                                    screen: 'refuse-profile',
                                    onBackButtonClick: () => {
                                      setSelectedProfile({
                                        ...selectedProfile,
                                        screen: 'main',
                                        screenHeader: null
                                      });
                                    },
                                    screenHeader: (
                                      <div className="flex items-center justify-between">
                                        <div>
                                          <h3>{t('refuseProfile.title')}</h3>
                                          <p className="font-normal">{selectedProfile?.email}</p>
                                        </div>
                                      </div>
                                    )
                                  });
                                }}
                                className="bg-white text-gray-900">
                                {t('profileDetails.action.refuse')}
                              </Button.ButtonOutlined>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <ul className="text-tertiary space-y-2">
                  {profileViewActionItems.map((actionItem) => (
                    <li key={actionItem.id}>
                      {actionItem.popover ? (
                        <Popover
                          position="bottom right"
                          size={actionItem.id === 'move-profile' ? 'normal' : 'sm'}
                          button={
                            <button
                              type="button"
                              className={clsx(
                                'flex items-center gap-2 rounded hover:bg-orange-100 px-1 cursor-pointer',
                                actionItem.className
                              )}>
                              <actionItem.icon /> <span>{actionItem.name}</span>
                            </button>
                          }>
                          {actionItem.popover}
                        </Popover>
                      ) : (
                        <ActionItemButton
                          icon={actionItem.icon}
                          name={actionItem.name}
                          className={actionItem.className}
                          onClick={() => handleProfileViewActionClick(actionItem.id)}
                          isLoading={actionItem.isLoading}
                        />
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              <Notes type="user" id={selectedProfile?._id} handleNoteAdded={handleNoteAdded} />
            </>
          )}
          {selectedProfile?.screen === 'refuse-profile' && (
            <RefuseProfile
              groupId={groupId}
              profileId={selectedProfile?._id}
              onSubmit={() =>
                setSelectedProfile({
                  ...selectedProfile,
                  screen: 'main',
                  screenHeader: null,
                  settings: {
                    ...selectedProfile.settings,
                    isApproved: false,
                    validationRequested: false
                  }
                })
              }
            />
          )}
          {selectedProfile?.screen === 'profileImage' && (
            <ProfileImage
              ref={saveProfileImageRef}
              profileId={selectedProfile?._id}
              url={selectedProfile?.image}
              onUpdate={(imageUrl) => {}}
            />
          )}
          {selectedProfile?.screen === 'profileInfo' && (
            <ProfileInformation
              profile={dbData.find((dbDataItem) => dbDataItem._id === selectedProfile?._id)}
              ref={saveProfileInfoRef}
              profileId={selectedProfile?._id}
              url={selectedProfile?.image}
              onUpdate={(updatedProfile) => {
                // setSelectedProfile({ isOpen: false, screen: 'main' });
                getGroupProfiles();
              }}
            />
          )}
          {selectedProfile?.screen === 'strengthsAndRoles' && (
            <StrengthsAndRoles ref={saveStrengthsAndRolesRef} profile={selectedProfile} />
          )}
          {selectedProfile?.screen === 'videos' && (
            <Videos
              profile={selectedProfile}
              groupDetails={groupDetails}
              onVideoInformationUpdate={handleVideoInformationUpdate}
            />
          )}
        </Dialog>
        <Dialog
          size="3xl"
          show={showGroupDetailsView}
          header={groupDetails?.name}
          onClose={() => {
            searchParams.delete('view');
            setSearchParams(searchParams);
          }}
          onBackButtonClick={
            !showGroupDetailsEmailProfilesView && !showGroupDetailsTeamMembersView
              ? null
              : () => {
                  searchParams.delete('groupDetailsView');
                  setSearchParams(searchParams);
                }
          }>
          {!showGroupDetailsEmailProfilesView && !showGroupDetailsTeamMembersView && (
            <GroupDetailsView
              groupId={groupId}
              canManageGroups={true}
              canManageProfiles={true}
              showManageProfilesButton={false}
            />
          )}
          {showGroupDetailsEmailProfilesView && <EmailProfiles groupId={groupId} />}
          {showGroupDetailsTeamMembersView && <GroupTeamMembers groupId={groupId} />}
        </Dialog>
      </div>
    </>
  );
}

// Component for the common action item layout
const ActionItemButton = ({ icon: Icon, name, onClick, className, isLoading }) => (
  <button
    type="button"
    onClick={onClick}
    className={clsx(
      'flex items-center gap-2 rounded hover:bg-orange-100 px-1 cursor-pointer',
      className
    )}>
    {isLoading ? <LoadingInButton size="small" position="none" loading={isLoading} /> : <Icon />}
    <span>{name}</span>
  </button>
);
