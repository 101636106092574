import React from 'react';

import HeaderProfile from './headerProfile';
import HeaderGoBack from './headerGoBack';
import HeaderDesktop from './headerDesktop';
import HeaderPdf from './headerPdf';
import HeaderWithTitle from './headerWithTitle';
import HeaderMain from './headerMain';

export default function Header({
  type,
  subtype,
  title = '',
  logo,
  hideButtons = false,
  menuShow,
  ...props
}) {
  switch (type) {
    case 'profile':
      return <HeaderProfile />;
    case 'goBack':
      return <HeaderGoBack {...props} />;
    case 'desktop':
      return <HeaderDesktop {...props} />;
    case 'pdf':
      return <HeaderPdf subtype={subtype} title={title} logo={logo} {...props} />;
    case 'title':
      return <HeaderWithTitle title={title} />;
    default:
      return <HeaderMain menuShow={menuShow} />;
  }
}

Header.defaultProps = {
  type: 'main',
  menuShow: true
};
