import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { addGroupColumn, getProfileTypes } from '../service';
import { addNewGroupColumnSchema } from '../utils/schema';
import { Dialog, RadioSelect, TextInput } from './common';
import Button from './common/button';

const NewGroupColumn = ({
  showAddNewGroupColumn = false,
  setShowAddNewGroupColumn = () => {},
  onUpdate = () => {}
}) => {
  const { t } = useTranslation();
  const [validProfileTypes, setValidProfileTypes] = useState([]);

  useEffect(() => {
    async function fetchValidProfilesTypes() {
      const { data: profilesTypes } = await getProfileTypes();
      setValidProfileTypes(
        profilesTypes.map((profileType) => {
          return {
            value: profileType._id,
            label: profileType.name
          };
        })
      );
    }
    fetchValidProfilesTypes();
  }, []);

  const groupColumnFormik = useFormik({
    initialValues: { columnName: '', profileType: validProfileTypes?.[0]?.value },
    validationSchema: addNewGroupColumnSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const { columnName, profileType } = values;
        await addGroupColumn({ columnName, profileType });
        toast.success('Group Column created successfully.');
        setShowAddNewGroupColumn(false);
        onUpdate();
      } catch (error) {
        console.error('Failed to create group.');
        toast.error('Failed to create group.');
      }
    }
  });

  return (
    <Dialog
      show={showAddNewGroupColumn}
      onClose={() => setShowAddNewGroupColumn(false)}
      header={t('groupColumn.title')}>
      <form onSubmit={groupColumnFormik.handleSubmit}>
        <TextInput
          name="columnName"
          label={t('groupColumn.columnNameField')}
          value={groupColumnFormik.values.columnName}
          onChange={groupColumnFormik.handleChange}
          onBlur={groupColumnFormik.handleBlur}
          error={groupColumnFormik.errors.columnName}
        />
        <RadioSelect
          name="profileType"
          label={t('groupColumn.profileTypeField')}
          className="my-1"
          value={groupColumnFormik.values.profileType}
          touched={groupColumnFormik.touched.profileType}
          onBlur={groupColumnFormik.handleBlur}
          error={groupColumnFormik.errors.profileType}
          options={validProfileTypes}
          onChange={(option) => {
            groupColumnFormik.setFieldValue('profileType', option, true);
          }}
        />
        <footer className="w-full flex items-center justify-end">
          <Button.ButtonFilled type="submit" loading={groupColumnFormik.isSubmitting}>
            {t('groupColumn.submit')}
          </Button.ButtonFilled>
        </footer>
      </form>
    </Dialog>
  );
};

export default NewGroupColumn;
