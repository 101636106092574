//Get Token
export const getToken = () => {
  var token = localStorage.getItem('token');
  if (!token) {
    return null;
  }
  return token;
};
//Convert Base 64 Image to Blob
export function base64ToBlob(base64) {
  const cleanedBase64 = base64.replace(/\s/g, '');
  const binary = atob(cleanedBase64.split(',')[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }

  const mimeString = cleanedBase64.split(',')[0].split(':')[1].split(';')[0];
  // return new Blob([new Uint8Array(array)], { type: mimeString });
  const extension = mimeString.split('/')[1];
  const blob = new Blob([new Uint8Array(array)], { type: mimeString });
  blob.name = `image.${extension}`;
  return blob;
}

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const getResizedImageName = (imageName, size) => {
  const acceptableSizes = [100, 200, 300, 400];
  if (!imageName || !acceptableSizes.includes(size)) {
    return imageName;
  }

  const extensionIndex = imageName.lastIndexOf('.');
  const namePart = imageName.slice(0, extensionIndex);
  const extensionPart = imageName.slice(extensionIndex);

  return `${namePart}_${size}x${size}${extensionPart}`;
};

export const changeFavicon = (faviconUrl) => {
  const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = faviconUrl;
  document.getElementsByTagName('head')[0].appendChild(link);
};

// Formats the date like this: Wed 07/11/23 at 2:00pm
export const formatDateWithTime = (dateStr) => {
  const date = new Date(dateStr);

  // Format the day of the week, day, month, and year
  const dayMonthYear = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  }).format(date);

  // Format the time with AM/PM
  const time = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  }).format(date);

  return `${dayMonthYear} at ${time}`;
};

// Helper function to format the date as "DayOfWeek Day Month"
export const formatDateWithDays = (dateStr) => {
  const date = new Date(dateStr);
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const monthsOfYear = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  const dayOfWeek = daysOfWeek[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = monthsOfYear[date.getMonth()];

  return `${dayOfWeek} ${dayOfMonth} ${month}`;
};

// Outputs: 20 / 02 / 2024;
export const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // JavaScript months are 0-based
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const calculatePercentage = (numerator, denominator) => {
  try {
    // Ensure inputs are numbers and denominator is not zero
    numerator = Number(numerator);
    denominator = Number(denominator);

    if (isNaN(numerator) || isNaN(denominator) || denominator === 0) {
      return 0;
    }

    const totalPercentage = (numerator / denominator) * 100;

    // Adjust the percentage based on the specified condition
    return totalPercentage >= 90 && totalPercentage < 100 ? totalPercentage - 5 : totalPercentage;
  } catch (error) {
    console.error(`Error calculating percentage: ${error.message}`);
    return 0;
  }
};

export const getSortedList = (list, lang, property = 'name') => {
  return list.sort((a, b) =>
    a[`${property}_${lang}`]?.localeCompare(b[`${property}_${lang}`], lang)
  );
};

export const getSortedColumns = (columns = []) => {
  const sortedColumns = columns.sort((a, b) => a._id?.localeCompare(b._id));

  return sortedColumns;
};

export const getTranslation = (t, key, orgPrefix = '', array = false, index = 0, subKey = '') => {
  let translation = t(key, { returnObjects: true });

  if (array) translation = translation?.[index]?.[subKey];

  if (translation?.[orgPrefix]) return translation[orgPrefix];
  else return translation?.default ? translation.default : key?.toString();
};
