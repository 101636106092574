import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { HelpLinePopup } from '../components';
import { LoginForm } from '../components/forms';
import { adminLogin } from '../service';
import { LoginSchema } from '../utils/schema';

const VALID_LANGUAGES = ['de', 'fr', 'it', 'en'];
const DEFAULT_LANGUAGE = 'en';

export default function Login() {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const [emailSent, setEmailStatus] = useState(false);

  useEffect(() => {
    const selectedLanguage = searchParams.get('lang');
    if (VALID_LANGUAGES.includes(selectedLanguage)) {
      localStorage.setItem('lang', selectedLanguage);
      i18n.changeLanguage(selectedLanguage);
    } else {
      localStorage.setItem('lang', DEFAULT_LANGUAGE);
      i18n.changeLanguage(DEFAULT_LANGUAGE);
    }
  }, []);

  const submitHandler = async (values, {}) => {
    try {
      const response = await adminLogin(values);
      if (response?.status === 200) {
        setEmailStatus(true);
      } else toast.error(t('login.error.title'));
    } catch (error) {
      toast.error(error.message);
    } finally {
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: LoginSchema,
    onSubmit: submitHandler
  });

  const closeModal = () => {
    formik.resetForm();
    setEmailStatus(false);
  };

  return (
    <div>
      {emailSent ? (
        <div className="z-50 backdrop-blur-sm absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <div className="rounded w-[600px] max-w-[100%]">
            <HelpLinePopup
              title={t('login.success.title')}
              description={t('login.success.description')}
              icon={'email-sent-generic'}
              closeModal={closeModal}
              type="generic"
            />
          </div>
        </div>
      ) : (
        <LoginForm formik={formik} />
      )}
    </div>
  );
}
