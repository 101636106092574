import Select, { components } from 'react-select';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

export default function InputMultiSelect({
  label,
  error,
  touched,
  labelClassName,
  options,
  onReset,
  ...props
}) {
  return (
    <div className="">
      {label && <label className={`${labelClassName}`}>{label}</label>}

      {onReset && props.value && (
        <ArrowPathIcon
          className="w-4 inline-block ml-2 cursor-pointer"
          title="reset"
          onClick={onReset}
        />
      )}

      <Select
        styles={{
          control: (base) => ({
            ...base,
            background: '#e4e4e7'
          })
        }}
        hideSelectedOptions={false}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: 'gray',
            primary: 'black'
          }
        })}
        isMulti={true}
        className="mb-4"
        options={options}
        blurInputOnSelect={false}
        closeMenuOnSelect={false}
        components={{ Option: CheckboxOption }}
        {...props}
      />
      {error && touched && <p className="text text-red-500">{error}</p>}
    </div>
  );
}
const CheckboxOption = ({ children, isSelected, ...rest }) => {
  const handleChange = () => {
    // You can perform any custom logic here if needed
  };

  return (
    <components.Option {...rest}>
      <div className="flex justify-between cutom-checkbox">
        <span>{children}</span>
        <input type="checkbox" checked={isSelected} onChange={handleChange} />
      </div>
    </components.Option>
  );
};
