import React from 'react';
import { FaChevronLeft } from 'react-icons/fa';

export default function HeaderGoBack({ props }) {
  const handler = () => {};
  return (
    <section className="header header-fixed">
      <div className={`flex gap-4 items-center`}>
        <button className="btn btn-rounded btn-menu" onClick={handler}>
          <FaChevronLeft className="icon" />
        </button>
      </div>
    </section>
  );
}
