import React from 'react';

import { CrossButton } from '../common';
import HelpLinePopupItem from './helpLinePopupItem';

export default function HelpLinePopup({ icon, title, description, type, closeModal }) {
  const crossButtonColor =
    type === 'generic' ? 'bg-gray-500' : type === 'ch' ? 'bg-chred-300' : 'bg-cblue-500';

  return (
    <div className="px-5 py-5 bg-white rounded shadow-lg relative">
      <div className="content">
        <CrossButton
          onClick={closeModal}
          className={`${crossButtonColor} p-4 rounded-full text-white z-20 absolute top-[10px] left-[10px]`}
        />
        <div className="text-center">
          <img
            src={`../../images/icons/${icon}.png`}
            className="mx-auto mb-6 w-[100px] md:w-[150px] lg:w-[200px]"
            alt=""
          />
        </div>
        <HelpLinePopupItem title={title} description={description} />
      </div>
    </div>
  );
}
