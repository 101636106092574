import { useTranslation } from 'react-i18next';
import { calculatePercentage } from '../utils';

const GroupCard = ({ groupItem, onClick = () => {} }) => {
  const { t } = useTranslation();

  const totalProfiles = groupItem.notStarted + groupItem.inProgress + groupItem.validated;

  return (
    <li className="mt-3">
      <button
        type="button"
        onClick={() => onClick(groupItem)}
        className={'block p-5 bg-genericGray-100 rounded-xl shadow-sm w-full hover:shadow'}>
        <div className="flex items-start justify-between">
          <h4 className="text-left font-bold break-words w-[88%]">{groupItem.name}</h4>
          {/* <MeatballsMenu className="cursor-pointer" /> */}
        </div>
        <div className="mt-4 text-textAndIcons">
          <div className="flex items-center justify-between mb-4">
            <span className="font-semibold text-sm">{t('groupCardView.allProfiles')}</span>
            <span className=" font-semibold text-sm tabular-nums slashed-zero">
              {totalProfiles}
            </span>
          </div>
          <div className="flex items-center justify-between mb-1">
            <span className="text-sm">{t('groupCardView.notStarted')}</span>
            <span className="text-sm slashed-zero">{groupItem.notStarted}</span>
          </div>
          <div className="flex items-center justify-between mb-1">
            <span className="text-sm">{t('groupCardView.inProgress')}</span>
            <span className="text-sm slashed-zero">{groupItem.inProgress}</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-sm">{t('groupCardView.validated')}</span>
            <span className="text-sm slashed-zero">{groupItem.validated}</span>
          </div>
        </div>
        <div className="w-full bg-[#D9D9D9] rounded-full h-2.5 mt-4">
          <div
            className="bg-[#515151] h-2.5 rounded-full"
            style={{
              width: `${calculatePercentage(groupItem.validated, totalProfiles)}%`
            }}></div>
        </div>
      </button>
    </li>
  );
};

export default GroupCard;
