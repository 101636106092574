export default function HelpLinePopupItem(props) {
  return (
    <div className="text-center">
      <h2 className="font-bold text-2xl font-bold">{props.title}</h2>
      {props.description && <p className="max-w-xs mx-auto mt-5 text-xl">{props.description}</p>}
      {props.button && (
        <button className={props.button.className} onClick={props.button.onClick}>
          <span aria-label={props.button.label}>{props.button.icon}</span>
        </button>
      )}
    </div>
  );
}
