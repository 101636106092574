import React from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import { Input } from '../';
import Button from '../common/button';

const LoginForm = ({ formik }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-screen flex items-center justify-center " data-testid="login-form">
      <div className="w-auto md:w-5/12 max-w-2xl">
        <div className="md:px-10 relative">
          <div className="bg-cblack pb-2 md:bg-white max-w-2xl text-center">
            <h1 className="mb-6 text-3xl text-center font-bold md:text-cback underline decoration-slate-500 decoration-2 underline-offset-8 md:decoration-0 md:decoration-white uppercase">
              Connect
            </h1>
            <p className="mb-2">Please enter your e-mail and click on LOGIN.</p>
            <p className="mb-2">Geben Sie bitte Ihre E-Mail an und klicken Sie auf LOGIN.</p>
            <p className="mb-2">Veuillez renseigner votre email et cliquer sur LOGIN.</p>
            <p className="mb-2">Inserisca il suo indirizzo e-mail e clicchi su LOGIN.</p>
          </div>
          <form className="py-8 mx-2" onSubmit={formik.handleSubmit}>
            <div className="flex flex-col justify-between max-w-2xl">
              <div>
                <Input
                  className="mb-3"
                  label={t('login.fields.emailAddress.label')}
                  inputClassName={clsx('text-sm', {
                    'bg-red-50 border-red-200': formik.errors.email
                  })}
                  labelClassName="text-xl font-bold text-[#191919] mb-[10px]"
                  name="email"
                  data-testid="email-field"
                  placeholder={t('login.fields.emailAddress.placeholder')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.errors.email}
                  touched={formik.touched.email}
                />
              </div>

              <div className="text-center mt-20">
                <Button.ButtonRounded
                  loading={formik.isSubmitting}
                  className={`bg-gray-500 uppercase rounded text-white px-[78px] py-[14px] font-bold mx-auto disabled:opacity-60 gap-10 rounded-5`}
                  type="submit"
                  data-testid="submit-button">
                  {t('login.actionButtonText')}
                </Button.ButtonRounded>
                <br />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
