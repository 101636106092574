import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { addNote, deleteNote, getNotes } from '../../service';
import { formatDateWithTime } from '../../utils';
import { CrossIcon, NextButtonIcon } from '../icons';
import { useTranslation } from 'react-i18next';
import Loading from './loading';
import LoadingHorizontalBar from './loadingHorizontalBar';
import Popover from './popover';
import Button from './button';

const MAX_CHARACTER_COUNT = 200;
const MIN_CHARACTER_COUNT = 5;

// 'id' = group or user id based on the 'type'
export default function Notes({ type, id, handleNoteAdded = () => {} }) {
  const { t } = useTranslation();
  const notesFieldRef = useRef(null);
  const notesListRef = useRef(null);

  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOverLimit, setIsOverLimit] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const [showMinCharError, setShowMinCharError] = useState(false);
  const [isSubmittingNote, setIsSubmittingNote] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [isDeletingNote, setIsDeletingNote] = useState(null);

  useEffect(() => {
    const getAllNotes = async () => {
      if (!type || !id) return;

      try {
        setIsLoading(true);
        const { data } = await getNotes(type, id);
        setNotes(data);
      } catch (e) {
        toast.error(t('notes.errors.load'));
        console.error('error:', e);
      } finally {
        setIsLoading(false);
      }
    };

    getAllNotes();
  }, [type, id]);

  useEffect(() => {
    if (notesListRef.current) {
      notesListRef.current.scrollTop = 0;
    }
  }, [notes]);

  const handleAddNote = async () => {
    if (isSubmittingNote) {
      return;
    }

    const fieldValue = notesFieldRef.current?.value;

    if (!fieldValue || isSubmittingNote) {
      return;
    }

    if (fieldValue.trim().length < MIN_CHARACTER_COUNT) {
      setShowMinCharError(true);
      return;
    }

    try {
      setIsSubmittingNote(true);

      const { data: addedNote } = await addNote({
        type,
        [type]: id,
        content: fieldValue
      });

      toast.success(t('notes.success.add'));
      handleNoteAdded(addedNote);

      // Add note to notes list
      const allNotes = JSON.parse(JSON.stringify(notes));
      allNotes.unshift({
        _id: addedNote._id,
        content: addedNote.content,
        updatedAt: addedNote.updatedAt
      });

      setNotes(allNotes);
      notesFieldRef.current.value = '';
      setCharacterCount(0);
    } catch (error) {
      console.error('Failed to add a note: ', error);
      toast.error(t('notes.errors.save'));
    } finally {
      setIsSubmittingNote(false);
    }
  };

  const handleDeleteNote = async (id) => {
    try {
      if (isDeletingNote) {
        return;
      }

      setIsDeletingNote(id);
      await deleteNote(id);
      setNotes(notes.filter((note) => note._id !== id));
      setNoteToDelete(null);
      toast.success(t('notes.success.delete'));
    } catch (error) {
      console.error('Failed to delete note: ', error);
      toast.error(t('notes.errors.delete'));
    } finally {
      setIsDeletingNote(null);
    }
  };

  const handleNoteChange = (e) => {
    const value = e.target.value.replace(/[\r\n\t\f\v]/g, '');

    if (showMinCharError) {
      setShowMinCharError(false);
    }

    if (value.length <= MAX_CHARACTER_COUNT) {
      setCharacterCount(value.length);
    } else {
      e.target.value = value.slice(0, MAX_CHARACTER_COUNT);
      setCharacterCount(MAX_CHARACTER_COUNT);
      setIsOverLimit(true);
      setTimeout(() => {
        setIsOverLimit(false);
      }, 500);
    }
  };

  const handleKeyDown = (e) => {
    // Prevent Enter key (including Shift+Enter)
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="relative">
        <textarea
          ref={notesFieldRef}
          id="notes"
          rows="3"
          disabled={isSubmittingNote}
          onChange={handleNoteChange}
          onKeyDown={handleKeyDown}
          className={clsx(
            'peer',
            'px-4 py-2 w-full text-sm bg-[#F8F8F8] border-0 resize-none rounded-xl ring-1 ring-gray-200 tracking-wider shadow-sm',
            'placeholder:text-gray-400',
            'focus:outline-none focus:placeholder:text-transparent',
            showMinCharError || isOverLimit
              ? 'ring-red-500 focus:ring-red-500'
              : 'focus:ring-gray-900',
            isSubmittingNote
              ? 'text-gray-300 ring-gray-200 focus:ring-gray-200 cursor-not-allowed'
              : 'text-gray-900'
          )}
          placeholder={t('notes.placeholder')}></textarea>

        {/* Min character error message */}
        {showMinCharError && (
          <div className={clsx('absolute left-4 bottom-3 text-xs text-red-500')}>
            {t('notes.errors.minCharacters', { count: MIN_CHARACTER_COUNT })}
          </div>
        )}

        {/* Character count */}
        <div
          className={clsx(
            'absolute right-10 bottom-3 text-xs',
            showMinCharError ? 'text-red-500' : isOverLimit ? 'text-red-500' : 'text-gray-500',
            isSubmittingNote && 'text-gray-200',
            notesFieldRef.current?.value ? 'visible' : 'invisible peer-focus:visible'
          )}>
          <span className={clsx(characterCount < MIN_CHARACTER_COUNT && 'text-red-500')}>
            {characterCount}
          </span>
          /{MAX_CHARACTER_COUNT}
        </div>

        <button
          type="button"
          onClick={handleAddNote}
          className={clsx(
            'absolute -right-1 bottom-1 flex items-center justify-center p-1 w-10 h-10 cursor-pointer',
            'text-gray-900 hover:text-white group',
            'focus:ring-0',
            notesFieldRef.current?.value ? 'visible' : 'invisible peer-focus:visible',
            isSubmittingNote && 'cursor-not-allowed'
          )}>
          {isSubmittingNote ? (
            <Loading loading={true} />
          ) : (
            <NextButtonIcon className="cursor-pointer" />
          )}
        </button>
      </div>

      {isLoading ? (
        <LoadingHorizontalBar variant="small" type={type} id={id} />
      ) : notes?.length > 0 ? (
        <>
          <div className="mt-4 flex items-center gap-4">
            <div className="h-px flex-1 bg-gray-200"></div>
            <div className="text-sm font-bold text-gray-500">
              {t('notes.list.title', { count: notes.length })}
            </div>
            <div className="h-px flex-1 bg-gray-200"></div>
          </div>
          <div
            ref={notesListRef}
            className="w-full rounded-xl mt-2 pr-6 overflow-y-auto max-h-[180px]">
            <ol className="border-s border-gray-200 ml-8 my-2">
              {notes?.map((note, index) => (
                <li className="mb-6 ms-6" key={note._id}>
                  <div className="relative group">
                    <span className="absolute flex items-center justify-center w-6 h-6 bg-gray-100 rounded-full -left-9 top-2 ring-4 ring-white text-xs text-gray-500">
                      {notes.length - index}
                    </span>
                    <div
                      className={clsx(
                        'p-3 bg-[#F8F8F8] text-[#757575] border border-gray-200 rounded-xl shadow-sm text-sm',
                        noteToDelete === note._id && 'ring-1 ring-red-500'
                      )}>
                      <div className="break-words whitespace-pre-wrap pr-8">{note.content}</div>
                      <Popover
                        position="left center"
                        onOpen={() => setNoteToDelete(note._id)}
                        onClose={() => setNoteToDelete(null)}
                        button={
                          <button
                            type="button"
                            className="absolute top-3 right-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 hover:bg-gray-200"
                            onClick={() => handleDeleteNote(note._id)}>
                            <CrossIcon color="#ef4444" />
                          </button>
                        }>
                        <div className="px-4 py-2">
                          <h4 className="font-semibold">{t('notes.delete.title')}</h4>
                          <p className="text-sm my-2 text-secondary">
                            {t('notes.delete.description')}
                          </p>
                          <Button.ButtonFilled
                            onClick={() => handleDeleteNote(note._id)}
                            isMini
                            disabled={isDeletingNote === note._id}>
                            {isDeletingNote === note._id ? (
                              <Loading loading={true} />
                            ) : (
                              t('notes.delete.submit')
                            )}
                          </Button.ButtonFilled>
                        </div>
                      </Popover>
                    </div>
                    <div className="my-2 flex items-center justify-between">
                      <span className="ml-1 mb-1 text-xs font-normal text-gray-400 sm:mb-0">
                        {note.updatedBy}
                      </span>
                      <div className="flex items-center justify-end gap-4">
                        <time className="mb-1 text-xs font-normal text-gray-400 sm:mb-0">
                          {formatDateWithTime(note.updatedAt)}
                        </time>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ol>
          </div>
        </>
      ) : null}
    </>
  );
}
