import { useFormik } from 'formik';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getRoles, getStrengths, updateOrganizationProfile } from '../service';
import { getSortedList } from '../utils';
import { MultiSelect } from './common';

const StrengthsAndRoles = forwardRef(({ profile, onSave = () => {} }, ref) => {
  const { t, i18n } = useTranslation();

  useImperativeHandle(ref, () => ({
    save: () => {
      formik.submitForm();
    }
  }));

  const [roles, setRoles] = useState([]);
  const [strengths, setStrengths] = useState([]);

  const fetchStrengthsAndRoles = async () => {
    try {
      const currentLanguage = i18n.language;

      const rolesData = await getRoles(profile.masterOrganization);
      const strengthData = await getStrengths(profile.masterOrganization);

      const sortedStrengths = getSortedList(strengthData.data.data, currentLanguage).map(
        (item) => ({
          _id: item._id,
          label: item[`name_${currentLanguage}`],
          value: item._id
        })
      );

      const sortedRoles = getSortedList(rolesData.data.data, currentLanguage).map((item) => ({
        _id: item._id,
        label: item[`name_${currentLanguage}`],
        value: item._id
      }));

      setRoles(sortedRoles);
      setStrengths(sortedStrengths);
    } catch (error) {
      console.error('Failed to get strengths or roles: ', error);
      toast.error('Failed to fetch strengths or roles.');
    }
  };

  useEffect(() => {
    if (profile) {
      fetchStrengthsAndRoles();
    }
  }, [profile, i18n.language]);

  // Map selected roles and strengths from profile to their full objects
  const mapSelectedToFullObjects = (items, selectedIds) => {
    return items
      .filter((item) => selectedIds.includes(item._id))
      .map(({ _id, label }) => ({ _id, value: _id, label }));
  };

  // Formik initialization and handling
  const formik = useFormik({
    initialValues: {
      roles: mapSelectedToFullObjects(roles, profile.roles || []),
      strengths: mapSelectedToFullObjects(strengths, profile.strengths || [])
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        let { roles: selectedRoles, strengths: selectedStrengths } = values;

        if (selectedRoles.length < 3 || selectedStrengths.length < 3) {
          toast.info('Select atleast 3 Roles and 3 Strenghts.');
          return;
        }

        selectedRoles = selectedRoles.map((role) => role._id);
        selectedStrengths = selectedStrengths.map((strength) => strength._id);

        await updateOrganizationProfile({
          _id: profile?._id,
          email: profile?.email,
          roles: selectedRoles,
          strengths: selectedStrengths
        });
        toast.success('Strengths and Roles updated successfully.');
        onSave();
      } catch (error) {
        console.error('Failed to save strengths and roles: ', error);
        toast.error('Failed to save strengths and roles.');
      }
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <h4 className="font-semibold">{t('editStrengthsAndRoles.topStrenghts')}</h4>
      <MultiSelect
        isMulti
        label={t('editStrengthsAndRoles.selectStrenghts')}
        name="strengths"
        value={formik.values.strengths}
        options={strengths}
        isOptionDisabled={() => formik.values.strengths?.length >= 3}
        onChange={(option) => {
          formik.setFieldValue('strengths', option, true);
        }}
      />
      <h4 className="font-semibold">{t('editStrengthsAndRoles.topRoles')}</h4>
      <MultiSelect
        isMulti
        label={t('editStrengthsAndRoles.selectRoles')}
        name="roles"
        value={formik.values.roles}
        options={roles}
        isOptionDisabled={() => formik.values.roles?.length >= 3}
        onChange={(option) => {
          formik.setFieldValue('roles', option, true);
        }}
      />
    </form>
  );
});

export default StrengthsAndRoles;
