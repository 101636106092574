import clsx from 'clsx';
import React from 'react';

export default function ProgressBar({ progressPercentage, className }) {
  // Clamp progress between 0 and 100
  const clampedProgress = Math.min(Math.max(progressPercentage, 0), 100);

  return (
    <div className={clsx('progress-bar h-2 bg-gray-200 rounded-full', className)}>
      <div
        style={{ width: `${clampedProgress}%` }}
        className={`h-full rounded-full bg-black`}></div>
    </div>
  );
}
