import React, { useState } from 'react';
export default function Avatar({ className, img }) {
  const [loaded, setLoaded] = useState(false);

  return (
    <div className={`relative w-fit ${className}`}>
      <img
        className="h-60 w-60 rounded-full object-contain"
        src={img}
        onLoad={() => {
          setLoaded(true);
        }}
        alt=""
      />
      {!loaded && <span className="loading"></span>}
    </div>
  );
}

Avatar.defaultProps = {
  className: '',
  img: 'https://placeholder.com/assets/images/150x150-2-500x500.png'
};
