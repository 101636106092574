import cloneDeep from 'lodash/cloneDeep';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover, RadioSelect } from './common';
import Button from './common/button';
import { CrossIcon, FunnelIcon, PlusButtonIcon } from './icons';

const Filters = ({ colors = {}, headers = [], data = [], filters = [], onApply = () => {} }) => {
  const { t } = useTranslation();
  const [fields, setFields] = useState([...headers]);
  const [localFilters, setLocalFilters] = useState([...filters]);

  const handleFieldChange = (option, index) => {
    const updatedFilters = cloneDeep(localFilters);
    updatedFilters[index].selectedField = option;
    updatedFilters[index].selectedValue = getUniqueValuesForSelectedOption(option)?.[0]?.value;
    setLocalFilters(updatedFilters);
  };

  const handleValueChange = (option, index) => {
    const updatedFilters = cloneDeep(localFilters);
    updatedFilters[index].selectedValue = option;
    setLocalFilters(updatedFilters);
  };

  const handleAddFilter = () => {
    const fieldValue = getUniqueValuesForSelectedOption(fields?.[0]?._id)?.[0]?.value;
    setLocalFilters([
      ...localFilters,
      {
        selectedField: fields?.[0]?._id,
        selectedValue: fieldValue
      }
    ]);
  };

  const handleRemoveFilter = (index) => {
    const newFilters = localFilters.filter((_, i) => i !== index);
    setLocalFilters(newFilters);
  };

  const getUniqueValuesForSelectedOption = (selectedOptionId) => {
    if (!selectedOptionId) return [];

    if (selectedOptionId === 'profileStatus') {
      return [
        { label: 'Not Started', value: 'not-started' },
        { label: 'In Progress', value: 'in-progress' },
        { label: 'Validated', value: 'validated' }
      ];
    }

    const values = data
      .map((item) => item[selectedOptionId])
      .filter((value, index, self) => self.indexOf(value) === index)
      .filter(Boolean);

    return values.map((value) => ({ label: value.toString(), value }));
  };

  const PopoverContent = ({ closePopup }) => {
    return (
      <>
        <header className="bg-[#F8F8F8] px-6 py-4 rounded-t-lg flex items-center justify-between">
          <h4 className="font-semibold"> {t('columnFilter.title')}</h4>
          <Button.ButtonFilled
            onClick={() => {
              onApply(localFilters);
              closePopup();
            }}>
            {t('columnFilter.submit')}
          </Button.ButtonFilled>
        </header>
        <main className="max-h-[450px] overflow-y-auto p-6">
          {localFilters.map((localFilter, index) => (
            <div key={index} className="flex items-center justify-between gap-2">
              <RadioSelect
                name={`filter.selectField.${index}`}
                label={t('filter.selectField')}
                options={fields}
                selected={localFilter.selectedField}
                onChange={(option) => handleFieldChange(option, index)}
              />
              <RadioSelect
                name={`filter.selectField.${index}`}
                label={t('filter.selectValue')}
                options={getUniqueValuesForSelectedOption(localFilter.selectedField)}
                selected={localFilter.selectedValue}
                onChange={(option) => handleValueChange(option, index)}
              />
              <CrossIcon
                className="mt-2 cursor-pointer w-10"
                onClick={() => handleRemoveFilter(index)}
              />
            </div>
          ))}
          <PlusButtonIcon className="my-4 cursor-pointer" onClick={handleAddFilter} />
        </main>
      </>
    );
  };

  return (
    <>
      <Popover
        position="bottom left"
        size="md"
        onOpen={() => {
          setLocalFilters(filters);
          setFields(headers);
        }}
        onClose={() => setLocalFilters(filters)}
        button={
          <Button.ButtonOutlined type="button">
            <FunnelIcon color={colors?.primary} /> {t('columnFilter.button')}
          </Button.ButtonOutlined>
        }>
        <PopoverContent />
      </Popover>
    </>
  );
};

export default Filters;
