import _ from 'lodash';
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ProfileContext } from '../contexts/profileContext';

import { Loader } from '../components/common';
import GenericForm from '../components/forms/genericForm';

import {
  getFunction,
  getProfessionalAssociation,
  getProfessions,
  getProfileInputFields,
  getRegions,
  getSpecialJobTitles,
  getVocationalSchool,
  updateOrganizationProfile
} from '../service';

import { getSortedList, getTranslation } from '../utils';

const ProfileInformation = forwardRef(({ profile, onUpdate }, ref) => {
  const { t, i18n } = useTranslation();
  const formRef = useRef(null);

  const [initialValues, setInitialValues] = useState({ ...profile });
  const [adminProfile] = useContext(ProfileContext);

  const { masterOrganization: { translationPrefix, languages } = {} } = adminProfile;

  const [isLoading, setIsLoading] = useState(true);
  const [regions, setRegions] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [listSwissRegions, setListSwissRegions] = useState([]);
  const [listProfessionalAssoc, setListProfessionalAssoc] = useState([]);
  const [listSwissFunction, setListSwissFunction] = useState([]);
  const [listSwissJobTitle, setListSwissJobTitle] = useState([]);
  const [swissJobTitleList, setSwissJobTitle] = useState([]);
  const [listVocationalSchool, setListVocationalSchool] = useState([]);
  const [inputFields, setInputFields] = useState([]);

  useImperativeHandle(ref, () => ({
    save: () => {
      if (formRef.current) {
        formRef.current.save();
      }
    }
  }));

  const handleFormSubmit = async ({
    natFinalsRegion: { value: natFinalsRegion = '' } = {},
    worldSkillsProfession: { value: worldSkillsProfession = '' } = {},
    gender: { value: gender = '' } = {},
    natFinalsRank: { value: natFinalsRank = '' } = {},
    bestWorker: { value: bestWorker = '' } = {},
    bestApprentice: { value: bestApprentice = '' } = {},
    worldSkillsRank: { value: worldSkillsRank = '' } = {},
    euroSkillsRank: { value: euroSkillsRank = '' } = {},
    abilympicsProfession: { label: abilympicsProfession = '' } = {},
    region: { value: region = '' } = {},
    specialJobTitle: { value: specialJobTitle = '' } = {},
    profession: { value: profession = '' } = {},
    associationProfession: { value: associationProfession = '' } = {},
    vocationSchool: { value: vocationSchool = '' } = {},
    swissChampionRank: { value: swissChampionRank = '' } = {},
    participationType: { value: participationType = '' } = {},
    montherLanguage: { value: montherLanguage = '' } = {},
    ...values
  }) => {
    try {
      let formData = {
        ...values,
        natFinalsRegion,
        worldSkillsProfession,
        gender,
        natFinalsRank,
        bestWorker,
        bestApprentice,
        worldSkillsRank,
        euroSkillsRank,
        abilympicsProfession,
        region,
        specialJobTitle,
        profession,
        associationProfession,
        vocationSchool,
        swissChampionRank,
        participationType,
        montherLanguage
      };

      if (formData.functions) formData.functions = formData.functions.map((obj) => obj.value) || [];

      await updateOrganizationProfile({
        _id: initialValues?._id,
        email: initialValues?.email,
        ...formData
      });
      setInitialValues({ ...initialValues, ...formData });
      onUpdate({ ...initialValues, ...formData });
      toast.success(getTranslation(t, 'form.success', translationPrefix));
    } catch (e) {
      console.log(e);
      toast.error(getTranslation(t, 'form.fail', translationPrefix));
    }
  };

  const getPageData = async () => {
    try {
      const { data: regionData } = await getRegions(initialValues?.masterOrganization);
      const { data: professionData } = await getProfessions(initialValues?.masterOrganization);

      const filteredRegions = regionData.map((region) => ({
        value: region._id,
        label: region[`name_${i18n.language}`]
      }));

      const filteredProfessions = professionData.map((profession) => ({
        value: profession._id,
        label: profession[`jobRole_${i18n.language}`]
      }));

      setRegions(filteredRegions);
      setProfessions(filteredProfessions);

      // ch champion profile
      const currentLang = i18n.language;
      const { data: swissRegionData } = await getRegions(initialValues?.masterOrganization);
      const { data: professionalAssocData } = await getProfessionalAssociation(
        initialValues?.masterOrganization
      );
      const { data: swissFunctionData } = await getFunction(initialValues?.masterOrganization);
      const { data: swissJobTitleData } = await getSpecialJobTitles(
        initialValues?.masterOrganization
      );
      const { data: swissJobChampData } = await getProfessions(initialValues?.masterOrganization);
      const { data: vocationalSchoolData } = await getVocationalSchool(
        initialValues?.masterOrganization
      );

      const filteredSwissRegion = swissRegionData.map(({ _id, ...rest }) => ({
        value: _id,
        label: rest[`name_${currentLang}`]
      }));
      const filteredProfessionAssoc = professionalAssocData.map(({ name, _id }) => ({
        value: _id,
        label: name
      }));
      const filteredSwissFunction = swissFunctionData.map(({ _id, ...rest }) => ({
        value: _id,
        label: rest[`name_${currentLang}`]
      }));

      const isEN = currentLang === 'en';

      const filteredSwissJobTitle = getSortedList(
        swissJobTitleData,
        isEN ? 'de' : currentLang,
        'name'
      ).map(({ _id, ...rest }) => ({
        value: _id,
        label: rest[`name_${isEN ? 'de' : currentLang}`]
      }));

      const filteredSwissJobChamp = getSortedList(
        swissJobChampData,
        isEN ? 'de' : currentLang,
        'jobRole'
      ).map(({ _id, ...rest }) => ({
        value: _id,
        label: rest[`jobRole_${isEN ? 'de' : currentLang}`]
      }));

      const filteredVocationalSchool = vocationalSchoolData.map(({ name, _id }) => ({
        value: _id,
        label: name
      }));
      setSwissJobTitle(swissJobTitleData);
      setListSwissRegions(filteredSwissRegion);
      setListProfessionalAssoc(filteredProfessionAssoc);
      setListSwissFunction(filteredSwissFunction);
      setListSwissJobTitle(filteredSwissJobTitle);
      setProfessions(filteredSwissJobChamp);
      setListVocationalSchool(filteredVocationalSchool);
    } catch (error) {
      toast.error('Failed to load complete profile data.');
      console.error('Failed to load complete profile data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getJobsList = async (gender) => {
    let filteredSwissJobTitle = [];
    if (gender === 'masculin') {
      filteredSwissJobTitle = swissJobTitleList.map((item) => ({
        value: item._id,
        label: item.male_de
      }));
    } else {
      filteredSwissJobTitle = swissJobTitleList.map((item) => ({
        value: item._id,
        label: item.female_de
      }));
    }
    setListSwissJobTitle(filteredSwissJobTitle);
  };

  const loadProfileInputFields = async (id) => {
    try {
      const { data } = await getProfileInputFields(id);
      setInputFields(data);
    } catch (error) {
      toast.error('Failed to load profile input fields.');
      console.error('Failed to load profile input fields:', error);
    }
  };

  useEffect(() => {
    loadProfileInputFields(initialValues?._id);
    getPageData();
    getJobsList(initialValues?.gender);
  }, [initialValues]);

  return (
    <>
      <div className="w-full mb-8">
        <GenericForm
          ref={formRef}
          profile={initialValues}
          languages={languages}
          fields={inputFields}
          regions={regions}
          professions={professions}
          listSwissRegions={listSwissRegions}
          listProfessionalAssoc={listProfessionalAssoc}
          listSwissFunction={listSwissFunction}
          listSwissJobTitle={listSwissJobTitle}
          listVocationalSchool={listVocationalSchool}
          handleFormSubmit={handleFormSubmit}
          orgPrefix={translationPrefix}
        />
      </div>
      {isLoading && <Loader transparent={true} />}
    </>
  );
});

export default ProfileInformation;
