import MenuItems from './menuItems';
import Footer from './footer';
import Header from './header';
const Partials = {
  Header,
  MenuItems,
  Footer
};

export default Partials;
