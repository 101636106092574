import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { editableFieldSchema } from '../../utils/schema';
import Spinner from './spinner';
import TextInput from './textInput';

const ALLOWED_DISPLAY_COMPONENTS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span'];

export default function EditableField({
  id,
  label = 'Enter value',
  text,
  isEditable = true,
  className = '',
  onUpdate = () => {},
  submitFunction = async () => {},
  successMessage = 'Updated successfully.',
  errorMessage = 'Failed to update.',
  DisplayComponent = 'span'
}) {
  const [isEditActive, setIsEditActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: { fieldText: text },
    validationSchema: editableFieldSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);

        let { fieldText } = values;

        // Trim the field text
        fieldText = fieldText.trim();

        // Check if the field value has changed
        if (fieldText === text) {
          setIsEditActive(false);
          return;
        }

        await submitFunction(id, { fieldText });
        toast.success(successMessage);
        setIsEditActive(false);
        onUpdate({ id, fieldText });
      } catch (error) {
        console.error('Failed to update field: ', error);
        toast.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    }
  });

  useEffect(() => {
    formik.setFieldValue('fieldText', text);
  }, [text]);

  const handleEditCloseClick = () => {
    formik.resetForm();
    setIsEditActive(false);
  };

  return isEditActive ? (
    <form onSubmit={formik.handleSubmit} className="w-[90%] relative">
      <TextInput
        name="fieldText"
        label={label}
        value={formik.values.fieldText}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.fieldText}
      />
      <span className="flex items-center gap-1 absolute -top-1 -right-1">
        {isLoading ? (
          <Spinner isSubmitting={isLoading} color="#000" />
        ) : (
          <button type="submit">
            <CheckCircleIcon className="w-5 cursor-pointer hover:fill-green-300 transition-all" />
          </button>
        )}
        <XCircleIcon
          className={clsx('w-5 transition-all', {
            'cursor-not-allowed': isLoading,
            'cursor-pointer hover:fill-red-300': !isLoading
          })}
          onClick={isLoading ? null : () => handleEditCloseClick()}
        />
      </span>
    </form>
  ) : (
    <DisplayComponent
      className={clsx(
        'max-w-[80%] break-words truncate cursor-pointer',
        {
          'text-gray-900 font-bold': ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(
            DisplayComponent
          ),
          'text-secondary text-sm': ['p', 'span'].includes(DisplayComponent)
        },
        className
      )}
      onClick={isEditable ? () => setIsEditActive(true) : null}>
      {text}
    </DisplayComponent>
  );
}

EditableField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  text: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  className: PropTypes.string,
  onUpdate: PropTypes.func,
  submitFunction: PropTypes.func,
  displayComponent: PropTypes.oneOf(ALLOWED_DISPLAY_COMPONENTS)
};
