import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { createOrganizationSubAdmin, getAllTeamMembers, removeTeamMember } from '../service';
import { addNewTeamMemberSchema } from '../utils/schema';
import { RadioSelect, TextInput } from './common';
import Button from './common/button';
import { CloseButtonIcon, PlusButtonIcon } from './icons';

const GroupTeamMembers = ({ groupId }) => {
  const [showAddNewTeamMember, setShowAddNewTeamMember] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);

  const getTeamMembers = async () => {
    try {
      const { data: teamMembers } = await getAllTeamMembers(groupId);

      setTeamMembers(teamMembers);
    } catch (e) {
      toast.error('Failed to get team members.');
      console.error('error:', e);
    }
  };

  useEffect(() => {
    getTeamMembers();
  }, []);

  const handleGroupTeamMemberRemoveClick = async (teamMemberId) => {
    try {
      await removeTeamMember(groupId, teamMemberId);
      toast.success('Group Member removed successfully');
      getTeamMembers();
    } catch (error) {
      console.error('Failed to remove group member.');
      toast.error('Failed to remove group member.');
    }
  };

  const teamMemberFormik = useFormik({
    initialValues: { email: '', role: '' },
    validationSchema: addNewTeamMemberSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const { email, role } = values;
        await createOrganizationSubAdmin({ groupId, email, role });

        toast.success('Team member added successfully.');
        setShowAddNewTeamMember(false);
        teamMemberFormik.resetForm();
        getTeamMembers();
      } catch (error) {
        console.error('Failed to add team member.');
        toast.error('Failed to add team member.');
      }
    }
  });

  return (
    <div className="py-6">
      <h6 className="text-lg font-bold mb-2">Team Members:</h6>
      <div className="my-6">
        <ul>
          {teamMembers?.map((teamMember) => (
            <li key={teamMember._id} className="my-2 flex items-center gap-4">
              <TextInput value={teamMember.email} disabled />
              <CloseButtonIcon
                className="cursor-pointer"
                onClick={() => handleGroupTeamMemberRemoveClick(teamMember._id)}
              />
            </li>
          ))}
        </ul>
      </div>
      <PlusButtonIcon onClick={() => setShowAddNewTeamMember(true)} className="cursor-pointer" />

      {showAddNewTeamMember && (
        <div className="my-6">
          <h6 className="text-lg font-bold mb-2">Add Team Member:</h6>
          <form onSubmit={teamMemberFormik.handleSubmit}>
            <TextInput
              name="email"
              label="Member Email"
              value={teamMemberFormik.values.email}
              onChange={teamMemberFormik.handleChange}
              onBlur={teamMemberFormik.handleBlur}
              error={teamMemberFormik.errors.email}
            />
            <RadioSelect
              label="Select role/s"
              className="my-1"
              name="role"
              value={teamMemberFormik.values.role}
              touched={teamMemberFormik.touched.role}
              onBlur={teamMemberFormik.handleBlur}
              error={teamMemberFormik.errors.role}
              options={[
                {
                  id: 'select-role',
                  label: 'Select role',
                  value: 'select-role',
                  isDisabled: true
                },
                {
                  id: 'admin',
                  label: 'Administrator',
                  value: 'admin'
                },
                {
                  id: 'editor',
                  label: 'Editor',
                  value: 'editor'
                }
              ]}
              onChange={(option) => {
                teamMemberFormik.setFieldValue('role', option, true);
              }}
            />
            <footer className="flex items-center justify-end">
              <Button.ButtonFilled type="submit" loading={teamMemberFormik.isSubmitting}>
                Add
              </Button.ButtonFilled>
            </footer>
          </form>
        </div>
      )}
    </div>
  );
};

export default GroupTeamMembers;
