import React, { useContext } from 'react';
import { ProfileContext } from '../../../contexts/profileContext';
import { Spinner } from '../../common';

export default function ButtonRounded({
  onClick,
  className,
  children,
  typeClass = 'rounded-full',
  loading,
  ...props
}) {
  const [profile] = useContext(ProfileContext);
  const { masterOrganization: { customConfigurations: { colors } = {} } = {} } = profile;

  return (
    <button
      {...props}
      className={`text-white px-6 py-2 text-lg font-bold flex items-center justify-center border-2 ${typeClass} ${className} disabled:opacity-60`}
      style={{
        backgroundColor: colors?.primary,
        borderRadius: colors?.buttonRadius,
        borderColor: colors?.primary
      }}
      disabled={loading}
      onClick={onClick}>
      {children} <Spinner isSubmitting={loading} />
    </button>
  );
}
