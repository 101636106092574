import { useContext } from 'react';
import { ProfileContext } from '../contexts/profileContext';

function useOrganizationColors() {
  const [profile] = useContext(ProfileContext);

  const { masterOrganization: { customConfigurations: { colors } = {} } = {} } = profile;

  return colors;
}

export default useOrganizationColors;
